export enum UserRole {
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    DRIVER = 'DRIVER',
    EXTERNAL_RECEIVER = 'EXTERNAL_RECEIVER',
    EXCAVATOR_OPERATOR = 'EXCAVATOR_OPERATOR',
}

export enum UserStatus {
    REGISTERED = 'REGISTERED',
    EMAIL_CONFIRMED = 'EMAIL_CONFIRMED',
    APPROVED = 'APPROVED',
}

export enum UserContextRole {
    OWNER = 'OWNER',
    RECEIVER = 'RECEIVER',
    GUEST = 'GUEST',
    UNKNOWN = 'UNKNOWN',
    AUTHOR = 'AUTHOR',
    PROJECT_USER = 'PROJECT_USER',
    PROJECT_EXCAVATOR_OPERATOR = 'PROJECT_EXCAVATOR_OPERATOR',
    PROJECT_DRIVER = 'PROJECT_DRIVER',
}

export enum ErrorCodes {
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    INVALID_INPUT = 'INVALID_INPUT',
    REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
    INVALID_ID = 'INVALID_ID',
    ACCESS_VIOLATION = 'ACCESS_VIOLATION',
    NOT_NULL_EXPECTED = 'NOT_NULL_EXPECTED',
    END_DATE_IS_BEFORE_START_DATE = 'END_DATE_IS_BEFORE_START_DATE',
    VALUE_ALREADY_CHANGED = 'VALUE_ALREADY_CHANGED',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    USER_BLOCKED = 'USER_BLOCKED',
    USER_NOT_CONFIRMED = 'USER_NOT_CONFIRMED',
    USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',

    LANDFILL_NOT_FOUND = 'LANDFILL_NOT_FOUND',
    LANDFILL_INVALID_RECEIVER_USER = 'LANDFILL_INVALID_RECEIVER_USER',
    LANDFILL_CUSTOMER_REQUIRED = 'LANDFILL_CUSTOMER_REQUIRED',
    SUBAREA_PRICE_NOT_SPECIFIED = 'SUBAREA_PRICE_NOT_SPECIFIED',
    SUBAREA_USED_IN_MATCHING = 'SUBAREA_USED_IN_MATCHING',
    TOC_VALUE_REQUIRED = 'TOC_VALUE_REQUIRED',
    TOC_VALUE_INCORRECT = 'TOC_VALUE_INCORRECT',
    INVASIVE_SPECIES_REQUIRED = 'INVASIVE_SPECIES_REQUIRED',
    LANDFILL_EVENT_NOT_FOUND = 'LANDFILL_EVENT_NOT_FOUND',
    LANDFILL_EVENT_ALREADY_ATTACHED = 'LANDFILL_EVENT_ALREADY_ATTACHED',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    ORDER_ALREADY_ATTACHED = 'ORDER_ALREADY_ATTACHED',
    ORDER_RECEIPTS_DIRECTORY_EXISTS = 'ORDER_RECEIPTS_DIRECTORY_EXISTS',
    ORDER_RECEIPTS_DIRECTORY_RENAME_ERROR = 'ORDER_RECEIPTS_DIRECTORY_RENAME_ERROR',
    ORDER_RECEIPTS_FILE_RENAME_ERROR = 'ORDER_RECEIPTS_FILE_RENAME_ERROR',
    ORDER_NUMBER_REQUIRED = 'ORDER_NUMBER_REQUIRED',
    ORDER_PLANNED_START_DATE_REQUIRED = 'ORDER_PLANNED_START_DATE_REQUIRED',
    ORDER_DUPLICATE_ORDER_NUMBER = 'ORDER_DUPLICATE_ORDER_NUMBER',
    PROJECT_IS_NOT_AVAILABLE = 'PROJECT_IS_NOT_AVAILABLE',

    DUMP_LOAD_NOT_FOUND = 'DUMP_LOAD_NOT_FOUND',
    PROJECT_NAME_INVALID = 'PROJECT_NAME_INVALID',
    PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
    PROJECT_HAS_ORDER_WITH_OTHER_CUSTOMER = 'PROJECT_HAS_ORDER_WITH_OTHER_CUSTOMER',
    PROJECT_HAS_CUSTOMER = 'PROJECT_HAS_CUSTOMER',
    PROJECT_ACTIVE_LOCATION_CANNOT_BE_CHANGED = 'PROJECT_ACTIVE_LOCATION_CANNOT_BE_CHANGED',
    PROJECT_CUSTOMER_REQUIRED = 'PROJECT_CUSTOMER_REQUIRED',
    EMAIL_BLOCKED = 'EMAIL_BLOCKED',
    DUMP_LOAD_STATUS_UNKNOWN = 'DUMP_LOAD_STATUS_UNKNOWN',
    DUMP_LOAD_INVALID_TARGET_STATUS_OR_NO_PERMISSIONS = 'DUMP_LOAD_INVALID_TARGET_STATUS_OR_NO_PERMISSIONS',
    DUMP_LOAD_NO_PERMISSIONS = 'DUMP_LOAD_NO_PERMISSIONS',
    DUMP_LOAD_EDIT_FORBIDDEN_IN_CURRENT_STATUS = 'DUMP_LOAD_EDIT_FORBIDDEN_IN_CURRENT_STATUS',
    DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES = 'DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES',
    DUMP_LOAD_NOT_SPECIFIED = 'DUMP_LOAD_NOT_SPECIFIED',
    DUMP_LOAD_NOT_SIGNED = 'DUMP_LOAD_NOT_SIGNED',
    DUMP_LOAD_NEED_TO_SEND = 'DUMP_LOAD_NEED_TO_SEND',
    DUMP_LOAD_CUSTOMER_NEED_FOR_SIGN = 'DUMP_LOAD_CUSTOMER_NEED_FOR_SIGN',
    DUMP_LOAD_CAN_NOT_BE_CREATED_FOR_DELETED_CUSTOMER = 'DUMP_LOAD_CAN_NOT_BE_CREATED_FOR_DELETED_CUSTOMER',
    DUMP_LOAD_HAS_DESPATCH_ADVICE = 'DUMP_LOAD_HAS_DESPATCH_ADVICE',
    DRIVER_EMAIL_EXISTS = 'DRIVER_EMAIL_EXISTS',
    DRIVER_PHONE_EXISTS = 'DRIVER_PHONE_EXISTS',
    CANNOT_SET_DATE_IN_PAST = 'CANNOT_SET_DATE_IN_PAST',
    PROJECT_EDIT_FORBIDDEN_IN_CURRENT_STATUS = 'PROJECT_EDIT_FORBIDDEN_IN_CURRENT_STATUS',

    LINK_EXPIRED = 'LINK_EXPIRED',
    USER_EXISTS = 'USER_EXISTS',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_EMPTY_OLD_PASSWORD = 'USER_EMPTY_OLD_PASSWORD',
    USER_INVALID_OLD_PASSWORD = 'USER_INVALID_OLD_PASSWORD',
    USER_PASSWORD_NOT_EQUAL = 'USER_PASSWORD_NOT_EQUAL',
    USER_PASSWORD_SIMILAR_TO_CURRENT = 'USER_PASSWORD_SIMILAR_TO_CURRENT',
    USER_HAS_LINKED_PROJECTS = 'USER_HAS_LINKED_PROJECTS',
    USER_REQUIRED_CUSTOMER = 'USER_REQUIRED_CUSTOMER',
    USER_ALREADY_INVITED = 'USER_ALREADY_INVITED',
    USER_ALREADY_JOINED_PROJECT = 'USER_ALREADY_JOINED_PROJECT',
    EMAIL_EXISTS = 'EMAIL_EXISTS',
    DRIVER_NOT_FOUND = 'DRIVER_NOT_FOUND',
    DRIVER_EMPTY_PASSWORD = 'DRIVER_EMPTY_PASSWORD',
    DRIVER_INVALID_OLD_PASSWORD = 'DRIVER_INVALID_OLD_PASSWORD',
    DRIVER_PASSWORD_NOT_EQUAL = 'DRIVER_PASSWORD_NOT_EQUAL',
    DRIVER_IS_NOT_ACTIVE = 'DRIVER_IS_NOT_ACTIVE',
    INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
    EMPTY_OWNER = 'EMPTY_OWNER',
    INVALID_ROLE_FOR_EMAIL = 'INVALID_ROLE_FOR_EMAIL',
    DUMP_TYPE_NOT_FOUND = 'DUMP_TYPE_NOT_FOUND',
    DUMP_TYPE_EXISTS = 'DUMP_TYPE_EXISTS',
    DUMP_TYPE_NAME_CANNOT_BE_EMPTY = 'DUMP_TYPE_NAME_CANNOT_BE_EMPTY',
    DUMP_TYPE_VOLUME_CANNOT_BE_EMPTY = 'DUMP_TYPE_VOLUME_CANNOT_BE_EMPTY',
    DUMP_TYPE_TONS_PER_M3_MUST_BE_POSITIVE = 'DUMP_TYPE_TONS_PER_M3_MUST_BE_POSITIVE',
    TOXIC_LIMITS_INVALID_VALUE = 'TOXIC_LIMITS_INVALID_VALUE',

    SUBSTANCE_IS_USED = 'SUBSTANCE_IS_USED',
    SUBSTANCE_CONTAMINATION_TYPE_IS_USED = 'SUBSTANCE_CONTAMINATION_TYPE_IS_USED',
    PERMISSION_ERROR = 'PERMISSION_ERROR',

    DELIVERY_LINE_NOT_FOUND = 'DELIVERY_LINE_NOT_FOUND',
    COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
    COMPANY_DUPLICATE_ORGANIZATION_NUMBER = 'COMPANY_DUPLICATE_ORGANIZATION_NUMBER',
    COMPANY_DUPLICATE_GLN = 'COMPANY_DUPLICATE_GLN',
    COMPANY_HAS_CONNECTED_ENTITIES = 'COMPANY_HAS_CONNECTED_ENTITIES',
    NETWORK_ERROR = 'NETWORK_ERROR',
    LANDFILL_STATUS_NOT_FOUND = 'LANDFILL_STATUS_NOT_FOUND',
    LANDFILL_STATUS_NAME_CANNOT_BE_EMPTY = 'LANDFILL_STATUS_NAME_CANNOT_BE_EMPTY',
    LANDFILL_STATUS_EXISTS = 'LANDFILL_STATUS_EXISTS',

    LANDFILL_IS_NOT_AVAILABLE = 'LANDFILL_IS_NOT_AVAILABLE',
    FORTNOX_ENTITY_NOT_FOUND = 'FORTNOX_ENTITY_NOT_FOUND',
    FORTNOX_SYNC_TIMEOUT = 'FORTNOX_SYNC_TIMEOUT',
    FORTNOX_ANOTHER_SYNC_IN_PROGRESS = 'FORTNOX_ANOTHER_SYNC_IN_PROGRESS',
    FORTNOX_NETWORK_ERROR = 'FORTNOX_NETWORK_ERROR',
    FORTNOX_SYNC_CANCELED = 'FORTNOX_SYNC_CANCELED',
    EMPTY_COMPANY = 'EMPTY_COMPANY',
    NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',

    DEVIATION_NOT_FOUND = 'DEVIATION_NOT_FOUND',
    DEVIATION_INVALID_FIELDS = 'DEVIATION_INVALID_FIELDS',

    FILE_NOT_FOUND = 'FILE_NOT_FOUND',
    FILE_EXISTS = 'FILE_EXISTS',
    FILE_TOO_LARGE = 'FILE_TOO_LARGE',
    INVALID_FILE_NAME = 'INVALID_FILE_NAME',

    CONVERSION_TO_PDF_ERROR = 'CONVERSION_TO_PDF_ERROR',

    PROJECT_END_DATE_BEFORE_START_DATE = 'PROJECT_END_DATE_BEFORE_START_DATE',
    PROJECT_NO_DATE_CHANGE_ONGOING_MASS = 'PROJECT_NO_DATE_CHANGE_ONGOING_MASS',
    PROJECT_START_DATE_AFTER_MASS_START_DATE = 'PROJECT_START_DATE_AFTER_MASS_START_DATE',
    PROJECT_END_DATE_BEFORE_MASS_END_DATE = 'PROJECT_END_DATE_BEFORE_MASS_END_DATE',

    MASS_START_DATE_BEFORE_PROJECT_START_DATE = 'MASS_START_DATE_BEFORE_PROJECT_START_DATE',
    MASS_START_DATE_BEFORE_TODAY = 'MASS_START_DATE_BEFORE_TODAY',
    MASS_END_DATE_BEFORE_PROJECT_START_DATE = 'MASS_END_DATE_BEFORE_PROJECT_START_DATE',
    MASS_END_DATE_BEFORE_MASS_START_DATE = 'MASS_END_DATE_BEFORE_MASS_START_DATE',
    MASS_END_DATE_BEFORE_TODAY = 'MASS_END_DATE_BEFORE_TODAY',
    PROJECT_END_DATE_MUST_BE_UPDATED = 'PROJECT_END_DATE_MUST_BE_UPDATED',
    PROJECT_LOCATION_ON_WATER = 'PROJECT_LOCATION_ON_WATER',
    LANDFILL_LOCATION_ON_WATER = 'LANDFILL_LOCATION_ON_WATER',

    OPEN_FROM_AFTER_OPEN_TO = 'OPEN_FROM_AFTER_OPEN_TO',
    NO_OPEN_WORK_DAY = 'NO_OPEN_WORK_DAY',

    API_KEY_NOT_SPECIFIED = 'API_KEY_NOT_SPECIFIED',
    API_KEY_INVALID = 'API_KEY_INVALID',

    INVALID_LOCATION = 'INVALID_LOCATION',

    PHONE_NUMBER_EXISTS = 'PHONE_NUMBER_EXISTS',
    PHONE_NUMBER_HAVE_TO_BE_LOCAL_OR_INTERNATIONAL = 'PHONE_NUMBER_HAVE_TO_BE_LOCAL_OR_INTERNATIONAL',
    PHONE_NUMBER_TOO_SHORT = 'PHONE_NUMBER_TOO_SHORT',
    PHONE_NUMBER_TOO_LONG = 'PHONE_NUMBER_TOO_LONG',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',

    INVALID_ORGANIZATION_NUMBER = 'INVALID_ORGANIZATION_NUMBER',
    INVALID_GLN = 'INVALID_GLN',
    VALUE_ALREADY_EXISTS = 'VALUE_ALREADY_EXISTS',
    GOOGLE_API_ERROR = 'GOOGLE_API_ERROR',
    GOOGLE_API_ZERO_RESULTS = 'GOOGLE_API_ZERO_RESULTS',

    LIMIT_OF_FILES_FOR_DOWNLOAD_IS_OUT_OF_RANGE = 'LIMIT_OF_FILES_FOR_DOWNLOAD_IS_OUT_OF_RANGE',
    UNKNOWN_GQL_ERROR = 'UNKNOWN_GQL_ERROR',
    TRUCK_NOT_FOUND = 'TRUCK_NOT_FOUND',
    ANOTHER_DRIVER_DELIVERY_LINE_IN_PROGRESS = 'ANOTHER_DRIVER_DELIVERY_LINE_IN_PROGRESS',
    ALREADY_TRUCK_DELIVERY_LINE_IN_PROGRESS = 'ALREADY_TRUCK_DELIVERY_LINE_IN_PROGRESS',
    WRONG_VERSION = 'WRONG_VERSION',

    BEAST_MATERIAL_EXISTS = 'BEAST_MATERIAL_EXISTS',
    BEAST_MATERIAL_ID_CANNOT_BE_EMPTY = 'BEAST_MATERIAL_ID_CANNOT_BE_EMPTY',
    BEAST_MATERIAL_NAME_CANNOT_BE_EMPTY = 'BEAST_MATERIAL_NAME_CANNOT_BE_EMPTY',
    DELIVERY_LINE_HAS_PEPPOL_RESPONSE = 'DELIVERY_LINE_HAS_PEPPOL_RESPONSE',
    TIME_SPENT_HAS_PEPPOL_RESPONSE = 'TIME_SPENT_HAS_PEPPOL_RESPONSE',
    INVALID_TEST_PEPPOL_PARTIES = 'INVALID_TEST_PEPPOL_PARTIES',

    VEHICLE_TYPE_IS_USED = 'VEHICLE_TYPE_IS_USED',
    VEHICLE_TYPE_NOT_FOUND = 'VEHICLE_TYPE_NOT_FOUND',
    NO_ACTIVE_WHATSAPP_PHONE = 'NO_ACTIVE_WHATSAPP_PHONE',

    INCORRECT_CHAT_GPT_ASSISTANT_ID = 'INCORRECT_CHAT_GPT_ASSISTANT_ID',
    OPEN_AI_ERROR = 'OPEN_AI_ERROR',

    LANDFILL_BATCH_NOT_FOUND = 'LANDFILL_BATCH_NOT_FOUND',
    LANDFILL_BATCH_CHANGE_INCORRECT = 'LANDFILL_BATCH_CHANGE_INCORRECT',

    LANDFILL_BATCH_TRANSFER_NOT_FOUND = 'LANDFILL_BATCH_TRANSFER_NOT_FOUND',
    LANDFILL_TRANSFER_BATCHES_SHOULD_BE_DIFFERENT = 'LANDFILL_TRANSFER_BATCHES_SHOULD_BE_DIFFERENT',

    LANDFILL_BATCH_ANALYSIS_NOT_FOUND = 'LANDFILL_BATCH_ANALYSIS_NOT_FOUND',
}

export enum InfoCode {
    TRANSPORT_CONF_EMPTY = 'TRANSPORT_CONF_EMPTY',
    MATCHED_LANDFILLS_NOT_FOUND = 'MATCHED_LANDFILLS_NOT_FOUND',
    GOOGLE_SERVICE_UNAVAILABLE = 'GOOGLE_SERVICE_UNAVAILABLE',
    LANDFILL_ROUTE_UNAVAILABLE = 'LANDFILL_ROUTE_UNAVAILABLE',
    AUTOMATIC_PRICES_UNAVAILABLE = 'AUTOMATIC_PRICES_UNAVAILABLE',
    SKIP_MATCHING_MODE = 'SKIP_MATCHING_MODE',

    DUMP_TYPE_EMPTY = 'DUMP_TYPE_EMPTY',
    LOCATION_EMPTY = 'LOCATION_EMPTY',
    NOT_ALLOCATED_TRIPS = 'NOT_ALLOCATED_TRIPS',
    OVER_ALLOCATED_TRIPS = 'OVER_ALLOCATED_TRIPS',
}

export type IDestinationResultInfoCode = InfoCode;

// eslint-disable-next-line pinpointer/equal-enum
export enum AbilityCan {
    CRUD = 'crud',
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    UPLOAD = 'upload',
}

export { AbilityCan as IAbilityCan };

// eslint-disable-next-line pinpointer/equal-enum
export enum AbilityFields {
    DUMP_LOAD_STATUS = 'status',
    DUMP_LOAD_TO_STATUS = 'toStatus',
}

export enum DriverFields {
    name = 'name',
    surname = 'surname',
    phone = 'phone',
    email = 'email',
    companies = 'companies',
    registeredAt = 'registeredAt',
    comments = 'comments',
}

export enum AbilitySubjects {
    LANDFILL = 'LANDFILL',
    LANDFILL_STATUS = 'LANDFILL_STATUS',
    LANDFILL_DEVIATION = 'LANDFILL_DEVIATION',

    PROJECT = 'PROJECT',

    PROJECT_DUMPLOAD = 'PROJECT_DUMPLOAD',

    ORDER = 'ORDER',
    COMPANY = 'COMPANY',
    DRIVER = 'DRIVER',
    DELIVERY_LINE = 'DELIVERY_LINE',

    USER = 'USER',

    DUMP_TYPE = 'DUMP_TYPE',
    SUBSTANCE = 'SUBSTANCE',
    TOXIC_LIMITS = 'TOXIC_LIMITS',
    FILE_TEMPLATES = 'FILE_TEMPLATES',
    INVOICES = 'INVOICES',

    MANAGER_BUSINESS_FLOW = 'MANAGER_BUSINESS_FLOW',

    ADMIN_PAGES = 'ADMIN_PAGES',

    PROJECT_DEVIATION = 'PROJECT_DEVIATION',

    SETTINGS = 'SETTINGS',
    LOCK = 'LOCK',
    RELEASE_NOTE = 'RELEASE_NOTE',
    LOCALIZATION = 'LOCALIZATION',
    BEAST_INTEGRATION = 'BEAST_INTEGRATION',
    VEHICLE_TYPE = 'VEHICLE_TYPE',
    TIME_SPENT = 'TIME_SPENT',
}

export type IAbilitySubject = AbilitySubjects;

// eslint-disable-next-line pinpointer/equal-enum
export enum DocumentTypes {
    PROJECT = 'Project',
    LANDFILL = 'Landfill',
}

export type IDocumentType = DocumentTypes;

export enum ContaminationType {
    SOLID = 'SOLID',
    LEACHING = 'LEACHING',
}

export enum SubstanceGroupBy {
    MAX = 'MAX',
    SUM = 'SUM',
}

export enum ContaminationLevel {
    mrr = 'mrr',
    km = 'km',
    mkm = 'mkm',
    ifa = 'ifa',
    fa = 'fa',
    inert = 'inert',
    na = 'na',
    limit = 'limit',
}

export enum DumpLoadActionName {
    REOPEN = 'REOPEN',
    DISCARD = 'DISCARD',
    REQUEST = 'REQUEST',
    REJECT = 'REJECT',
    CONFIRM = 'CONFIRM',
    BUY = 'BUY',
    START_DELIVERY = 'START_DELIVERY',
    DONE = 'DONE',
}

export enum LoadStatus {
    DISCARDED = 'DISCARDED',
    NOT_ORDERED = 'NOT_ORDERED',
    DRAFT = 'DRAFT',
    REQUESTED = 'REQUESTED',
    CONFIRMED = 'CONFIRMED',
    ORDERED = 'ORDERED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
}

/**@deprecated */
export { LoadStatus as DumpLoadStatus };

export enum OrderStatus {
    CLOSED = 'CLOSED',
    OPENED = 'OPENED',
}

export enum SyncStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    UNKNOWN = 'UNKNOWN',
}

export enum DumpLoadFields {
    id = 'id',
    declaration = 'declaration',
    status = 'status',
    dumpType = 'dumpType',
    dumpTypeId = 'dumpTypeId',
    amount = 'amount',
    unitOfMeasure = 'unitOfMeasure',
    date = 'date',
    endDate = 'endDate',
    transportLine = 'transportLine',
    projectId = 'projectId',
    solidTestResult = 'solidTestResult',
    leachingTestResult = 'leachingTestResult',
    solidState = 'solidState',
    leachingState = 'leachingState',
    stackability = 'stackability',
    withTransport = 'withTransport',
    over50cm = 'over50cm',
    comment = 'comment',
    statusUpdatedAt = 'statusUpdatedAt',
    transportSchedule = 'transportSchedule',
    totalTrips = 'totalTrips',
    customerSignLink = 'customerSignLink',
    signRequestId = 'signRequestId',
    isCustomerSigned = 'isCustomerSigned',
    isEmployeeSigned = 'isEmployeeSigned',
    substances = 'substances',
    comments = 'comments',
    landfill = 'landfill',
    destinationInfo = 'destinationInfo',
    priceData = 'priceData',
    destination = 'destination',
    toxicAnalysisApprovedExternally = 'toxicAnalysisApprovedExternally',
    siteConfirmed = 'siteConfirmed',
    creditCheck = 'creditCheck',
    yourOrderNumber = 'yourOrderNumber',
    contractSigned = 'contractSigned',
    landfillOrdered = 'landfillOrdered',
    withTOC = 'withTOC',
    withInvasiveSpecies = 'withInvasiveSpecies',
    destinationLandfill = 'destinationLandfill',
    distance = 'distance',
    beastMaterialId = 'beastMaterialId',
    places = 'places',
    skipMatching = 'skipMatching',
    inbound = 'inbound',
}

export type IDumpLoadFields = DumpLoadFields;

export enum LimitValidationErrorType {
    equal = 'equal',
    lesser = 'lesser',
    bigger = 'bigger',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum LimitValidationReturnCodes {
    valid = 'valid',
    nothingChanged = 'nothing-changed',
    invalidLevelName = 'invalid-level-name',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum FileFormats {
    PDF = 'pdf',
    EXCEL = 'xlsx',
    DOCX = 'docx',
    JPEG = 'jpeg',
    ZIP = 'zip',
    JPG = 'jpg',
    PNG = 'png',
    WEBP = 'webp',
    BMP = 'bmp',
    GIF = 'gif',
}

export type TFileFormats = FileFormats;

export enum Granularity {
    second = 'second',
    day = 'day',
    week = 'week',
    isoWeek = 'isoWeek',
    month = 'month',
    year = 'year',
}

export type IGranularity = `${Granularity}`;

export enum Sizes {
    small = 'small',
    regular = 'regular',
    big = 'big',
}

export enum ProjectStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    DELETED = 'DELETED',
}

/**@deprecated */
export { ProjectStatus as ProjectStatuses };

export enum UnitOfMeasure {
    CUBIC_METERS = 'CUBIC_METERS',
    TONNS = 'TONNS',
}

/**@deprecated */
export { UnitOfMeasure as Units };

export type IUnits = `${UnitOfMeasure}`;

// eslint-disable-next-line pinpointer/equal-enum
export enum Colors {
    green = 'green',
    red = 'red',
    orange = 'orange',
    gray = 'gray',
    blue = 'blue',
    darkBlue = 'darkBlue',
    lightBlue = 'lightBlue',
    white = 'white',
    transparentWhite = 'transparent-white',
    black = 'black',
    transparentBlack = 'transparent-black',
    transparent = 'transparent',
}

export type IColors = Colors;

export enum SettingsFields {
    featuresFlags = 'featuresFlags',
    transportConfiguration = 'transportConfiguration',
    companyConfiguration = 'companyConfiguration',
    keys = 'keys',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum TransportConfigurationFields {
    TONNS_PER_TRUCK = 'tonnsPerTruck',
    PRICE_PER_KM = 'pricePerKm',
    PRICE_PER_HOUR = 'pricePerHour',
    TAX = 'tax',
    FIXED_PRICE = 'fixedPrice',
    CO2_PER_KM = 'co2perKm',
}

export type ITransportConfigurationFields = TransportConfigurationFields;

export enum CompanyConfigurationFields {
    orgName = 'orgName',
    orgNumber = 'orgNumber',
    place = 'place',
    postNumber = 'postNumber',
    address = 'address',
    tel = 'tel',
    email = 'email',
}

export type ICompanyConfigurationFields = CompanyConfigurationFields;

export enum SettingsKeysFields {
    captchaSiteKey = 'captchaSiteKey',
    captchaSecretKey = 'captchaSecretKey',
    chatGptApiKey = 'chatGptApiKey',
    chatGptAssistantId = 'chatGptAssistantId',
    tickstarClientId = 'tickstarClientId',
    tickstarClientSecret = 'tickstarClientSecret',
    tickstarToken = 'tickstarToken',
    pinpointerGLN = 'pinpointerGLN',
    testDespatchPartyOrgNumber = 'testDespatchPartyOrgNumber',
    testConsigneeOrgNumber = 'testConsigneeOrgNumber',
}

export enum CustomCodesFields {
    customHeadCode = 'customHeadCode',
    customBodyCode = 'customBodyCode',
}

export type ISettingsKeysFields = SettingsKeysFields;

export enum JavascriptTypes {
    boolean = 'boolean',
    function = 'function',
    string = 'string',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum HttpCodes {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    NOT_ACCEPTABLE = 406,
    CONFLICT = 409,
    GONE = 410,
    PRECONDITION_FAILED = 412,
    PAYLOAD_TOO_LARGE = 413,
    UNPROCESSABLE_ENTITY = 422,
    LOCKED = 423,
    TOO_MANY_REQUESTS = 429,
    INTERNAL_SERVER_ERROR = 500,
}

export type THttpCodes = HttpCodes;

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum ContentTypes {
    applicationOctetStream = 'application/octet-stream',
    applicationJson = 'application/json',
    applicationPdf = 'application/pdf',
    textXml = 'text/xml',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum HttpHeaders {
    Accept = 'Accept',
    AccessToken = 'Access-Token',
    ClientSecret = 'Client-Secret',
    ContentType = 'Content-Type',
}

export enum DumpTypeFields {
    name = 'name',
    description = 'description',
    tonnsPerCubicM = 'tonnsPerCubicM',
    usedInDeclaration = 'usedInDeclaration',
}

export enum DeclarationFields {
    serialNumber = 'serialNumber',
    producer = 'producer',
    customerNr = 'customerNr',
    invoice = 'invoice',
    adressFrom = 'adressFrom',
    adressTo = 'adressTo',
    postNrFrom = 'postNrFrom',
    placeFrom = 'placeFrom',
    postNrTo = 'postNrTo',
    placeTo = 'placeTo',
    referenceFrom = 'referenceFrom',
    telFrom = 'telFrom',
    referenceTo = 'referenceTo',
    telTo = 'telTo',
    organizationNrFrom = 'organizationNrFrom',
    organizationNrTo = 'organizationNrTo',
    ewcCode = 'ewcCode',
    wasteType = 'wasteType',
    pollutionType = 'pollutionType',
    process = 'process',
    reason = 'reason',
    consistence = 'consistence',
    risks = 'risks',
    stability = 'stability',
    subject1 = 'subject1',
    value1 = 'value1',
    unit1 = 'unit1',
    subject2 = 'subject2',
    value2 = 'value2',
    unit2 = 'unit2',
    subject3 = 'subject3',
    value3 = 'value3',
    unit3 = 'unit3',
    subject4 = 'subject4',
    value4 = 'value4',
    unit4 = 'unit4',
    subject5 = 'subject5',
    value5 = 'value5',
    unit5 = 'unit5',
    subject6 = 'subject6',
    value6 = 'value6',
    unit6 = 'unit6',
    isTunk = 'isTunk',
    isContainer = 'isContainer',
    totalAmountCa = 'totalAmountCa',
    totalTon = 'totalTon',
    deliveryAmountCa = 'deliveryAmountCa',
    deliveryTon = 'deliveryTon',
    isRails = 'isRails',
    transport = 'transport',
    packaging1 = 'packaging1',
    quantity1 = 'quantity1',
    packaging2 = 'packaging2',
    quantity2 = 'quantity2',
    unNr = 'unNr',
    class = 'class',
    packaging3 = 'packaging3',
    quantity3 = 'quantity3',
    packaging4 = 'packaging4',
    quantity4 = 'quantity4',
    packaging = 'packaging',
    conveyor = 'conveyor',
    organizationNr = 'organizationNr',
    infoAttached = 'infoAttached',
    otherInfo = 'otherInfo',
    otherCar = 'otherCar',
    date = 'date',
    hasMassEffluentRecord = 'hasMassEffluentRecord',
    hasContaminatedSoilGroundWater = 'hasContaminatedSoilGroundWater',
    hasDifferentSmellAndColor = 'hasDifferentSmellAndColor',
    hasOccuredByExcavationOfPath = 'hasOccuredByExcavationOfPath',
    hasPetroleumProductBeenOnSite = 'hasPetroleumProductBeenOnSite',
    hasAnyAccidentsOccuredOnProperty = 'hasAnyAccidentsOccuredOnProperty',
    hasEnvironmentalTechnicalAssessment = 'hasEnvironmentalTechnicalAssessment',
    hasOtherAssessment = 'hasOtherAssessment',
    wasteOriginate = 'wasteOriginate',
    wasteOriginateMunicipality = 'wasteOriginateMunicipality',
}

export type IDeclarationFields = DeclarationFields;

export enum DeclarationConsistenceFields {
    isLiquid = 'isLiquid',
    isViscous = 'isViscous',
    isFixed = 'isFixed',
    isPasty = 'isPasty',
    isPowder = 'isPowder',
    isSmall = 'isSmall',
    isOther = 'isOther',
    other = 'other',
}

export enum DeclarationRisksFields {
    isToxic = 'isToxic',
    isFlammable = 'isFlammable',
    isAcid = 'isAcid',
    acid = 'acid',
    isHazard = 'isHazard',
    isExplosive = 'isExplosive',
    isAlk = 'isAlk',
    alk = 'alk',
    isHarmful = 'isHarmful',
    isFarlig = 'isFarlig',
    isCorrosive = 'isCorrosive',
    isOxidising = 'isOxidising',
    isHygiene = 'isHygiene',
}

export enum DeviationStatus {
    DRAFT = 'DRAFT',
    REQUESTED = 'REQUESTED',
    ANSWERED = 'ANSWERED',
    CLOSED = 'CLOSED',
}

export type IDeviationStatus = DeviationStatus;

// eslint-disable-next-line pinpointer/equal-enum
export enum DeviationFields {
    ticketNumber = 'ticketNumber',
    issue = 'issue',
    description = 'description',
    suggestedSolution = 'suggestedSolution',
    status = 'status',
    project = 'project',
    landfill = 'landfill',
    author = 'author',
    authorId = 'author.id',
    photos = 'photos',
    comments = 'comments',
    commentsAuthor = 'comments.author',
    dateSent = 'dateSent',
    dateClosed = 'dateClosed',
}

export type IDeviationField = DeviationFields;

export enum MultipartFileField {
    file = 'file',
    files = 'files',
}

export enum UserFields {
    id = 'id',
    name = 'name',
    surname = 'surname',
    password = 'password',
    email = 'email',
    phoneNumber = 'phoneNumber',
    orgName = 'orgName',
    orgNr = 'orgNr',
    language = 'language',
    customerId = 'customerId',
    customer = 'customer',
    blocked = 'blocked',
    createdAt = 'createdAt',
    lastLocation = 'lastLocation',
    role = 'role',
}

export enum CompanyFields {
    name = 'name',
}

export enum Language {
    en = 'en',
    sv = 'sv',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum ProjectFields {
    startDate = 'startDate',
    endDate = 'endDate',
    locationLat = 'location.lat',
    locationLng = 'location.lng',
    status = 'status',
    dumpLoads = 'dumpLoads',
    senders = 'senders',
    sendersIds = 'sendersIds',
    ownerUserId = 'ownerUserId',
    customerId = 'customerId',
    name = 'name',
    updatedBy = 'updatedBy',
    link = 'link',
    location = 'location',
    customer = 'customer',
    owner = 'owner',
    comment = 'comment',
    __v = '__v',
    _id = '_id',
    solidState = 'solidState',
    leachingState = 'leachingState',
    drivers = 'drivers',
    invoiceReference = 'invoiceReference',
    externalId = 'externalId',
}

export type IProjectField = ProjectFields;

export enum LandfillFields {
    id = 'id',
    name = 'name',
    location = 'location',
    comment = 'comment',
    workTime = 'workTime',
    owner = 'owner',
    receivers = 'receivers',
    receiverIds = 'receiverIds',
    customerId = 'customerId',
    marginPercent = 'marginPercent',
    minMargin = 'minMargin',
    status = 'status',
    createdAt = 'createdAt',
    deletedAt = 'deletedAt',
    gateFee = 'gateFee',
    customer = 'customer',
    certificates = 'certificates',
    capacity = 'capacity',
}

export type ILandfillField = LandfillFields;

export enum LandfillSubareaFields {
    maxAmount = 'maxAmount',
    currentAmount = 'currentAmount',
    reservedAmount = 'reservedAmount',
    deliveredAmount = 'deliveredAmount',
    unitOfMeasure = 'unitOfMeasure',
    comment = 'comment',
    priceBase = 'priceBase',
    priceNotStackable = 'priceNotStackable',
    solidState = 'solidState',
    leachingState = 'leachingState',
    allowSolidFA = 'allowSolidFA',
    priceOver50cm = 'priceOver50cm',
    onHold = 'onHold',
    grossPriceBase = 'grossPriceBase',
    grossPriceOver50cm = 'grossPriceOver50cm',
    grossPriceNotStackable = 'grossPriceNotStackable',
    layerType = 'layerType',
}

export type ILandfillSubareaField = LandfillSubareaFields;

export enum OrderFields {
    documentNumber = 'documentNumber',
    orderNumber = 'orderNumber',
    orderStatus = 'orderStatus',
    customer = 'customer',
    deliveryAddress = 'deliveryAddress',
    plannedStartDate = 'plannedStartDate',
    deliveredVolume = 'deliveredVolume',
    salesResponsible = 'salesResponsible',
    projectNumber = 'projectNumber',
    history = 'history',
    deliveryName = 'deliveryName',
    targetSubarea = 'targetSubarea',
    dumpLoadComment = 'dumpLoadComment',
    dumpLoad = 'dumpLoad',
    dumpLoadDumpType = 'dumpLoadDumpType',
    deliveryLines = 'deliveryLines',
    project = 'project',
    pricePerTonn = 'pricePerTonn',
    places = 'places',
    deliveryLineCount = 'deliveryLineCount',
    customerId = 'customerId',
}

export type IOrderFields = OrderFields;

// eslint-disable-next-line pinpointer/equal-enum
export enum DateTextFormat {
    years = 'yyyy',
    months = 'yyyy-MM',
    weeks = 'RRRR-II',
}

export enum SignStatus {
    CREATED = 'CREATED',
    SIGN_PROGRESS = 'SIGN_PROGRESS',
    SIGNED = 'SIGNED',
    ERROR = 'ERROR',
    TIME_OUTED = 'TIME_OUTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
}

/**@deprecated */
export { SignStatus as SignDocumentStatuses };

export enum LandfillSubareaHistoryType {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
}

export enum LocationType {
    VALID = 'VALID',
    ON_WATER = 'ON_WATER',
    UNKNOWN = 'UNKNOWN',
    UNREACHABLE = 'UNREACHABLE',
}

export enum LockEntityType {
    ORDER = 'ORDER',
}

export enum DeliveryLineStatus {
    EXCAVATED = 'EXCAVATED',
    DELIVERING = 'DELIVERING',
    DELIVERED = 'DELIVERED',
    VERIFIED = 'VERIFIED',
}

export enum ReleaseNoteType {
    CUSTOMER = 'CUSTOMER',
    DRIVER = 'DRIVER',
    ADMIN = 'ADMIN',
}

export enum DespatchAdviceType {
    ORIGINAL = 'ORIGINAL',
    REPLACE = 'REPLACE',
    CANCELLATION = 'CANCELLATION',
}

export enum DespatchAdviceResponseType {
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
}

export enum DespatchAdviceCategory {
    EXCAVATING = 'EXCAVATING',
    TRANSPORTING = 'TRANSPORTING',
    DUMPING = 'DUMPING',
}

export enum BeastStatus {
    DISABLED = 'DISABLED',
    NOT_SENT = 'NOT_SENT',
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
}

export enum DeliveryLineFields {
    orderNumber = 'orderNumber',
    updatedBy = 'updatedBy',
    weight = 'weight',
    plannedStartDate = 'plannedStartDate',
    companyId = 'companyId',
    truckRegistrationNumber = 'truckRegistrationNumber',
    customerInvoiceNumber = 'customerInvoiceNumber',
    transportationInvoiceNumber = 'transportationInvoiceNumber',
    landfillInvoiceNumber = 'landfillInvoiceNumber',
    deliveryLineIdx = 'deliveryLineIdx',
    createdAt = 'createdAt',
    driver = 'driver',
    deletedAt = 'deletedAt',
    status = 'status',
    truckCapacity = 'truckCapacity',
    driverId = 'driverId',
    excavatorOperatorId = 'excavatorOperatorId',
    receiverCheck = 'receiverCheck',
    receiverComment = 'receiverComment',
    startDeliveryDate = 'startDeliveryDate',
    vehicleTypeId = 'vehicleTypeId',
    batchId = 'batchId',
}

export enum ProjectDocumentType {
    CERTIFICATE = 'CERTIFICATE',
    ANALYSIS = 'ANALYSIS',
}

export enum DeliveryStatisticInterval {
    WEEK = 'WEEK',
    YEAR = 'YEAR',
}

export enum Fuel {
    Diesel = 'Diesel',
    HVO100 = 'HVO100',
    Electric = 'Electric',
}

export enum Engine {
    Euro4 = 'Euro4',
    Euro5 = 'Euro5',
    Euro6 = 'Euro6',
    Euro7 = 'Euro7',
}

export enum VehicleKind {
    TRANSPORT = 'TRANSPORT',
    ON_SITE = 'ON_SITE',
}

export enum EntityActionType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    RESTORE = 'RESTORE',
}

export enum DespatchAdviceSource {
    DELIVERY_LINE = 'DELIVERY_LINE',
    TIME_SPENT = 'TIME_SPENT',
}

export enum TransferType {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
    INTERNAL = 'INTERNAL',
}
