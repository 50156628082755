/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeQuery
// ====================================================

export interface MeQuery_me_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface MeQuery_me {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  startPage: string | null;
  customer: MeQuery_me_customer | null;
  customerId: string | null;
}

export interface MeQuery {
  me: MeQuery_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AccountUpdateMutation
// ====================================================

export interface AccountUpdateMutation_selfUpdateProfile_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AccountUpdateMutation_selfUpdateProfile {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  startPage: string | null;
  customer: AccountUpdateMutation_selfUpdateProfile_customer | null;
  customerId: string | null;
}

export interface AccountUpdateMutation {
  selfUpdateProfile: AccountUpdateMutation_selfUpdateProfile | null;
}

export interface AccountUpdateMutationVariables {
  input: ProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AccountChangePassword
// ====================================================

export interface AccountChangePassword {
  selfChangePassword: boolean | null;
}

export interface AccountChangePasswordVariables {
  input: ChangePasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminBeastDataQuery
// ====================================================

export interface AdminBeastDataQuery_despatchAdvices {
  __typename: "DespatchAdvice";
  id: string;
  category: DespatchAdviceCategory;
  error: string | null;
  canSend: boolean;
  adviceType: DespatchAdviceType;
  createdAt: GQL_Date | null;
  sentAt: GQL_Date | null;
  transactionId: string | null;
  body: string;
}

export interface AdminBeastDataQuery_despatchAdviceResponses_deliveryLine_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminBeastDataQuery_despatchAdviceResponses_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
  deliveryLineIdx: number;
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  status: DeliveryLineStatus;
  company: AdminBeastDataQuery_despatchAdviceResponses_deliveryLine_company | null;
}

export interface AdminBeastDataQuery_despatchAdviceResponses {
  __typename: "DespatchAdviceResponse";
  id: string;
  date: GQL_Date;
  body: string;
  despatchAdviceId: string | null;
  responseType: DespatchAdviceResponseType;
  transactionId: string;
  declineReason: string | null;
  deliveryLine: AdminBeastDataQuery_despatchAdviceResponses_deliveryLine | null;
}

export interface AdminBeastDataQuery {
  despatchAdvices: AdminBeastDataQuery_despatchAdvices[];
  despatchAdviceResponses: AdminBeastDataQuery_despatchAdviceResponses[];
}

export interface AdminBeastDataQueryVariables {
  entityId: string;
  source: DespatchAdviceSource;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminSendDespatchAdvicesMutation
// ====================================================

export interface AdminSendDespatchAdvicesMutation_sendDespatchAdvice {
  __typename: "DespatchAdvice";
  id: string;
  category: DespatchAdviceCategory;
  error: string | null;
  canSend: boolean;
  adviceType: DespatchAdviceType;
  createdAt: GQL_Date | null;
  sentAt: GQL_Date | null;
  transactionId: string | null;
  body: string;
}

export interface AdminSendDespatchAdvicesMutation {
  sendDespatchAdvice: AdminSendDespatchAdvicesMutation_sendDespatchAdvice;
}

export interface AdminSendDespatchAdvicesMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DespatchAdviceResponsesPaged
// ====================================================

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node_deliveryLine_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
  deliveryLineIdx: number;
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  status: DeliveryLineStatus;
  company: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node_deliveryLine_company | null;
}

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node {
  __typename: "DespatchAdviceResponse";
  id: string;
  date: GQL_Date;
  body: string;
  despatchAdviceId: string | null;
  responseType: DespatchAdviceResponseType;
  transactionId: string;
  declineReason: string | null;
  deliveryLine: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node_deliveryLine | null;
}

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges {
  __typename: "DespatchAdviceResponseEdge";
  cursor: string;
  node: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges_node;
}

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged {
  __typename: "DespatchAdviceResponseConnection";
  edges: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_edges[];
  pageInfo: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface DespatchAdviceResponsesPaged {
  despatchAdviceResponsesPaged: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged;
}

export interface DespatchAdviceResponsesPagedVariables {
  filter?: DespatchAdviceResponseFilterInput | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDespatchAdviceDefaultSettingsQuery
// ====================================================

export interface AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings_despatchParty {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings_consignee {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings {
  __typename: "DefaultDespatchAdviceSettings";
  despatchParty: AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings_despatchParty | null;
  consignee: AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings_consignee | null;
  allowSelect: boolean;
}

export interface AdminDespatchAdviceDefaultSettingsQuery {
  despatchAdviceDefaultSettings: AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DespatchAdviceResponsesRequest
// ====================================================

export interface DespatchAdviceResponsesRequest_requestAdviceResponses_deliveryLine_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DespatchAdviceResponsesRequest_requestAdviceResponses_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
  deliveryLineIdx: number;
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  status: DeliveryLineStatus;
  company: DespatchAdviceResponsesRequest_requestAdviceResponses_deliveryLine_company | null;
}

export interface DespatchAdviceResponsesRequest_requestAdviceResponses {
  __typename: "DespatchAdviceResponse";
  id: string;
  date: GQL_Date;
  body: string;
  despatchAdviceId: string | null;
  responseType: DespatchAdviceResponseType;
  transactionId: string;
  declineReason: string | null;
  deliveryLine: DespatchAdviceResponsesRequest_requestAdviceResponses_deliveryLine | null;
}

export interface DespatchAdviceResponsesRequest {
  requestAdviceResponses: DespatchAdviceResponsesRequest_requestAdviceResponses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BeastMaterialListQuery
// ====================================================

export interface BeastMaterialListQuery_beastMaterials {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface BeastMaterialListQuery {
  beastMaterials: BeastMaterialListQuery_beastMaterials[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BeastMaterialCreateMutation
// ====================================================

export interface BeastMaterialCreateMutation_createBeastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface BeastMaterialCreateMutation {
  createBeastMaterial: BeastMaterialCreateMutation_createBeastMaterial;
}

export interface BeastMaterialCreateMutationVariables {
  input: BeastMaterialCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BeastMaterialUpdateMutation
// ====================================================

export interface BeastMaterialUpdateMutation_updateBeastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface BeastMaterialUpdateMutation {
  updateBeastMaterial: BeastMaterialUpdateMutation_updateBeastMaterial | null;
}

export interface BeastMaterialUpdateMutationVariables {
  input: BeastMaterialUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BeastMaterialRemoveMutation
// ====================================================

export interface BeastMaterialRemoveMutation {
  removeBeastMaterial: boolean;
}

export interface BeastMaterialRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlacklistQuery
// ====================================================

export interface BlacklistQuery_emailsblackedlist {
  __typename: "EmailsBlacklisted";
  id: string;
  email: GQL_Email;
}

export interface BlacklistQuery {
  emailsblackedlist: BlacklistQuery_emailsblackedlist[];
}

export interface BlacklistQueryVariables {
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFromBlacklistMutation
// ====================================================

export interface DeleteFromBlacklistMutation {
  emailBlackedRemove: boolean | null;
}

export interface DeleteFromBlacklistMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddToBlacklistMutation
// ====================================================

export interface AddToBlacklistMutation_emailBlackedCreate {
  __typename: "EmailsBlacklisted";
  id: string;
  email: GQL_Email;
}

export interface AddToBlacklistMutation {
  emailBlackedCreate: AddToBlacklistMutation_emailBlackedCreate | null;
}

export interface AddToBlacklistMutationVariables {
  input: AddBlackedlist;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompaniesSearchQuery
// ====================================================

export interface CompaniesSearchQuery_pplCompanies {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface CompaniesSearchQuery {
  pplCompanies: CompaniesSearchQuery_pplCompanies[];
}

export interface CompaniesSearchQueryVariables {
  search: string;
  limit: number;
  isTransportCompany?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompaniesPagedQuery
// ====================================================

export interface CompaniesPagedQuery_companiesPaged_edges_node {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
  lastCommentText: string | null;
}

export interface CompaniesPagedQuery_companiesPaged_edges {
  __typename: "CompanyEdge";
  cursor: string;
  node: CompaniesPagedQuery_companiesPaged_edges_node;
}

export interface CompaniesPagedQuery_companiesPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface CompaniesPagedQuery_companiesPaged {
  __typename: "CompanyConnection";
  edges: CompaniesPagedQuery_companiesPaged_edges[];
  pageInfo: CompaniesPagedQuery_companiesPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface CompaniesPagedQuery {
  companiesPaged: CompaniesPagedQuery_companiesPaged;
}

export interface CompaniesPagedQueryVariables {
  filter?: PplCompanyFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyGenerateAPIKeyMutation
// ====================================================

export interface CompanyGenerateAPIKeyMutation_companyGenerateAPIKey {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
}

export interface CompanyGenerateAPIKeyMutation {
  companyGenerateAPIKey: CompanyGenerateAPIKeyMutation_companyGenerateAPIKey | null;
}

export interface CompanyGenerateAPIKeyMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyRemoveApiKeyMutation
// ====================================================

export interface CompanyRemoveApiKeyMutation_companyRemoveApiKey {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
}

export interface CompanyRemoveApiKeyMutation {
  companyRemoveApiKey: CompanyRemoveApiKeyMutation_companyRemoveApiKey | null;
}

export interface CompanyRemoveApiKeyMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyQuery
// ====================================================

export interface CompanyQuery_company_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface CompanyQuery_company_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: CompanyQuery_company_comments_author | null;
}

export interface CompanyQuery_company {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
  comments: CompanyQuery_company_comments[] | null;
  lastCommentText: string | null;
}

export interface CompanyQuery {
  company: CompanyQuery_company | null;
}

export interface CompanyQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyAddCommentMutation
// ====================================================

export interface CompanyAddCommentMutation_companyAddComment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface CompanyAddCommentMutation_companyAddComment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: CompanyAddCommentMutation_companyAddComment_comments_author | null;
}

export interface CompanyAddCommentMutation_companyAddComment {
  __typename: "PplCompany";
  id: string;
  comments: CompanyAddCommentMutation_companyAddComment_comments[] | null;
  lastCommentText: string | null;
}

export interface CompanyAddCommentMutation {
  companyAddComment: CompanyAddCommentMutation_companyAddComment | null;
}

export interface CompanyAddCommentMutationVariables {
  companyId: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyUpdateMutation
// ====================================================

export interface CompanyUpdateMutation_companyUpdate {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
}

export interface CompanyUpdateMutation {
  companyUpdate: CompanyUpdateMutation_companyUpdate | null;
}

export interface CompanyUpdateMutationVariables {
  input: CompanyUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyCreateMutation
// ====================================================

export interface CompanyCreateMutation_companyCreate {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
}

export interface CompanyCreateMutation {
  companyCreate: CompanyCreateMutation_companyCreate;
}

export interface CompanyCreateMutationVariables {
  input: CompanyCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyRemoveMutation
// ====================================================

export interface CompanyRemoveMutation {
  companyRemove: boolean | null;
}

export interface CompanyRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyActiveConnectedEntitiesQuery
// ====================================================

export interface CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_projects {
  __typename: "Project";
  id: string;
  name: string;
}

export interface CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_landfills {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_users {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities {
  __typename: "ConnectedEntitiesInfo";
  projects: CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_projects[];
  landfills: CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_landfills[];
  users: CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities_users[];
}

export interface CompanyActiveConnectedEntitiesQuery {
  companyActiveConnectedEntities: CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities;
}

export interface CompanyActiveConnectedEntitiesQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecalculateProjectSolidTestResults
// ====================================================

export interface RecalculateProjectSolidTestResults_recalculateProjectSolidTestResults {
  __typename: "MaintenanceResult";
  processed: number;
  modified: number;
}

export interface RecalculateProjectSolidTestResults {
  recalculateProjectSolidTestResults: RecalculateProjectSolidTestResults_recalculateProjectSolidTestResults;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecalculateLandfillSolidTestResults
// ====================================================

export interface RecalculateLandfillSolidTestResults_recalculateLandfillSolidTestResults {
  __typename: "MaintenanceResult";
  processed: number;
  modified: number;
}

export interface RecalculateLandfillSolidTestResults {
  recalculateLandfillSolidTestResults: RecalculateLandfillSolidTestResults_recalculateLandfillSolidTestResults;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDeliveryLinesPagedQuery
// ====================================================

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_vehicleType_fuelConsumptions[];
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_driver | null;
  excavatorOperator: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges {
  __typename: "DeliveryLineEdge";
  cursor: string;
  node: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges_node;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminDeliveryLinesPagedQuery_deliveryLinesPaged {
  __typename: "DeliveryLineConnection";
  edges: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_edges[];
  pageInfo: AdminDeliveryLinesPagedQuery_deliveryLinesPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminDeliveryLinesPagedQuery {
  deliveryLinesPaged: AdminDeliveryLinesPagedQuery_deliveryLinesPaged;
}

export interface AdminDeliveryLinesPagedQueryVariables {
  filter?: DeliveryLineFilterInput | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDeliveryLineHistoryQuery
// ====================================================

export interface AdminDeliveryLineHistoryQuery_pplDeliveryLine_history_user {
  __typename: "Actor";
  id: string;
  name: string;
  surname: string | null;
  role: UserRole;
}

export interface AdminDeliveryLineHistoryQuery_pplDeliveryLine_history_changes {
  __typename: "DeliveryLineHistoryLine";
  field: string | null;
  valueOld: string | null;
  valueNew: string | null;
}

export interface AdminDeliveryLineHistoryQuery_pplDeliveryLine_history {
  __typename: "DeliveryLineHistoryRecord";
  user: AdminDeliveryLineHistoryQuery_pplDeliveryLine_history_user | null;
  changes: AdminDeliveryLineHistoryQuery_pplDeliveryLine_history_changes[];
  createdAt: GQL_Date | null;
}

export interface AdminDeliveryLineHistoryQuery_pplDeliveryLine {
  __typename: "PplDeliveryLine";
  history: AdminDeliveryLineHistoryQuery_pplDeliveryLine_history[] | null;
}

export interface AdminDeliveryLineHistoryQuery {
  pplDeliveryLine: AdminDeliveryLineHistoryQuery_pplDeliveryLine | null;
}

export interface AdminDeliveryLineHistoryQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryLineUpdateMutation
// ====================================================

export interface DeliveryLineUpdateMutation_deliveryLineUpdate_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLineUpdateMutation_deliveryLineUpdate_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineUpdateMutation_deliveryLineUpdate_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineUpdateMutation_deliveryLineUpdate_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DeliveryLineUpdateMutation_deliveryLineUpdate_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DeliveryLineUpdateMutation_deliveryLineUpdate_vehicleType_fuelConsumptions[];
}

export interface DeliveryLineUpdateMutation_deliveryLineUpdate {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DeliveryLineUpdateMutation_deliveryLineUpdate_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DeliveryLineUpdateMutation_deliveryLineUpdate_driver | null;
  excavatorOperator: DeliveryLineUpdateMutation_deliveryLineUpdate_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DeliveryLineUpdateMutation_deliveryLineUpdate_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DeliveryLineUpdateMutation {
  deliveryLineUpdate: DeliveryLineUpdateMutation_deliveryLineUpdate;
}

export interface DeliveryLineUpdateMutationVariables {
  input: DeliveryLineUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryLineDeleteMutation
// ====================================================

export interface DeliveryLineDeleteMutation {
  deliveryLineDelete: boolean;
}

export interface DeliveryLineDeleteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryLineRestoreMutation
// ====================================================

export interface DeliveryLineRestoreMutation {
  deliveryLineRestore: boolean;
}

export interface DeliveryLineRestoreMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryLineCreateMutation
// ====================================================

export interface DeliveryLineCreateMutation_deliveryLineCreate_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLineCreateMutation_deliveryLineCreate_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineCreateMutation_deliveryLineCreate_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineCreateMutation_deliveryLineCreate_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DeliveryLineCreateMutation_deliveryLineCreate_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DeliveryLineCreateMutation_deliveryLineCreate_vehicleType_fuelConsumptions[];
}

export interface DeliveryLineCreateMutation_deliveryLineCreate {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DeliveryLineCreateMutation_deliveryLineCreate_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DeliveryLineCreateMutation_deliveryLineCreate_driver | null;
  excavatorOperator: DeliveryLineCreateMutation_deliveryLineCreate_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DeliveryLineCreateMutation_deliveryLineCreate_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DeliveryLineCreateMutation {
  deliveryLineCreate: DeliveryLineCreateMutation_deliveryLineCreate;
}

export interface DeliveryLineCreateMutationVariables {
  input: DeliveryLineCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryLinesUpdateMutation
// ====================================================

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DeliveryLinesUpdateMutation_deliveryLinesUpdate_vehicleType_fuelConsumptions[];
}

export interface DeliveryLinesUpdateMutation_deliveryLinesUpdate {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DeliveryLinesUpdateMutation_deliveryLinesUpdate_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DeliveryLinesUpdateMutation_deliveryLinesUpdate_driver | null;
  excavatorOperator: DeliveryLinesUpdateMutation_deliveryLinesUpdate_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DeliveryLinesUpdateMutation_deliveryLinesUpdate_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DeliveryLinesUpdateMutation {
  deliveryLinesUpdate: DeliveryLinesUpdateMutation_deliveryLinesUpdate[];
}

export interface DeliveryLinesUpdateMutationVariables {
  input: DeliveryLineUpdateInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveryLineQuery
// ====================================================

export interface DeliveryLineQuery_pplDeliveryLine_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLineQuery_pplDeliveryLine_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineQuery_pplDeliveryLine_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineQuery_pplDeliveryLine_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DeliveryLineQuery_pplDeliveryLine_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DeliveryLineQuery_pplDeliveryLine_vehicleType_fuelConsumptions[];
}

export interface DeliveryLineQuery_pplDeliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DeliveryLineQuery_pplDeliveryLine_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DeliveryLineQuery_pplDeliveryLine_driver | null;
  excavatorOperator: DeliveryLineQuery_pplDeliveryLine_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DeliveryLineQuery_pplDeliveryLine_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DeliveryLineQuery {
  pplDeliveryLine: DeliveryLineQuery_pplDeliveryLine | null;
}

export interface DeliveryLineQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDumpLoadsPagedQuery
// ====================================================

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_userLinks[];
  location: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_location;
  customer: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_customer | null;
  customerId: string;
  owner: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_analysisFiles[];
  priceData: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_dumpType | null;
  landfill: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_landfill | null;
  transportSchedule: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  inbound: boolean;
  skipMatching: boolean;
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  project: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project | null;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges {
  __typename: "ProjectLoadEdge";
  cursor: string;
  node: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminDumpLoadsPagedQuery_dumpLoadsPaged {
  __typename: "ProjectLoadConnection";
  edges: AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges[];
  pageInfo: AdminDumpLoadsPagedQuery_dumpLoadsPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminDumpLoadsPagedQuery {
  dumpLoadsPaged: AdminDumpLoadsPagedQuery_dumpLoadsPaged;
}

export interface AdminDumpLoadsPagedQueryVariables {
  filter?: DumpLoadsFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDumpLoadsPagedItemQuery
// ====================================================

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: AdminDumpLoadsPagedItemQuery_projectDumpload_project_userLinks[];
  location: AdminDumpLoadsPagedItemQuery_projectDumpload_project_location;
  customer: AdminDumpLoadsPagedItemQuery_projectDumpload_project_customer | null;
  customerId: string;
  owner: AdminDumpLoadsPagedItemQuery_projectDumpload_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface AdminDumpLoadsPagedItemQuery_projectDumpload {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminDumpLoadsPagedItemQuery_projectDumpload_analysisFiles[];
  priceData: AdminDumpLoadsPagedItemQuery_projectDumpload_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminDumpLoadsPagedItemQuery_projectDumpload_dumpType | null;
  landfill: AdminDumpLoadsPagedItemQuery_projectDumpload_landfill | null;
  transportSchedule: AdminDumpLoadsPagedItemQuery_projectDumpload_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  inbound: boolean;
  skipMatching: boolean;
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  project: AdminDumpLoadsPagedItemQuery_projectDumpload_project | null;
}

export interface AdminDumpLoadsPagedItemQuery {
  projectDumpload: AdminDumpLoadsPagedItemQuery_projectDumpload;
}

export interface AdminDumpLoadsPagedItemQueryVariables {
  projectId: string;
  dumpLoadId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDetailedDumpLoadQuery
// ====================================================

export interface AdminDetailedDumpLoadQuery_projectDumpload_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: AdminDetailedDumpLoadQuery_projectDumpload_comments_author | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_workTime[];
  location: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_location;
  gateFee: number | null;
  status: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo {
  __typename: "IDestination";
  landfill: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_landfill;
  subarea: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_driver | null;
  excavatorOperator: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_vehicleType_fuelConsumptions[];
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: AdminDetailedDumpLoadQuery_projectDumpload_timeSpent_user | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_weeks[];
  months: AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_months[];
  years: AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods_years[];
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_weeks[];
  months: AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_months[];
  years: AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods_years[];
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: AdminDetailedDumpLoadQuery_projectDumpload_project_userLinks[];
  location: AdminDetailedDumpLoadQuery_projectDumpload_project_location;
  customer: AdminDetailedDumpLoadQuery_projectDumpload_project_customer | null;
  customerId: string;
  owner: AdminDetailedDumpLoadQuery_projectDumpload_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface AdminDetailedDumpLoadQuery_projectDumpload {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminDetailedDumpLoadQuery_projectDumpload_analysisFiles[];
  priceData: AdminDetailedDumpLoadQuery_projectDumpload_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminDetailedDumpLoadQuery_projectDumpload_dumpType | null;
  destinationLandfill: AdminDetailedDumpLoadQuery_projectDumpload_destinationLandfill | null;
  landfill: AdminDetailedDumpLoadQuery_projectDumpload_landfill | null;
  transportSchedule: AdminDetailedDumpLoadQuery_projectDumpload_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: AdminDetailedDumpLoadQuery_projectDumpload_declaration | null;
  comments: AdminDetailedDumpLoadQuery_projectDumpload_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: AdminDetailedDumpLoadQuery_projectDumpload_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: AdminDetailedDumpLoadQuery_projectDumpload_actionsToShow[];
  yourOrderNumber: string | null;
  substances: AdminDetailedDumpLoadQuery_projectDumpload_substances[];
  deliveryLines: AdminDetailedDumpLoadQuery_projectDumpload_deliveryLines[];
  timeSpent: AdminDetailedDumpLoadQuery_projectDumpload_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: AdminDetailedDumpLoadQuery_projectDumpload_volumesPerPeriods;
  volumesSplitPerPeriods: AdminDetailedDumpLoadQuery_projectDumpload_volumesSplitPerPeriods;
  beastMaterial: AdminDetailedDumpLoadQuery_projectDumpload_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
  project: AdminDetailedDumpLoadQuery_projectDumpload_project | null;
}

export interface AdminDetailedDumpLoadQuery {
  projectDumpload: AdminDetailedDumpLoadQuery_projectDumpload;
}

export interface AdminDetailedDumpLoadQueryVariables {
  projectId: string;
  dumpLoadId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDetailedDumpLoadByNumber
// ====================================================

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_comments_author | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_workTime[];
  location: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_location;
  gateFee: number | null;
  status: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo {
  __typename: "IDestination";
  landfill: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_landfill;
  subarea: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_driver | null;
  excavatorOperator: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_vehicleType_fuelConsumptions[];
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent_user | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_weeks[];
  months: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_months[];
  years: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods_years[];
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_weeks[];
  months: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_months[];
  years: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods_years[];
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_userLinks[];
  location: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_location;
  customer: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_customer | null;
  customerId: string;
  owner: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface AdminDetailedDumpLoadByNumber_dumpLoadByNumber {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_analysisFiles[];
  priceData: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_dumpType | null;
  destinationLandfill: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationLandfill | null;
  landfill: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_landfill | null;
  transportSchedule: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_declaration | null;
  comments: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_actionsToShow[];
  yourOrderNumber: string | null;
  substances: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_substances[];
  deliveryLines: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_deliveryLines[];
  timeSpent: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesPerPeriods;
  volumesSplitPerPeriods: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_volumesSplitPerPeriods;
  beastMaterial: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
  project: AdminDetailedDumpLoadByNumber_dumpLoadByNumber_project | null;
}

export interface AdminDetailedDumpLoadByNumber {
  dumpLoadByNumber: AdminDetailedDumpLoadByNumber_dumpLoadByNumber;
}

export interface AdminDetailedDumpLoadByNumberVariables {
  serialNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: calculateDestinationResult
// ====================================================

export interface calculateDestinationResult_calculateDestinationResult_destinations_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface calculateDestinationResult_calculateDestinationResult_destinations_landfill_location {
  __typename: "Location";
  text: string;
}

export interface calculateDestinationResult_calculateDestinationResult_destinations_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface calculateDestinationResult_calculateDestinationResult_destinations_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: calculateDestinationResult_calculateDestinationResult_destinations_landfill_workTime[];
  location: calculateDestinationResult_calculateDestinationResult_destinations_landfill_location;
  gateFee: number | null;
  status: calculateDestinationResult_calculateDestinationResult_destinations_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface calculateDestinationResult_calculateDestinationResult_destinations_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface calculateDestinationResult_calculateDestinationResult_destinations {
  __typename: "IDestination";
  landfill: calculateDestinationResult_calculateDestinationResult_destinations_landfill;
  subarea: calculateDestinationResult_calculateDestinationResult_destinations_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface calculateDestinationResult_calculateDestinationResult {
  __typename: "DestinationResult";
  infoCode: InfoCode | null;
  destinations: calculateDestinationResult_calculateDestinationResult_destinations[];
}

export interface calculateDestinationResult {
  calculateDestinationResult: calculateDestinationResult_calculateDestinationResult | null;
}

export interface calculateDestinationResultVariables {
  projectId: string;
  input: DumpLoadDeliveryInfoInput;
  substances?: SubstanceItemInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DumpLoadDeclarationQuery
// ====================================================

export interface DumpLoadDeclarationQuery_projectLoadDeclaration_consistence {
  __typename: "DeclarationConsistence";
  isLiquid: boolean | null;
  isViscous: boolean | null;
  isFixed: boolean | null;
  isPasty: boolean | null;
  isPowder: boolean | null;
  isSmall: boolean | null;
  isOther: boolean | null;
  other: string | null;
}

export interface DumpLoadDeclarationQuery_projectLoadDeclaration_risks {
  __typename: "DeclarationRisks";
  isToxic: boolean | null;
  isHazard: boolean | null;
  isHarmful: boolean | null;
  isCorrosive: boolean | null;
  isOxidising: boolean | null;
  isFlammable: boolean | null;
  isExplosive: boolean | null;
  isFarlig: boolean | null;
  isHygiene: boolean | null;
  isAcid: boolean | null;
  acid: string | null;
  isAlk: boolean | null;
  alk: string | null;
}

export interface DumpLoadDeclarationQuery_projectLoadDeclaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
  producer: string | null;
  invoice: string | null;
  customerNr: string | null;
  adressFrom: string | null;
  adressTo: string | null;
  placeFrom: string | null;
  placeTo: string | null;
  postNrFrom: string | null;
  postNrTo: string | null;
  referenceFrom: string | null;
  referenceTo: string | null;
  telFrom: string | null;
  telTo: string | null;
  organizationNrFrom: string | null;
  organizationNrTo: string | null;
  ewcCode: string | null;
  process: string | null;
  reason: string | null;
  wasteType: string | null;
  pollutionType: string | null;
  subject1: string | null;
  subject2: string | null;
  subject3: string | null;
  subject4: string | null;
  subject5: string | null;
  subject6: string | null;
  value1: string | null;
  value2: string | null;
  value3: string | null;
  value4: string | null;
  value5: string | null;
  value6: string | null;
  unit1: string | null;
  unit2: string | null;
  unit3: string | null;
  unit4: string | null;
  unit5: string | null;
  unit6: string | null;
  isTunk: boolean | null;
  isContainer: boolean | null;
  isRails: boolean | null;
  totalAmountCa: string | null;
  deliveryAmountCa: string | null;
  totalTon: string | null;
  deliveryTon: string | null;
  packaging1: string | null;
  packaging2: string | null;
  packaging3: string | null;
  packaging4: string | null;
  quantity1: string | null;
  quantity2: string | null;
  quantity3: string | null;
  quantity4: string | null;
  transport: string | null;
  unNr: string | null;
  class: string | null;
  packaging: string | null;
  conveyor: string | null;
  organizationNr: string | null;
  otherInfo: string | null;
  infoAttached: boolean | null;
  otherCar: string | null;
  date: string | null;
  stability: string | null;
  consistence: DumpLoadDeclarationQuery_projectLoadDeclaration_consistence | null;
  risks: DumpLoadDeclarationQuery_projectLoadDeclaration_risks | null;
  hasMassEffluentRecord: boolean | null;
  hasContaminatedSoilGroundWater: boolean | null;
  hasDifferentSmellAndColor: boolean | null;
  hasOccuredByExcavationOfPath: boolean | null;
  hasPetroleumProductBeenOnSite: boolean | null;
  hasAnyAccidentsOccuredOnProperty: boolean | null;
  hasEnvironmentalTechnicalAssessment: boolean | null;
  hasOtherAssessment: boolean | null;
  wasteOriginate: string | null;
  wasteOriginateMunicipality: string | null;
}

export interface DumpLoadDeclarationQuery {
  projectLoadDeclaration: DumpLoadDeclarationQuery_projectLoadDeclaration | null;
}

export interface DumpLoadDeclarationQueryVariables {
  projectId: string;
  projectLoadId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddDumpLoadCommentMutation
// ====================================================

export interface AddDumpLoadCommentMutation_projectLoadAddComment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface AddDumpLoadCommentMutation_projectLoadAddComment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: AddDumpLoadCommentMutation_projectLoadAddComment_comments_author | null;
}

export interface AddDumpLoadCommentMutation_projectLoadAddComment_project {
  __typename: "Project";
  id: string;
  ver: number;
}

export interface AddDumpLoadCommentMutation_projectLoadAddComment {
  __typename: "ProjectLoad";
  id: string;
  lastCommentText: string | null;
  comments: AddDumpLoadCommentMutation_projectLoadAddComment_comments[] | null;
  project: AddDumpLoadCommentMutation_projectLoadAddComment_project | null;
}

export interface AddDumpLoadCommentMutation {
  projectLoadAddComment: AddDumpLoadCommentMutation_projectLoadAddComment;
}

export interface AddDumpLoadCommentMutationVariables {
  projectId: string;
  dumpLoadId: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadUpdateMutation
// ====================================================

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_userLinks[];
  location: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_location;
  customer: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_customer | null;
  customerId: string;
  owner: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_analysisFiles[];
  priceData: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_dumpType | null;
  landfill: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_landfill | null;
  transportSchedule: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  inbound: boolean;
  skipMatching: boolean;
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  project: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads_project | null;
}

export interface DumpLoadUpdateMutation_projectLoadUpdate {
  __typename: "Project";
  dumpLoads: DumpLoadUpdateMutation_projectLoadUpdate_dumpLoads[];
}

export interface DumpLoadUpdateMutation {
  projectLoadUpdate: DumpLoadUpdateMutation_projectLoadUpdate | null;
}

export interface DumpLoadUpdateMutationVariables {
  projectId: string;
  input: ProjectLoadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadCreateMutation
// ====================================================

export interface DumpLoadCreateMutation_projectLoadCreate_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
}

export interface DumpLoadCreateMutation_projectLoadCreate {
  __typename: "Project";
  id: string;
  status: ProjectStatus;
  dumpLoads: DumpLoadCreateMutation_projectLoadCreate_dumpLoads[];
  ver: number;
}

export interface DumpLoadCreateMutation {
  projectLoadCreate: DumpLoadCreateMutation_projectLoadCreate | null;
}

export interface DumpLoadCreateMutationVariables {
  projectId: string;
  input: ProjectLoadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadsUpdateStatusMutation
// ====================================================

export interface DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
}

export interface DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  status: LoadStatus | null;
  dumpType: DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads_dumpType | null;
  solidTestResult: ContaminationLevel | null;
  comment: string | null;
  amountInTons: number | null;
  priceData: DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads_priceData;
}

export interface DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus {
  __typename: "Project";
  id: string;
  status: ProjectStatus;
  ver: number;
  dumpLoads: DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads[];
}

export interface DumpLoadsUpdateStatusMutation {
  projectLoadsUpdateStatus: DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus | null;
}

export interface DumpLoadsUpdateStatusMutationVariables {
  input: DumpLoadsStatusUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadDeclarationUpdateMutation
// ====================================================

export interface DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate_consistence {
  __typename: "DeclarationConsistence";
  isLiquid: boolean | null;
  isViscous: boolean | null;
  isFixed: boolean | null;
  isPasty: boolean | null;
  isPowder: boolean | null;
  isSmall: boolean | null;
  isOther: boolean | null;
  other: string | null;
}

export interface DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate_risks {
  __typename: "DeclarationRisks";
  isToxic: boolean | null;
  isHazard: boolean | null;
  isHarmful: boolean | null;
  isCorrosive: boolean | null;
  isOxidising: boolean | null;
  isFlammable: boolean | null;
  isExplosive: boolean | null;
  isFarlig: boolean | null;
  isHygiene: boolean | null;
  isAcid: boolean | null;
  acid: string | null;
  isAlk: boolean | null;
  alk: string | null;
}

export interface DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
  producer: string | null;
  invoice: string | null;
  customerNr: string | null;
  adressFrom: string | null;
  adressTo: string | null;
  placeFrom: string | null;
  placeTo: string | null;
  postNrFrom: string | null;
  postNrTo: string | null;
  referenceFrom: string | null;
  referenceTo: string | null;
  telFrom: string | null;
  telTo: string | null;
  organizationNrFrom: string | null;
  organizationNrTo: string | null;
  ewcCode: string | null;
  process: string | null;
  reason: string | null;
  wasteType: string | null;
  pollutionType: string | null;
  subject1: string | null;
  subject2: string | null;
  subject3: string | null;
  subject4: string | null;
  subject5: string | null;
  subject6: string | null;
  value1: string | null;
  value2: string | null;
  value3: string | null;
  value4: string | null;
  value5: string | null;
  value6: string | null;
  unit1: string | null;
  unit2: string | null;
  unit3: string | null;
  unit4: string | null;
  unit5: string | null;
  unit6: string | null;
  isTunk: boolean | null;
  isContainer: boolean | null;
  isRails: boolean | null;
  totalAmountCa: string | null;
  deliveryAmountCa: string | null;
  totalTon: string | null;
  deliveryTon: string | null;
  packaging1: string | null;
  packaging2: string | null;
  packaging3: string | null;
  packaging4: string | null;
  quantity1: string | null;
  quantity2: string | null;
  quantity3: string | null;
  quantity4: string | null;
  transport: string | null;
  unNr: string | null;
  class: string | null;
  packaging: string | null;
  conveyor: string | null;
  organizationNr: string | null;
  otherInfo: string | null;
  infoAttached: boolean | null;
  otherCar: string | null;
  date: string | null;
  stability: string | null;
  consistence: DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate_consistence | null;
  risks: DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate_risks | null;
  hasMassEffluentRecord: boolean | null;
  hasContaminatedSoilGroundWater: boolean | null;
  hasDifferentSmellAndColor: boolean | null;
  hasOccuredByExcavationOfPath: boolean | null;
  hasPetroleumProductBeenOnSite: boolean | null;
  hasAnyAccidentsOccuredOnProperty: boolean | null;
  hasEnvironmentalTechnicalAssessment: boolean | null;
  hasOtherAssessment: boolean | null;
  wasteOriginate: string | null;
  wasteOriginateMunicipality: string | null;
}

export interface DumpLoadDeclarationUpdateMutation {
  projectLoadDeclarationUpdate: DumpLoadDeclarationUpdateMutation_projectLoadDeclarationUpdate | null;
}

export interface DumpLoadDeclarationUpdateMutationVariables {
  projectId: string;
  projectLoadId: string;
  input: ProjectLoadDeclarationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadSendForSignMutation
// ====================================================

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_comments_author | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_workTime[];
  location: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_landfill;
  subarea: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent_user | null;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_weeks[];
  months: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_months[];
  years: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods_years[];
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_months[];
  years: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_analysisFiles[];
  priceData: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_dumpType | null;
  destinationLandfill: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationLandfill | null;
  landfill: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_landfill | null;
  transportSchedule: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_declaration | null;
  comments: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_substances[];
  deliveryLines: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_deliveryLines[];
  timeSpent: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: DumpLoadSendForSignMutation_sendDumpLoadForSign_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface DumpLoadSendForSignMutation_sendDumpLoadForSign {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: DumpLoadSendForSignMutation_sendDumpLoadForSign_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: DumpLoadSendForSignMutation_sendDumpLoadForSign_location;
  customer: DumpLoadSendForSignMutation_sendDumpLoadForSign_customer | null;
  customerId: string;
  dumpLoads: DumpLoadSendForSignMutation_sendDumpLoadForSign_dumpLoads[];
  comment: string | null;
  ver: number;
  users: DumpLoadSendForSignMutation_sendDumpLoadForSign_users[] | null;
  userLinks: DumpLoadSendForSignMutation_sendDumpLoadForSign_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface DumpLoadSendForSignMutation {
  sendDumpLoadForSign: DumpLoadSendForSignMutation_sendDumpLoadForSign;
}

export interface DumpLoadSendForSignMutationVariables {
  projectId: string;
  dumpLoadId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadUploadAnalysisMutation
// ====================================================

export interface DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  analysisFiles: DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis_dumpLoads_analysisFiles[];
}

export interface DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis {
  __typename: "Project";
  id: string;
  ver: number;
  dumpLoads: DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis_dumpLoads[];
}

export interface DumpLoadUploadAnalysisMutation {
  projectLoadUploadAnalysis: DumpLoadUploadAnalysisMutation_projectLoadUploadAnalysis | null;
}

export interface DumpLoadUploadAnalysisMutationVariables {
  projectId: string;
  dumpLoadId: string;
  file: GQL_Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpLoadRemoveAnalysisMutation
// ====================================================

export interface DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  analysisFiles: DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis_dumpLoads_analysisFiles[];
}

export interface DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis {
  __typename: "Project";
  id: string;
  ver: number;
  dumpLoads: DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis_dumpLoads[];
}

export interface DumpLoadRemoveAnalysisMutation {
  projectLoadRemoveAnalysis: DumpLoadRemoveAnalysisMutation_projectLoadRemoveAnalysis | null;
}

export interface DumpLoadRemoveAnalysisMutationVariables {
  projectId: string;
  dumpLoadId: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminDumpLoadDestinationUpdateMutation
// ====================================================

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_userLinks[];
  location: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_location;
  customer: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_customer | null;
  customerId: string;
  owner: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_analysisFiles[];
  priceData: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_dumpType | null;
  landfill: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_landfill | null;
  transportSchedule: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  inbound: boolean;
  skipMatching: boolean;
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  project: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads_project | null;
}

export interface AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination {
  __typename: "Project";
  dumpLoads: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination_dumpLoads[];
}

export interface AdminDumpLoadDestinationUpdateMutation {
  projectLoadUpdateDestination: AdminDumpLoadDestinationUpdateMutation_projectLoadUpdateDestination;
}

export interface AdminDumpLoadDestinationUpdateMutationVariables {
  input: DestinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DumpLoadSearch
// ====================================================

export interface DumpLoadSearch_dumpLoadsPaged_edges_node {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
}

export interface DumpLoadSearch_dumpLoadsPaged_edges {
  __typename: "ProjectLoadEdge";
  cursor: string;
  node: DumpLoadSearch_dumpLoadsPaged_edges_node;
}

export interface DumpLoadSearch_dumpLoadsPaged {
  __typename: "ProjectLoadConnection";
  edges: DumpLoadSearch_dumpLoadsPaged_edges[];
  totalCount: number;
}

export interface DumpLoadSearch {
  dumpLoadsPaged: DumpLoadSearch_dumpLoadsPaged;
}

export interface DumpLoadSearchVariables {
  filter: DumpLoadsFilter;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminDespatchAdviceSettingsQuery
// ====================================================

export interface AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_consignee {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_despatchParty {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_buyer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_seller {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad {
  __typename: "DespatchAdviceSettings";
  id: string;
  category: DespatchAdviceCategory;
  consignee: AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_consignee;
  despatchParty: AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_despatchParty | null;
  buyer: AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_buyer;
  seller: AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad_seller;
  pricePerTon: number | null;
}

export interface AdminDespatchAdviceSettingsQuery {
  despatchAdviceSettingsForDumpLoad: AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad[];
}

export interface AdminDespatchAdviceSettingsQueryVariables {
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminCheckConnectedToPeppolQuery
// ====================================================

export interface AdminCheckConnectedToPeppolQuery {
  connectedToPeppol: boolean;
}

export interface AdminCheckConnectedToPeppolQueryVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyDespatchAdviceSettingsMutation
// ====================================================

export interface ApplyDespatchAdviceSettingsMutation_applyDespatchAdviceSettingsForDumpLoad {
  __typename: "DespatchAdviceSettings";
  id: string;
}

export interface ApplyDespatchAdviceSettingsMutation {
  applyDespatchAdviceSettingsForDumpLoad: ApplyDespatchAdviceSettingsMutation_applyDespatchAdviceSettingsForDumpLoad[];
}

export interface ApplyDespatchAdviceSettingsMutationVariables {
  orderNumber: string;
  settings: DespatchAdviceSettingsInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DumpTypeSelectQuery
// ====================================================

export interface DumpTypeSelectQuery_dumpTypes {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  description: string | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeSelectQuery {
  dumpTypes: DumpTypeSelectQuery_dumpTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpTypeRemoveMutation
// ====================================================

export interface DumpTypeRemoveMutation {
  dumpTypeRemove: boolean;
}

export interface DumpTypeRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpTypeUpdateMutation
// ====================================================

export interface DumpTypeUpdateMutation_dumpTypeUpdate {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeUpdateMutation {
  dumpTypeUpdate: DumpTypeUpdateMutation_dumpTypeUpdate | null;
}

export interface DumpTypeUpdateMutationVariables {
  input: DumpTypeUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpTypeUpdatePositionMutation
// ====================================================

export interface DumpTypeUpdatePositionMutation_dumpTypeUpdatePosition {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeUpdatePositionMutation {
  dumpTypeUpdatePosition: DumpTypeUpdatePositionMutation_dumpTypeUpdatePosition[] | null;
}

export interface DumpTypeUpdatePositionMutationVariables {
  items: DumpTypeUpdatePosition[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DumpTypeCreateMutation
// ====================================================

export interface DumpTypeCreateMutation_dumpTypeCreate {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeCreateMutation {
  dumpTypeCreate: DumpTypeCreateMutation_dumpTypeCreate | null;
}

export interface DumpTypeCreateMutationVariables {
  input: DumpTypeCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DumpTypeByIdQuery
// ====================================================

export interface DumpTypeByIdQuery_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeByIdQuery {
  dumpType: DumpTypeByIdQuery_dumpType | null;
}

export interface DumpTypeByIdQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DumpTypesListQuery
// ====================================================

export interface DumpTypesListQuery_dumpTypes {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypesListQuery {
  dumpTypes: DumpTypesListQuery_dumpTypes[];
}

export interface DumpTypesListQueryVariables {
  search?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillsPagedQuery
// ====================================================

export interface LandfillsPagedQuery_landfillsPaged_edges_node_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillsPagedQuery_landfillsPaged_edges_node_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillsPagedQuery_landfillsPaged_edges_node_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillsPagedQuery_landfillsPaged_edges_node_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillsPagedQuery_landfillsPaged_edges_node {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillsPagedQuery_landfillsPaged_edges_node_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: LandfillsPagedQuery_landfillsPaged_edges_node_receivers[] | null;
  workTime: LandfillsPagedQuery_landfillsPaged_edges_node_workTime[];
  comment: string | null;
  status: LandfillsPagedQuery_landfillsPaged_edges_node_status | null;
  isDeleted: boolean | null;
  capacity: number;
}

export interface LandfillsPagedQuery_landfillsPaged_edges {
  __typename: "LandfillEdge";
  cursor: string;
  node: LandfillsPagedQuery_landfillsPaged_edges_node;
}

export interface LandfillsPagedQuery_landfillsPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface LandfillsPagedQuery_landfillsPaged {
  __typename: "LandfillConnection";
  edges: LandfillsPagedQuery_landfillsPaged_edges[];
  pageInfo: LandfillsPagedQuery_landfillsPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface LandfillsPagedQuery {
  landfillsPaged: LandfillsPagedQuery_landfillsPaged;
}

export interface LandfillsPagedQueryVariables {
  filter?: LandfillFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillsSearchQuery
// ====================================================

export interface LandfillsSearchQuery_landfillsPaged_edges_node {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface LandfillsSearchQuery_landfillsPaged_edges {
  __typename: "LandfillEdge";
  node: LandfillsSearchQuery_landfillsPaged_edges_node;
}

export interface LandfillsSearchQuery_landfillsPaged {
  __typename: "LandfillConnection";
  edges: LandfillsSearchQuery_landfillsPaged_edges[];
}

export interface LandfillsSearchQuery {
  landfillsPaged: LandfillsSearchQuery_landfillsPaged;
}

export interface LandfillsSearchQueryVariables {
  filter: LandfillFilter;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillRemoveMutation
// ====================================================

export interface LandfillRemoveMutation {
  landfillRemove: boolean | null;
}

export interface LandfillRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillDetailedQuery
// ====================================================

export interface LandfillDetailedQuery_landfill_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillDetailedQuery_landfill_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillDetailedQuery_landfill_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillDetailedQuery_landfill_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillDetailedQuery_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillDetailedQuery_landfill_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDetailedQuery_landfill_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillDetailedQuery_landfill_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillDetailedQuery_landfill_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillDetailedQuery_landfill_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillDetailedQuery_landfill_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillDetailedQuery_landfill_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillDetailedQuery_landfill_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillDetailedQuery_landfill_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillDetailedQuery_landfill_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillDetailedQuery_landfill_ordersInfo_volumes_weeks[];
  months: LandfillDetailedQuery_landfill_ordersInfo_volumes_months[];
  years: LandfillDetailedQuery_landfill_ordersInfo_volumes_years[];
}

export interface LandfillDetailedQuery_landfill_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillDetailedQuery_landfill_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillDetailedQuery_landfill_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillDetailedQuery_landfill_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillDetailedQuery_landfill_ordersInfo_volumes;
  dumpLoad: LandfillDetailedQuery_landfill_ordersInfo_dumpLoad;
}

export interface LandfillDetailedQuery_landfill_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillDetailedQuery_landfill_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillDetailedQuery_landfill_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillDetailedQuery_landfill_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillDetailedQuery_landfill_deliveryLinesInfo_batch | null;
}

export interface LandfillDetailedQuery_landfill_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillDetailedQuery_landfill_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillDetailedQuery_landfill_batches_dumpType;
}

export interface LandfillDetailedQuery_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillDetailedQuery_landfill_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillDetailedQuery_landfill_receivers[] | null;
  customerId: string;
  customer: LandfillDetailedQuery_landfill_customer | null;
  workTime: LandfillDetailedQuery_landfill_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillDetailedQuery_landfill_status | null;
  certificates: LandfillDetailedQuery_landfill_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillDetailedQuery_landfill_subareas[];
  ordersInfo: LandfillDetailedQuery_landfill_ordersInfo[];
  deliveryLinesInfo: LandfillDetailedQuery_landfill_deliveryLinesInfo[] | null;
  batches: LandfillDetailedQuery_landfill_batches[];
}

export interface LandfillDetailedQuery {
  landfill: LandfillDetailedQuery_landfill | null;
}

export interface LandfillDetailedQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminLandfillsQuery
// ====================================================

export interface AdminLandfillsQuery_landfills_location {
  __typename: "Location";
  text: string;
}

export interface AdminLandfillsQuery_landfills_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface AdminLandfillsQuery_landfills {
  __typename: "Landfill";
  id: string;
  name: string;
  location: AdminLandfillsQuery_landfills_location;
  status: AdminLandfillsQuery_landfills_status | null;
  isDeleted: boolean | null;
}

export interface AdminLandfillsQuery {
  landfills: AdminLandfillsQuery_landfills[];
}

export interface AdminLandfillsQueryVariables {
  filter?: LandfillFilter | null;
  sort?: SortInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillQuery
// ====================================================

export interface LandfillQuery_landfill_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillQuery_landfill_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillQuery_landfill_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillQuery_landfill_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillQuery_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillQuery_landfill_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillQuery_landfill_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillQuery_landfill_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillQuery_landfill_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillQuery_landfill_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillQuery_landfill_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillQuery_landfill_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillQuery_landfill_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillQuery_landfill_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillQuery_landfill_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillQuery_landfill_ordersInfo_volumes_weeks[];
  months: LandfillQuery_landfill_ordersInfo_volumes_months[];
  years: LandfillQuery_landfill_ordersInfo_volumes_years[];
}

export interface LandfillQuery_landfill_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillQuery_landfill_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillQuery_landfill_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillQuery_landfill_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillQuery_landfill_ordersInfo_volumes;
  dumpLoad: LandfillQuery_landfill_ordersInfo_dumpLoad;
}

export interface LandfillQuery_landfill_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillQuery_landfill_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillQuery_landfill_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillQuery_landfill_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillQuery_landfill_deliveryLinesInfo_batch | null;
}

export interface LandfillQuery_landfill_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillQuery_landfill_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillQuery_landfill_batches_dumpType;
}

export interface LandfillQuery_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillQuery_landfill_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillQuery_landfill_receivers[] | null;
  customerId: string;
  customer: LandfillQuery_landfill_customer | null;
  workTime: LandfillQuery_landfill_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillQuery_landfill_status | null;
  certificates: LandfillQuery_landfill_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillQuery_landfill_subareas[];
  ordersInfo: LandfillQuery_landfill_ordersInfo[];
  deliveryLinesInfo: LandfillQuery_landfill_deliveryLinesInfo[] | null;
  batches: LandfillQuery_landfill_batches[];
}

export interface LandfillQuery {
  landfill: LandfillQuery_landfill | null;
}

export interface LandfillQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillCreateMutation
// ====================================================

export interface LandfillCreateMutation_landfillCreate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillCreateMutation_landfillCreate_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillCreateMutation_landfillCreate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillCreateMutation_landfillCreate_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillCreateMutation_landfillCreate_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillCreateMutation_landfillCreate_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillCreateMutation_landfillCreate_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillCreateMutation_landfillCreate_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillCreateMutation_landfillCreate_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillCreateMutation_landfillCreate_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillCreateMutation_landfillCreate_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillCreateMutation_landfillCreate_ordersInfo_volumes_weeks[];
  months: LandfillCreateMutation_landfillCreate_ordersInfo_volumes_months[];
  years: LandfillCreateMutation_landfillCreate_ordersInfo_volumes_years[];
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillCreateMutation_landfillCreate_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillCreateMutation_landfillCreate_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillCreateMutation_landfillCreate_ordersInfo_volumes;
  dumpLoad: LandfillCreateMutation_landfillCreate_ordersInfo_dumpLoad;
}

export interface LandfillCreateMutation_landfillCreate_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillCreateMutation_landfillCreate_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillCreateMutation_landfillCreate_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillCreateMutation_landfillCreate_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillCreateMutation_landfillCreate_deliveryLinesInfo_batch | null;
}

export interface LandfillCreateMutation_landfillCreate_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillCreateMutation_landfillCreate_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillCreateMutation_landfillCreate_batches_dumpType;
}

export interface LandfillCreateMutation_landfillCreate {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillCreateMutation_landfillCreate_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillCreateMutation_landfillCreate_receivers[] | null;
  customerId: string;
  customer: LandfillCreateMutation_landfillCreate_customer | null;
  workTime: LandfillCreateMutation_landfillCreate_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillCreateMutation_landfillCreate_status | null;
  certificates: LandfillCreateMutation_landfillCreate_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillCreateMutation_landfillCreate_subareas[];
  ordersInfo: LandfillCreateMutation_landfillCreate_ordersInfo[];
  deliveryLinesInfo: LandfillCreateMutation_landfillCreate_deliveryLinesInfo[] | null;
  batches: LandfillCreateMutation_landfillCreate_batches[];
}

export interface LandfillCreateMutation {
  landfillCreate: LandfillCreateMutation_landfillCreate | null;
}

export interface LandfillCreateMutationVariables {
  input: LandfillInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillUpdateMutation
// ====================================================

export interface LandfillUpdateMutation_landfillUpdate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillUpdateMutation_landfillUpdate_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillUpdateMutation_landfillUpdate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillUpdateMutation_landfillUpdate_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillUpdateMutation_landfillUpdate_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillUpdateMutation_landfillUpdate_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillUpdateMutation_landfillUpdate_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillUpdateMutation_landfillUpdate_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillUpdateMutation_landfillUpdate_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillUpdateMutation_landfillUpdate_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillUpdateMutation_landfillUpdate_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_weeks[];
  months: LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_months[];
  years: LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes_years[];
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillUpdateMutation_landfillUpdate_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillUpdateMutation_landfillUpdate_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillUpdateMutation_landfillUpdate_ordersInfo_volumes;
  dumpLoad: LandfillUpdateMutation_landfillUpdate_ordersInfo_dumpLoad;
}

export interface LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo_batch | null;
}

export interface LandfillUpdateMutation_landfillUpdate_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillUpdateMutation_landfillUpdate_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillUpdateMutation_landfillUpdate_batches_dumpType;
}

export interface LandfillUpdateMutation_landfillUpdate {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillUpdateMutation_landfillUpdate_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillUpdateMutation_landfillUpdate_receivers[] | null;
  customerId: string;
  customer: LandfillUpdateMutation_landfillUpdate_customer | null;
  workTime: LandfillUpdateMutation_landfillUpdate_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillUpdateMutation_landfillUpdate_status | null;
  certificates: LandfillUpdateMutation_landfillUpdate_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillUpdateMutation_landfillUpdate_subareas[];
  ordersInfo: LandfillUpdateMutation_landfillUpdate_ordersInfo[];
  deliveryLinesInfo: LandfillUpdateMutation_landfillUpdate_deliveryLinesInfo[] | null;
  batches: LandfillUpdateMutation_landfillUpdate_batches[];
}

export interface LandfillUpdateMutation {
  landfillUpdate: LandfillUpdateMutation_landfillUpdate | null;
}

export interface LandfillUpdateMutationVariables {
  input: LandfillInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillRemoveCertificateMutation
// ====================================================

export interface LandfillRemoveCertificateMutation {
  landfillRemoveCertificate: boolean | null;
}

export interface LandfillRemoveCertificateMutationVariables {
  landfillId: string;
  certId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillSubareaCreateMutation
// ====================================================

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillSubareaCreateMutation_landfillSubareaCreate_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillSubareaCreateMutation_landfillSubareaCreate_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_weeks[];
  months: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_months[];
  years: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes_years[];
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_volumes;
  dumpLoad: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo_dumpLoad;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo_batch | null;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillSubareaCreateMutation_landfillSubareaCreate_batches_dumpType;
}

export interface LandfillSubareaCreateMutation_landfillSubareaCreate {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillSubareaCreateMutation_landfillSubareaCreate_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillSubareaCreateMutation_landfillSubareaCreate_receivers[] | null;
  customerId: string;
  customer: LandfillSubareaCreateMutation_landfillSubareaCreate_customer | null;
  workTime: LandfillSubareaCreateMutation_landfillSubareaCreate_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillSubareaCreateMutation_landfillSubareaCreate_status | null;
  certificates: LandfillSubareaCreateMutation_landfillSubareaCreate_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillSubareaCreateMutation_landfillSubareaCreate_subareas[];
  ordersInfo: LandfillSubareaCreateMutation_landfillSubareaCreate_ordersInfo[];
  deliveryLinesInfo: LandfillSubareaCreateMutation_landfillSubareaCreate_deliveryLinesInfo[] | null;
  batches: LandfillSubareaCreateMutation_landfillSubareaCreate_batches[];
}

export interface LandfillSubareaCreateMutation {
  landfillSubareaCreate: LandfillSubareaCreateMutation_landfillSubareaCreate | null;
}

export interface LandfillSubareaCreateMutationVariables {
  landfillId: string;
  input: LandfillSubareaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillSubareaUpdateMutation
// ====================================================

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_weeks[];
  months: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_months[];
  years: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes_years[];
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_volumes;
  dumpLoad: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo_dumpLoad;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo_batch | null;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillSubareaUpdateMutation_landfillSubareaUpdate_batches_dumpType;
}

export interface LandfillSubareaUpdateMutation_landfillSubareaUpdate {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillSubareaUpdateMutation_landfillSubareaUpdate_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillSubareaUpdateMutation_landfillSubareaUpdate_receivers[] | null;
  customerId: string;
  customer: LandfillSubareaUpdateMutation_landfillSubareaUpdate_customer | null;
  workTime: LandfillSubareaUpdateMutation_landfillSubareaUpdate_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillSubareaUpdateMutation_landfillSubareaUpdate_status | null;
  certificates: LandfillSubareaUpdateMutation_landfillSubareaUpdate_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillSubareaUpdateMutation_landfillSubareaUpdate_subareas[];
  ordersInfo: LandfillSubareaUpdateMutation_landfillSubareaUpdate_ordersInfo[];
  deliveryLinesInfo: LandfillSubareaUpdateMutation_landfillSubareaUpdate_deliveryLinesInfo[] | null;
  batches: LandfillSubareaUpdateMutation_landfillSubareaUpdate_batches[];
}

export interface LandfillSubareaUpdateMutation {
  landfillSubareaUpdate: LandfillSubareaUpdateMutation_landfillSubareaUpdate | null;
}

export interface LandfillSubareaUpdateMutationVariables {
  landfillId: string;
  input: LandfillSubareaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillSubareaDeleteMutation
// ====================================================

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_weeks[];
  months: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_months[];
  years: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes_years[];
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_volumes;
  dumpLoad: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo_dumpLoad;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo_batch | null;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillSubareaDeleteMutation_landfillSubareaDelete_batches_dumpType;
}

export interface LandfillSubareaDeleteMutation_landfillSubareaDelete {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillSubareaDeleteMutation_landfillSubareaDelete_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillSubareaDeleteMutation_landfillSubareaDelete_receivers[] | null;
  customerId: string;
  customer: LandfillSubareaDeleteMutation_landfillSubareaDelete_customer | null;
  workTime: LandfillSubareaDeleteMutation_landfillSubareaDelete_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillSubareaDeleteMutation_landfillSubareaDelete_status | null;
  certificates: LandfillSubareaDeleteMutation_landfillSubareaDelete_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillSubareaDeleteMutation_landfillSubareaDelete_subareas[];
  ordersInfo: LandfillSubareaDeleteMutation_landfillSubareaDelete_ordersInfo[];
  deliveryLinesInfo: LandfillSubareaDeleteMutation_landfillSubareaDelete_deliveryLinesInfo[] | null;
  batches: LandfillSubareaDeleteMutation_landfillSubareaDelete_batches[];
}

export interface LandfillSubareaDeleteMutation {
  landfillSubareaDelete: LandfillSubareaDeleteMutation_landfillSubareaDelete | null;
}

export interface LandfillSubareaDeleteMutationVariables {
  landfillId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillBatchCreateMutation
// ====================================================

export interface LandfillBatchCreateMutation_landfillBatchCreate_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillBatchCreateMutation_landfillBatchCreate {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillBatchCreateMutation_landfillBatchCreate_dumpType;
}

export interface LandfillBatchCreateMutation {
  landfillBatchCreate: LandfillBatchCreateMutation_landfillBatchCreate | null;
}

export interface LandfillBatchCreateMutationVariables {
  landfillId: string;
  input: LandfillBatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillBatchUpdateMutation
// ====================================================

export interface LandfillBatchUpdateMutation_landfillBatchUpdate_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillBatchUpdateMutation_landfillBatchUpdate {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillBatchUpdateMutation_landfillBatchUpdate_dumpType;
}

export interface LandfillBatchUpdateMutation {
  landfillBatchUpdate: LandfillBatchUpdateMutation_landfillBatchUpdate | null;
}

export interface LandfillBatchUpdateMutationVariables {
  landfillId: string;
  id: string;
  input: LandfillBatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillBatchArchiveMutation
// ====================================================

export interface LandfillBatchArchiveMutation_landfillBatchArchive_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillBatchArchiveMutation_landfillBatchArchive {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillBatchArchiveMutation_landfillBatchArchive_dumpType;
}

export interface LandfillBatchArchiveMutation {
  landfillBatchArchive: LandfillBatchArchiveMutation_landfillBatchArchive | null;
}

export interface LandfillBatchArchiveMutationVariables {
  landfillId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillBatchRestoreMutation
// ====================================================

export interface LandfillBatchRestoreMutation_landfillBatchRestore_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillBatchRestoreMutation_landfillBatchRestore {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillBatchRestoreMutation_landfillBatchRestore_dumpType;
}

export interface LandfillBatchRestoreMutation {
  landfillBatchRestore: LandfillBatchRestoreMutation_landfillBatchRestore | null;
}

export interface LandfillBatchRestoreMutationVariables {
  landfillId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillStatusesListQuery
// ====================================================

export interface LandfillStatusesListQuery_landfillStatuses {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface LandfillStatusesListQuery {
  landfillStatuses: LandfillStatusesListQuery_landfillStatuses[];
}

export interface LandfillStatusesListQueryVariables {
  search?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillStatusQuery
// ====================================================

export interface LandfillStatusQuery_landfillStatus {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface LandfillStatusQuery {
  landfillStatus: LandfillStatusQuery_landfillStatus | null;
}

export interface LandfillStatusQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillStatusCreateMutation
// ====================================================

export interface LandfillStatusCreateMutation_landfillStatusCreate {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface LandfillStatusCreateMutation {
  landfillStatusCreate: LandfillStatusCreateMutation_landfillStatusCreate | null;
}

export interface LandfillStatusCreateMutationVariables {
  input: LandfillStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillStatusUpdateMutation
// ====================================================

export interface LandfillStatusUpdateMutation_landfillStatusUpdate {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface LandfillStatusUpdateMutation {
  landfillStatusUpdate: LandfillStatusUpdateMutation_landfillStatusUpdate | null;
}

export interface LandfillStatusUpdateMutationVariables {
  input: LandfillStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillStatusRemoveMutation
// ====================================================

export interface LandfillStatusRemoveMutation {
  landfillStatusRemove: boolean | null;
}

export interface LandfillStatusRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocalizationStringsQuery
// ====================================================

export interface LocalizationStringsQuery_localizationStrings {
  __typename: "LocalizationString";
  code: string;
  value: string;
}

export interface LocalizationStringsQuery {
  localizationStrings: LocalizationStringsQuery_localizationStrings[];
}

export interface LocalizationStringsQueryVariables {
  language: Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocalizationStringUpdateMutation
// ====================================================

export interface LocalizationStringUpdateMutation_updateLocalizationString {
  __typename: "LocalizationString";
  code: string;
  value: string;
}

export interface LocalizationStringUpdateMutation {
  updateLocalizationString: LocalizationStringUpdateMutation_updateLocalizationString[];
}

export interface LocalizationStringUpdateMutationVariables {
  input: LocalizationStringInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Geocode
// ====================================================

export interface Geocode_geocode {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface Geocode {
  geocode: Geocode_geocode;
}

export interface GeocodeVariables {
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReverseGeocode
// ====================================================

export interface ReverseGeocode_reverseGeocode {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ReverseGeocode {
  reverseGeocode: ReverseGeocode_reverseGeocode;
}

export interface ReverseGeocodeVariables {
  location: LocationReverseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LockMutation
// ====================================================

export interface LockMutation_lock_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface LockMutation_lock {
  __typename: "Lock";
  id: string;
  entityType: LockEntityType;
  entityId: string;
  expire: GQL_Date;
  userId: string;
  user: LockMutation_lock_user | null;
}

export interface LockMutation {
  lock: LockMutation_lock;
}

export interface LockMutationVariables {
  input: LockInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveLockMutation
// ====================================================

export interface RemoveLockMutation {
  removeLock: boolean;
}

export interface RemoveLockMutationVariables {
  input: LockInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAllLocksMutation
// ====================================================

export interface RemoveAllLocksMutation {
  removeAllLocks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminProjectsPagedShortQuery
// ====================================================

export interface AdminProjectsPagedShortQuery_projectsPaged_edges_node_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminProjectsPagedShortQuery_projectsPaged_edges_node_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminProjectsPagedShortQuery_projectsPaged_edges_node_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  role: UserRole;
  customerId: string | null;
}

export interface AdminProjectsPagedShortQuery_projectsPaged_edges_node {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  location: AdminProjectsPagedShortQuery_projectsPaged_edges_node_location;
  comment: string | null;
  customerId: string;
  customer: AdminProjectsPagedShortQuery_projectsPaged_edges_node_customer | null;
  owner: AdminProjectsPagedShortQuery_projectsPaged_edges_node_owner | null;
}

export interface AdminProjectsPagedShortQuery_projectsPaged_edges {
  __typename: "ProjectEdge";
  cursor: string;
  node: AdminProjectsPagedShortQuery_projectsPaged_edges_node;
}

export interface AdminProjectsPagedShortQuery_projectsPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminProjectsPagedShortQuery_projectsPaged {
  __typename: "ProjectConnection";
  edges: AdminProjectsPagedShortQuery_projectsPaged_edges[];
  pageInfo: AdminProjectsPagedShortQuery_projectsPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminProjectsPagedShortQuery {
  projectsPaged: AdminProjectsPagedShortQuery_projectsPaged;
}

export interface AdminProjectsPagedShortQueryVariables {
  filter?: ProjectFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminProjectQuery
// ====================================================

export interface AdminProjectQuery_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface AdminProjectQuery_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminProjectQuery_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface AdminProjectQuery_project_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface AdminProjectQuery_project_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface AdminProjectQuery_project_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface AdminProjectQuery_project_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface AdminProjectQuery_project_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface AdminProjectQuery_project_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: AdminProjectQuery_project_dumpLoads_comments_author | null;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_workTime[];
  location: AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: AdminProjectQuery_project_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface AdminProjectQuery_project_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: AdminProjectQuery_project_dumpLoads_destinationInfo_landfill;
  subarea: AdminProjectQuery_project_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface AdminProjectQuery_project_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface AdminProjectQuery_project_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: AdminProjectQuery_project_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface AdminProjectQuery_project_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: AdminProjectQuery_project_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: AdminProjectQuery_project_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: AdminProjectQuery_project_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: AdminProjectQuery_project_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminProjectQuery_project_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface AdminProjectQuery_project_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: AdminProjectQuery_project_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface AdminProjectQuery_project_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminProjectQuery_project_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: AdminProjectQuery_project_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: AdminProjectQuery_project_dumpLoads_timeSpent_user | null;
}

export interface AdminProjectQuery_project_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminProjectQuery_project_dumpLoads_volumesPerPeriods_weeks[];
  months: AdminProjectQuery_project_dumpLoads_volumesPerPeriods_months[];
  years: AdminProjectQuery_project_dumpLoads_volumesPerPeriods_years[];
}

export interface AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_months[];
  years: AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface AdminProjectQuery_project_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface AdminProjectQuery_project_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: AdminProjectQuery_project_dumpLoads_analysisFiles[];
  priceData: AdminProjectQuery_project_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: AdminProjectQuery_project_dumpLoads_dumpType | null;
  destinationLandfill: AdminProjectQuery_project_dumpLoads_destinationLandfill | null;
  landfill: AdminProjectQuery_project_dumpLoads_landfill | null;
  transportSchedule: AdminProjectQuery_project_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: AdminProjectQuery_project_dumpLoads_declaration | null;
  comments: AdminProjectQuery_project_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: AdminProjectQuery_project_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: AdminProjectQuery_project_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: AdminProjectQuery_project_dumpLoads_substances[];
  deliveryLines: AdminProjectQuery_project_dumpLoads_deliveryLines[];
  timeSpent: AdminProjectQuery_project_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: AdminProjectQuery_project_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: AdminProjectQuery_project_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: AdminProjectQuery_project_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface AdminProjectQuery_project_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface AdminProjectQuery_project_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: AdminProjectQuery_project_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface AdminProjectQuery_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminProjectQuery_project {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: AdminProjectQuery_project_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: AdminProjectQuery_project_location;
  customer: AdminProjectQuery_project_customer | null;
  customerId: string;
  dumpLoads: AdminProjectQuery_project_dumpLoads[];
  comment: string | null;
  ver: number;
  users: AdminProjectQuery_project_users[] | null;
  userLinks: AdminProjectQuery_project_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface AdminProjectQuery {
  project: AdminProjectQuery_project | null;
}

export interface AdminProjectQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminProjectSearchQuery
// ====================================================

export interface AdminProjectSearchQuery_projectsPaged_edges_node {
  __typename: "Project";
  id: string;
  name: string;
  ver: number;
}

export interface AdminProjectSearchQuery_projectsPaged_edges {
  __typename: "ProjectEdge";
  node: AdminProjectSearchQuery_projectsPaged_edges_node;
}

export interface AdminProjectSearchQuery_projectsPaged {
  __typename: "ProjectConnection";
  edges: AdminProjectSearchQuery_projectsPaged_edges[];
}

export interface AdminProjectSearchQuery {
  projectsPaged: AdminProjectSearchQuery_projectsPaged;
}

export interface AdminProjectSearchQueryVariables {
  filter?: ProjectFilter | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectQuery
// ====================================================

export interface ProjectQuery_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectQuery_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectQuery_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectQuery_project_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectQuery_project_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProjectQuery_project_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface ProjectQuery_project_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface ProjectQuery_project_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface ProjectQuery_project_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface ProjectQuery_project_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectQuery_project_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectQuery_project_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectQuery_project_dumpLoads_comments_author | null;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: ProjectQuery_project_dumpLoads_destinationInfo_landfill_workTime[];
  location: ProjectQuery_project_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: ProjectQuery_project_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface ProjectQuery_project_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: ProjectQuery_project_dumpLoads_destinationInfo_landfill;
  subarea: ProjectQuery_project_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface ProjectQuery_project_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface ProjectQuery_project_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface ProjectQuery_project_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectQuery_project_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectQuery_project_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectQuery_project_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectQuery_project_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: ProjectQuery_project_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface ProjectQuery_project_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: ProjectQuery_project_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: ProjectQuery_project_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: ProjectQuery_project_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: ProjectQuery_project_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface ProjectQuery_project_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectQuery_project_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: ProjectQuery_project_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface ProjectQuery_project_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectQuery_project_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: ProjectQuery_project_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: ProjectQuery_project_dumpLoads_timeSpent_user | null;
}

export interface ProjectQuery_project_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectQuery_project_dumpLoads_volumesPerPeriods_weeks[];
  months: ProjectQuery_project_dumpLoads_volumesPerPeriods_months[];
  years: ProjectQuery_project_dumpLoads_volumesPerPeriods_years[];
}

export interface ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectQuery_project_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_months[];
  years: ProjectQuery_project_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface ProjectQuery_project_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface ProjectQuery_project_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: ProjectQuery_project_dumpLoads_analysisFiles[];
  priceData: ProjectQuery_project_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: ProjectQuery_project_dumpLoads_dumpType | null;
  destinationLandfill: ProjectQuery_project_dumpLoads_destinationLandfill | null;
  landfill: ProjectQuery_project_dumpLoads_landfill | null;
  transportSchedule: ProjectQuery_project_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: ProjectQuery_project_dumpLoads_declaration | null;
  comments: ProjectQuery_project_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: ProjectQuery_project_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: ProjectQuery_project_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: ProjectQuery_project_dumpLoads_substances[];
  deliveryLines: ProjectQuery_project_dumpLoads_deliveryLines[];
  timeSpent: ProjectQuery_project_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: ProjectQuery_project_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: ProjectQuery_project_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: ProjectQuery_project_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface ProjectQuery_project_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectQuery_project_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: ProjectQuery_project_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface ProjectQuery_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectQuery_project {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: ProjectQuery_project_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: ProjectQuery_project_location;
  customer: ProjectQuery_project_customer | null;
  customerId: string;
  dumpLoads: ProjectQuery_project_dumpLoads[];
  comment: string | null;
  ver: number;
  users: ProjectQuery_project_users[] | null;
  userLinks: ProjectQuery_project_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface ProjectQuery {
  project: ProjectQuery_project | null;
}

export interface ProjectQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsOffersListQuery
// ====================================================

export interface ProjectsOffersListQuery_projectsOffers_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectsOffersListQuery_projectsOffers_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface ProjectsOffersListQuery_projectsOffers_signDetails {
  __typename: "SignDetails";
  canSendForSign: boolean;
}

export interface ProjectsOffersListQuery_projectsOffers_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectsOffersListQuery_projectsOffers_project {
  __typename: "Project";
  id: string;
  name: string;
  userLinks: ProjectsOffersListQuery_projectsOffers_project_userLinks[];
  customerId: string;
  status: ProjectStatus;
  ver: number;
}

export interface ProjectsOffersListQuery_projectsOffers {
  __typename: "ProjectLoad";
  id: string;
  status: LoadStatus | null;
  declaration: ProjectsOffersListQuery_projectsOffers_declaration | null;
  date: GQL_Date | null;
  dumpType: ProjectsOffersListQuery_projectsOffers_dumpType | null;
  signDetails: ProjectsOffersListQuery_projectsOffers_signDetails | null;
  project: ProjectsOffersListQuery_projectsOffers_project | null;
  solidTestResult: ContaminationLevel | null;
}

export interface ProjectsOffersListQuery {
  projectsOffers: ProjectsOffersListQuery_projectsOffers[] | null;
}

export interface ProjectsOffersListQueryVariables {
  projectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectOffersCountQuery
// ====================================================

export interface ProjectOffersCountQuery_projectOffersCount {
  __typename: "ProjectOffersCount";
  count: number;
}

export interface ProjectOffersCountQuery {
  projectOffersCount: ProjectOffersCountQuery_projectOffersCount | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SendReportQuery
// ====================================================

export interface SendReportQuery {
  sendProjectReport: boolean | null;
}

export interface SendReportQueryVariables {
  email: GQL_Email;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectRemoveMutation
// ====================================================

export interface ProjectRemoveMutation {
  projectRemove: boolean | null;
}

export interface ProjectRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectRecoverMutation
// ====================================================

export interface ProjectRecoverMutation {
  projectRecover: boolean | null;
}

export interface ProjectRecoverMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectCreateMutation
// ====================================================

export interface ProjectCreateMutation_projectCreate_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectCreateMutation_projectCreate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectCreateMutation_projectCreate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectCreateMutation_projectCreate_dumpLoads_comments_author | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_workTime[];
  location: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_landfill;
  subarea: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: ProjectCreateMutation_projectCreate_dumpLoads_timeSpent_user | null;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_weeks[];
  months: ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_months[];
  years: ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods_years[];
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_months[];
  years: ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface ProjectCreateMutation_projectCreate_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface ProjectCreateMutation_projectCreate_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: ProjectCreateMutation_projectCreate_dumpLoads_analysisFiles[];
  priceData: ProjectCreateMutation_projectCreate_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: ProjectCreateMutation_projectCreate_dumpLoads_dumpType | null;
  destinationLandfill: ProjectCreateMutation_projectCreate_dumpLoads_destinationLandfill | null;
  landfill: ProjectCreateMutation_projectCreate_dumpLoads_landfill | null;
  transportSchedule: ProjectCreateMutation_projectCreate_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: ProjectCreateMutation_projectCreate_dumpLoads_declaration | null;
  comments: ProjectCreateMutation_projectCreate_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: ProjectCreateMutation_projectCreate_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: ProjectCreateMutation_projectCreate_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: ProjectCreateMutation_projectCreate_dumpLoads_substances[];
  deliveryLines: ProjectCreateMutation_projectCreate_dumpLoads_deliveryLines[];
  timeSpent: ProjectCreateMutation_projectCreate_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: ProjectCreateMutation_projectCreate_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: ProjectCreateMutation_projectCreate_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: ProjectCreateMutation_projectCreate_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface ProjectCreateMutation_projectCreate_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectCreateMutation_projectCreate_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: ProjectCreateMutation_projectCreate_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface ProjectCreateMutation_projectCreate_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectCreateMutation_projectCreate {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: ProjectCreateMutation_projectCreate_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: ProjectCreateMutation_projectCreate_location;
  customer: ProjectCreateMutation_projectCreate_customer | null;
  customerId: string;
  dumpLoads: ProjectCreateMutation_projectCreate_dumpLoads[];
  comment: string | null;
  ver: number;
  users: ProjectCreateMutation_projectCreate_users[] | null;
  userLinks: ProjectCreateMutation_projectCreate_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface ProjectCreateMutation {
  projectCreate: ProjectCreateMutation_projectCreate | null;
}

export interface ProjectCreateMutationVariables {
  input: ProjectCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectUpdateMutation
// ====================================================

export interface ProjectUpdateMutation_projectUpdate_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectUpdateMutation_projectUpdate_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectUpdateMutation_projectUpdate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectUpdateMutation_projectUpdate_dumpLoads_comments_author | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_workTime[];
  location: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_landfill;
  subarea: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent_user | null;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_weeks[];
  months: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_months[];
  years: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods_years[];
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_months[];
  years: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: ProjectUpdateMutation_projectUpdate_dumpLoads_analysisFiles[];
  priceData: ProjectUpdateMutation_projectUpdate_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: ProjectUpdateMutation_projectUpdate_dumpLoads_dumpType | null;
  destinationLandfill: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationLandfill | null;
  landfill: ProjectUpdateMutation_projectUpdate_dumpLoads_landfill | null;
  transportSchedule: ProjectUpdateMutation_projectUpdate_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: ProjectUpdateMutation_projectUpdate_dumpLoads_declaration | null;
  comments: ProjectUpdateMutation_projectUpdate_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: ProjectUpdateMutation_projectUpdate_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: ProjectUpdateMutation_projectUpdate_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: ProjectUpdateMutation_projectUpdate_dumpLoads_substances[];
  deliveryLines: ProjectUpdateMutation_projectUpdate_dumpLoads_deliveryLines[];
  timeSpent: ProjectUpdateMutation_projectUpdate_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: ProjectUpdateMutation_projectUpdate_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: ProjectUpdateMutation_projectUpdate_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface ProjectUpdateMutation_projectUpdate_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectUpdateMutation_projectUpdate_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: ProjectUpdateMutation_projectUpdate_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface ProjectUpdateMutation_projectUpdate_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectUpdateMutation_projectUpdate {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: ProjectUpdateMutation_projectUpdate_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: ProjectUpdateMutation_projectUpdate_location;
  customer: ProjectUpdateMutation_projectUpdate_customer | null;
  customerId: string;
  dumpLoads: ProjectUpdateMutation_projectUpdate_dumpLoads[];
  comment: string | null;
  ver: number;
  users: ProjectUpdateMutation_projectUpdate_users[] | null;
  userLinks: ProjectUpdateMutation_projectUpdate_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface ProjectUpdateMutation {
  projectUpdate: ProjectUpdateMutation_projectUpdate | null;
}

export interface ProjectUpdateMutationVariables {
  input: ProjectUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectAddCertificateMutation
// ====================================================

export interface ProjectAddCertificateMutation {
  projectAddCertificate: boolean | null;
}

export interface ProjectAddCertificateMutationVariables {
  id: string;
  certificate: GQL_Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectRemoveCertificateMutation
// ====================================================

export interface ProjectRemoveCertificateMutation {
  projectRemoveCertificate: boolean | null;
}

export interface ProjectRemoveCertificateMutationVariables {
  id: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectTransportScheduleMutation
// ====================================================

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_comments_author | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_workTime[];
  location: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_landfill;
  subarea: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent_user | null;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_weeks[];
  months: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_months[];
  years: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods_years[];
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_months[];
  years: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_analysisFiles[];
  priceData: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_dumpType | null;
  destinationLandfill: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationLandfill | null;
  landfill: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_landfill | null;
  transportSchedule: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_declaration | null;
  comments: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_substances[];
  deliveryLines: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_deliveryLines[];
  timeSpent: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectTransportScheduleMutation_projectUpdateTransportSchedule {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_location;
  customer: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_customer | null;
  customerId: string;
  dumpLoads: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_dumpLoads[];
  comment: string | null;
  ver: number;
  users: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_users[] | null;
  userLinks: ProjectTransportScheduleMutation_projectUpdateTransportSchedule_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

export interface ProjectTransportScheduleMutation {
  projectUpdateTransportSchedule: ProjectTransportScheduleMutation_projectUpdateTransportSchedule | null;
}

export interface ProjectTransportScheduleMutationVariables {
  input: ProjectTransportScheduleUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectAddOrInviteUser
// ====================================================

export interface ProjectAddOrInviteUser_projectAddOrInviteUser_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectAddOrInviteUser_projectAddOrInviteUser_users {
  __typename: "UserRecord";
  id: string;
}

export interface ProjectAddOrInviteUser_projectAddOrInviteUser {
  __typename: "Project";
  id: string;
  userLinks: ProjectAddOrInviteUser_projectAddOrInviteUser_userLinks[];
  users: ProjectAddOrInviteUser_projectAddOrInviteUser_users[] | null;
  ver: number;
}

export interface ProjectAddOrInviteUser {
  projectAddOrInviteUser: ProjectAddOrInviteUser_projectAddOrInviteUser;
}

export interface ProjectAddOrInviteUserVariables {
  projectId: string;
  email: GQL_Email;
  role: UserContextRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectAddUser
// ====================================================

export interface ProjectAddUser_projectAddUser_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectAddUser_projectAddUser_users {
  __typename: "UserRecord";
  id: string;
}

export interface ProjectAddUser_projectAddUser {
  __typename: "Project";
  id: string;
  userLinks: ProjectAddUser_projectAddUser_userLinks[];
  users: ProjectAddUser_projectAddUser_users[] | null;
  ver: number;
}

export interface ProjectAddUser {
  projectAddUser: ProjectAddUser_projectAddUser;
}

export interface ProjectAddUserVariables {
  projectId: string;
  userId: string;
  role: UserContextRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectRemoveUser
// ====================================================

export interface ProjectRemoveUser_projectRemoveUser_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectRemoveUser_projectRemoveUser_users {
  __typename: "UserRecord";
  id: string;
}

export interface ProjectRemoveUser_projectRemoveUser {
  __typename: "Project";
  id: string;
  userLinks: ProjectRemoveUser_projectRemoveUser_userLinks[];
  users: ProjectRemoveUser_projectRemoveUser_users[] | null;
  ver: number;
}

export interface ProjectRemoveUser {
  projectRemoveUser: ProjectRemoveUser_projectRemoveUser;
}

export interface ProjectRemoveUserVariables {
  projectId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectEnableWhatsapp
// ====================================================

export interface ProjectEnableWhatsapp_projectEnableWhatsapp {
  __typename: "Project";
  id: string;
  ver: number;
  whatsappGroupLink: string | null;
}

export interface ProjectEnableWhatsapp {
  projectEnableWhatsapp: ProjectEnableWhatsapp_projectEnableWhatsapp;
}

export interface ProjectEnableWhatsappVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReleaseNotesByTypeQuery
// ====================================================

export interface ReleaseNotesByTypeQuery_releaseNotes {
  __typename: "ReleaseNote";
  id: string;
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

export interface ReleaseNotesByTypeQuery {
  releaseNotes: ReleaseNotesByTypeQuery_releaseNotes[];
}

export interface ReleaseNotesByTypeQueryVariables {
  type: ReleaseNoteType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAllReleaseNotesQuery
// ====================================================

export interface AdminAllReleaseNotesQuery_releaseNotes {
  __typename: "ReleaseNote";
  id: string;
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

export interface AdminAllReleaseNotesQuery {
  releaseNotes: AdminAllReleaseNotesQuery_releaseNotes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReleaseNoteMutation
// ====================================================

export interface CreateReleaseNoteMutation_createReleaseNote {
  __typename: "ReleaseNote";
  id: string;
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

export interface CreateReleaseNoteMutation {
  createReleaseNote: CreateReleaseNoteMutation_createReleaseNote;
}

export interface CreateReleaseNoteMutationVariables {
  input: ReleaseNoteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReleaseNoteMutation
// ====================================================

export interface UpdateReleaseNoteMutation_updateReleaseNote {
  __typename: "ReleaseNote";
  id: string;
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

export interface UpdateReleaseNoteMutation {
  updateReleaseNote: UpdateReleaseNoteMutation_updateReleaseNote;
}

export interface UpdateReleaseNoteMutationVariables {
  id: string;
  input: ReleaseNoteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReleaseNoteMutation
// ====================================================

export interface DeleteReleaseNoteMutation {
  deleteReleaseNote: boolean | null;
}

export interface DeleteReleaseNoteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SettingsQuery
// ====================================================

export interface SettingsQuery_settings_featuresFlags {
  __typename: "FeaturesFlags";
  captchaEnabled: boolean;
  chatGptEnabled: boolean;
  peppolEnabled: boolean;
  whatsappEnabled: boolean;
}

export interface SettingsQuery_settings_transportConfiguration {
  __typename: "TransportConfiguration";
  tonnsPerTruck: number;
  pricePerHour: number;
  pricePerKm: number;
  tax: number;
  fixedPrice: number;
  co2perKm: number;
}

export interface SettingsQuery_settings_companyConfiguration {
  __typename: "CompanyConfiguration";
  orgName: string;
  orgNumber: string;
  place: string;
  postNumber: string;
  address: string;
  tel: string;
  email: GQL_Email;
}

export interface SettingsQuery_settings_keys {
  __typename: "SettingsKeys";
  captchaSiteKey: string;
  captchaSecretKey: string;
  chatGptApiKey: string;
  chatGptAssistantId: string;
  tickstarClientId: string;
  tickstarClientSecret: string;
  tickstarToken: string;
  pinpointerGLN: string;
  testDespatchPartyOrgNumber: string;
  testConsigneeOrgNumber: string;
  mytapiProductId: string;
  mytapiToken: string;
}

export interface SettingsQuery_settings {
  __typename: "Settings";
  featuresFlags: SettingsQuery_settings_featuresFlags;
  transportConfiguration: SettingsQuery_settings_transportConfiguration;
  companyConfiguration: SettingsQuery_settings_companyConfiguration;
  keys: SettingsQuery_settings_keys;
}

export interface SettingsQuery {
  settings: SettingsQuery_settings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SettingsMutate
// ====================================================

export interface SettingsMutate_updateSettings_featuresFlags {
  __typename: "FeaturesFlags";
  captchaEnabled: boolean;
  chatGptEnabled: boolean;
  peppolEnabled: boolean;
  whatsappEnabled: boolean;
}

export interface SettingsMutate_updateSettings_transportConfiguration {
  __typename: "TransportConfiguration";
  tonnsPerTruck: number;
  pricePerHour: number;
  pricePerKm: number;
  tax: number;
  fixedPrice: number;
  co2perKm: number;
}

export interface SettingsMutate_updateSettings_companyConfiguration {
  __typename: "CompanyConfiguration";
  orgName: string;
  orgNumber: string;
  place: string;
  postNumber: string;
  address: string;
  tel: string;
  email: GQL_Email;
}

export interface SettingsMutate_updateSettings_keys {
  __typename: "SettingsKeys";
  captchaSiteKey: string;
  captchaSecretKey: string;
  chatGptApiKey: string;
  chatGptAssistantId: string;
  tickstarClientId: string;
  tickstarClientSecret: string;
  tickstarToken: string;
  pinpointerGLN: string;
  testDespatchPartyOrgNumber: string;
  testConsigneeOrgNumber: string;
  mytapiProductId: string;
  mytapiToken: string;
}

export interface SettingsMutate_updateSettings {
  __typename: "Settings";
  featuresFlags: SettingsMutate_updateSettings_featuresFlags;
  transportConfiguration: SettingsMutate_updateSettings_transportConfiguration;
  companyConfiguration: SettingsMutate_updateSettings_companyConfiguration;
  keys: SettingsMutate_updateSettings_keys;
}

export interface SettingsMutate {
  updateSettings: SettingsMutate_updateSettings;
}

export interface SettingsMutateVariables {
  input: SettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomScriptsQuery
// ====================================================

export interface CustomScriptsQuery_allCustomScripts {
  __typename: "CustomScript";
  id: string;
  text: string;
  src: string;
  async: boolean;
  order: number;
  active: boolean;
}

export interface CustomScriptsQuery {
  allCustomScripts: CustomScriptsQuery_allCustomScripts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CustomScriptAddMutation
// ====================================================

export interface CustomScriptAddMutation_addCustomScript {
  __typename: "CustomScript";
  id: string;
  text: string;
  src: string;
  async: boolean;
  order: number;
  active: boolean;
}

export interface CustomScriptAddMutation {
  addCustomScript: CustomScriptAddMutation_addCustomScript;
}

export interface CustomScriptAddMutationVariables {
  input: CustomScriptInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CustomScriptUpdateMutation
// ====================================================

export interface CustomScriptUpdateMutation_updateCustomScript {
  __typename: "CustomScript";
  id: string;
  text: string;
  src: string;
  async: boolean;
  order: number;
  active: boolean;
}

export interface CustomScriptUpdateMutation {
  updateCustomScript: CustomScriptUpdateMutation_updateCustomScript;
}

export interface CustomScriptUpdateMutationVariables {
  id: string;
  input: CustomScriptInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CustomScriptRemoveMutation
// ====================================================

export interface CustomScriptRemoveMutation {
  removeCustomScript: boolean;
}

export interface CustomScriptRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillSubareasPagedQuery
// ====================================================

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_receivers[] | null;
  workTime: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_workTime[];
  comment: string | null;
  status: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill_status | null;
  isDeleted: boolean | null;
  capacity: number;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  landfill: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill;
  dumpType: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_dumpType | null;
  unitOfMeasure: UnitOfMeasure | null;
  comment: string | null;
  maxAmount: number;
  currentAmount: number;
  reservedAmount: number;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  priceUpdatedAt: GQL_Date;
  stackable: boolean;
  over50cm: boolean;
  solidTestResult: ContaminationLevel | null;
  isProvider: boolean;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_edges {
  __typename: "LandfillSubareaPage";
  cursor: string;
  node: LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface LandfillSubareasPagedQuery_landfillSubareasPaged {
  __typename: "LandfillSubareaConnection";
  edges: LandfillSubareasPagedQuery_landfillSubareasPaged_edges[];
  pageInfo: LandfillSubareasPagedQuery_landfillSubareasPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface LandfillSubareasPagedQuery {
  landfillSubareasPaged: LandfillSubareasPagedQuery_landfillSubareasPaged;
}

export interface LandfillSubareasPagedQueryVariables {
  filter?: SubareaFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubstanceRemoveMutation
// ====================================================

export interface SubstanceRemoveMutation {
  substanceRemove: boolean | null;
}

export interface SubstanceRemoveMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubstanceUpdateMutation
// ====================================================

export interface SubstanceUpdateMutation_substanceUpdate {
  __typename: "SubstanceChild" | "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
}

export interface SubstanceUpdateMutation {
  substanceUpdate: SubstanceUpdateMutation_substanceUpdate | null;
}

export interface SubstanceUpdateMutationVariables {
  id: string;
  name?: string | null;
  contaminationTypes?: ContaminationType[] | null;
  groupBy?: SubstanceGroupBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubstanceCreateMutation
// ====================================================

export interface SubstanceCreateMutation_substanceCreate {
  __typename: "SubstanceChild" | "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
}

export interface SubstanceCreateMutation {
  substanceCreate: SubstanceCreateMutation_substanceCreate | null;
}

export interface SubstanceCreateMutationVariables {
  name: string;
  parentId?: string | null;
  contaminationTypes?: ContaminationType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubstanceUpdatePositionMutation
// ====================================================

export interface SubstanceUpdatePositionMutation_substanceUpdatePosition {
  __typename: "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
}

export interface SubstanceUpdatePositionMutation {
  substanceUpdatePosition: (SubstanceUpdatePositionMutation_substanceUpdatePosition | null)[] | null;
}

export interface SubstanceUpdatePositionMutationVariables {
  items?: SubstanceUpdatePosition[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubstanceCheckUsageQuery
// ====================================================

export interface SubstanceCheckUsageQuery {
  substanceCheckUsage: boolean | null;
}

export interface SubstanceCheckUsageQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubstanceByIdQuery
// ====================================================

export interface SubstanceByIdQuery_substance {
  __typename: "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
}

export interface SubstanceByIdQuery {
  substance: SubstanceByIdQuery_substance | null;
}

export interface SubstanceByIdQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubstancesListQuery
// ====================================================

export interface SubstancesListQuery_substances_children {
  __typename: "SubstanceChild";
  id: string;
  name: string;
  position: number;
  parentId: string;
}

export interface SubstancesListQuery_substances {
  __typename: "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
  contaminationTypes: ContaminationType[];
  groupBy: SubstanceGroupBy | null;
  children: SubstancesListQuery_substances_children[] | null;
}

export interface SubstancesListQuery {
  substances: SubstancesListQuery_substances[];
}

export interface SubstancesListQueryVariables {
  search?: string | null;
  first?: number | null;
  after?: string | null;
  contaminationType?: ContaminationType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TimeSpentQuery
// ====================================================

export interface TimeSpentQuery_timeSpentList_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface TimeSpentQuery_timeSpentList {
  __typename: "TimeSpent";
  id: string;
  userId: string;
  user: TimeSpentQuery_timeSpentList_user | null;
  dumpLoadNumber: string;
  date: GQL_Date;
  hoursSpent: number;
  licensePlate: string;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
  comment: string | null;
  beastStatus: BeastStatus | null;
}

export interface TimeSpentQuery {
  timeSpentList: TimeSpentQuery_timeSpentList[];
}

export interface TimeSpentQueryVariables {
  filter: TimeSpentFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ToxicLimitsQuery
// ====================================================

export interface ToxicLimitsQuery_substances_children {
  __typename: "SubstanceChild";
  id: string;
  name: string;
  position: number;
  parentId: string;
}

export interface ToxicLimitsQuery_substances {
  __typename: "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
  contaminationTypes: ContaminationType[];
  groupBy: SubstanceGroupBy | null;
  children: ToxicLimitsQuery_substances_children[] | null;
}

export interface ToxicLimitsQuery_toxicLimitsValues_SOLID_limits {
  __typename: "ToxicLevelLimit";
  levelName: ContaminationLevel;
  value: number | null;
}

export interface ToxicLimitsQuery_toxicLimitsValues_SOLID {
  __typename: "ToxicLimit";
  substanceId: string;
  limits: ToxicLimitsQuery_toxicLimitsValues_SOLID_limits[];
}

export interface ToxicLimitsQuery_toxicLimitsValues_LEACHING_limits {
  __typename: "ToxicLevelLimit";
  levelName: ContaminationLevel;
  value: number | null;
}

export interface ToxicLimitsQuery_toxicLimitsValues_LEACHING {
  __typename: "ToxicLimit";
  substanceId: string;
  limits: ToxicLimitsQuery_toxicLimitsValues_LEACHING_limits[];
}

export interface ToxicLimitsQuery_toxicLimitsValues {
  __typename: "ToxicValues";
  SOLID: ToxicLimitsQuery_toxicLimitsValues_SOLID[];
  LEACHING: ToxicLimitsQuery_toxicLimitsValues_LEACHING[];
}

export interface ToxicLimitsQuery_toxicSumRules_substances {
  __typename: "ToxicSumRuleSubstance";
  substanceId: string;
  threshold: number;
}

export interface ToxicLimitsQuery_toxicSumRules {
  __typename: "ToxicSumRule";
  id: string;
  name: string;
  faSumLevel: number;
  substances: ToxicLimitsQuery_toxicSumRules_substances[];
}

export interface ToxicLimitsQuery {
  substances: ToxicLimitsQuery_substances[];
  toxicLimitsValues: ToxicLimitsQuery_toxicLimitsValues;
  toxicSumRules: ToxicLimitsQuery_toxicSumRules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateToxicLimitMutation
// ====================================================

export interface UpdateToxicLimitMutation {
  changeLimitValue: boolean | null;
}

export interface UpdateToxicLimitMutationVariables {
  contaminationType: ContaminationType;
  substanceId: string;
  levelName: ContaminationLevel;
  value?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSumRuleMutation
// ====================================================

export interface CreateSumRuleMutation_createSumRule_substances {
  __typename: "ToxicSumRuleSubstance";
  substanceId: string;
  threshold: number;
}

export interface CreateSumRuleMutation_createSumRule {
  __typename: "ToxicSumRule";
  id: string;
  name: string;
  faSumLevel: number;
  substances: CreateSumRuleMutation_createSumRule_substances[];
}

export interface CreateSumRuleMutation {
  createSumRule: CreateSumRuleMutation_createSumRule;
}

export interface CreateSumRuleMutationVariables {
  input: ToxicSumRuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSumRuleMutation
// ====================================================

export interface UpdateSumRuleMutation_updateSumRule_substances {
  __typename: "ToxicSumRuleSubstance";
  substanceId: string;
  threshold: number;
}

export interface UpdateSumRuleMutation_updateSumRule {
  __typename: "ToxicSumRule";
  id: string;
  name: string;
  faSumLevel: number;
  substances: UpdateSumRuleMutation_updateSumRule_substances[];
}

export interface UpdateSumRuleMutation {
  updateSumRule: UpdateSumRuleMutation_updateSumRule;
}

export interface UpdateSumRuleMutationVariables {
  id: string;
  input: ToxicSumRuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSumRuleMutation
// ====================================================

export interface RemoveSumRuleMutation {
  removeSumRule: boolean;
}

export interface RemoveSumRuleMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserActivityPagedQuery
// ====================================================

export interface UserActivityPagedQuery_activitiesPaged_edges_node_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface UserActivityPagedQuery_activitiesPaged_edges_node {
  __typename: "UserActivity";
  id: string;
  user: UserActivityPagedQuery_activitiesPaged_edges_node_user;
  lastActivityDate: GQL_Date;
  minutesAgo: number;
}

export interface UserActivityPagedQuery_activitiesPaged_edges {
  __typename: "UserActivityEdge";
  cursor: string;
  node: UserActivityPagedQuery_activitiesPaged_edges_node;
}

export interface UserActivityPagedQuery_activitiesPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface UserActivityPagedQuery_activitiesPaged {
  __typename: "UserActivityConnection";
  edges: UserActivityPagedQuery_activitiesPaged_edges[];
  pageInfo: UserActivityPagedQuery_activitiesPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface UserActivityPagedQuery {
  activitiesPaged: UserActivityPagedQuery_activitiesPaged;
}

export interface UserActivityPagedQueryVariables {
  minutesToCut?: number | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUsersSearchQuery
// ====================================================

export interface AdminUsersSearchQuery_users {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface AdminUsersSearchQuery {
  users: AdminUsersSearchQuery_users[];
}

export interface AdminUsersSearchQueryVariables {
  filter: UserFilter;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUsersPagedQuery
// ====================================================

export interface AdminUsersPagedQuery_usersPaged_edges_node_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminUsersPagedQuery_usersPaged_edges_node_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminUsersPagedQuery_usersPaged_edges_node {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: AdminUsersPagedQuery_usersPaged_edges_node_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: AdminUsersPagedQuery_usersPaged_edges_node_vehicles[];
  deletedAt: GQL_Date | null;
  lastCommentText: string | null;
}

export interface AdminUsersPagedQuery_usersPaged_edges {
  __typename: "UserEdge";
  cursor: string;
  node: AdminUsersPagedQuery_usersPaged_edges_node;
}

export interface AdminUsersPagedQuery_usersPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminUsersPagedQuery_usersPaged {
  __typename: "UserConnection";
  edges: AdminUsersPagedQuery_usersPaged_edges[];
  pageInfo: AdminUsersPagedQuery_usersPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminUsersPagedQuery {
  usersPaged: AdminUsersPagedQuery_usersPaged;
}

export interface AdminUsersPagedQueryVariables {
  filter?: UserFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUserQuery
// ====================================================

export interface AdminUserQuery_user_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminUserQuery_user_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface AdminUserQuery_user_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface AdminUserQuery_user_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: AdminUserQuery_user_comments_author | null;
}

export interface AdminUserQuery_user {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: AdminUserQuery_user_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: AdminUserQuery_user_vehicles[];
  deletedAt: GQL_Date | null;
  comments: AdminUserQuery_user_comments[];
}

export interface AdminUserQuery {
  user: AdminUserQuery_user | null;
}

export interface AdminUserQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUserProjectsQuery
// ====================================================

export interface AdminUserProjectsQuery_projectsPaged_edges_node_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface AdminUserProjectsQuery_projectsPaged_edges_node {
  __typename: "Project";
  id: string;
  name: string;
  userLinks: AdminUserProjectsQuery_projectsPaged_edges_node_userLinks[];
  customerId: string;
  ownerUserId: string | null;
  status: ProjectStatus;
  ver: number;
}

export interface AdminUserProjectsQuery_projectsPaged_edges {
  __typename: "ProjectEdge";
  cursor: string;
  node: AdminUserProjectsQuery_projectsPaged_edges_node;
}

export interface AdminUserProjectsQuery_projectsPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminUserProjectsQuery_projectsPaged {
  __typename: "ProjectConnection";
  edges: AdminUserProjectsQuery_projectsPaged_edges[];
  pageInfo: AdminUserProjectsQuery_projectsPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminUserProjectsQuery {
  projectsPaged: AdminUserProjectsQuery_projectsPaged;
}

export interface AdminUserProjectsQueryVariables {
  filter?: ProjectFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUserLandfillsQuery
// ====================================================

export interface AdminUserLandfillsQuery_landfillsPaged_edges_node {
  __typename: "Landfill";
  id: string;
  name: string;
  receiverIds: string[];
  customerId: string;
  ownerUserId: string | null;
}

export interface AdminUserLandfillsQuery_landfillsPaged_edges {
  __typename: "LandfillEdge";
  cursor: string;
  node: AdminUserLandfillsQuery_landfillsPaged_edges_node;
}

export interface AdminUserLandfillsQuery_landfillsPaged_pageInfo {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

export interface AdminUserLandfillsQuery_landfillsPaged {
  __typename: "LandfillConnection";
  edges: AdminUserLandfillsQuery_landfillsPaged_edges[];
  pageInfo: AdminUserLandfillsQuery_landfillsPaged_pageInfo;
  pageCount: number;
  totalCount: number;
}

export interface AdminUserLandfillsQuery {
  landfillsPaged: AdminUserLandfillsQuery_landfillsPaged;
}

export interface AdminUserLandfillsQueryVariables {
  filter?: LandfillFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersListQuery
// ====================================================

export interface UsersListQuery_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UsersListQuery_users_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UsersListQuery_users {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UsersListQuery_users_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UsersListQuery_users_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UsersListQuery {
  users: UsersListQuery_users[];
}

export interface UsersListQueryVariables {
  filter?: UserFilter | null;
  sort?: SortInput | null;
  connection?: ConnectionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserUpdateMutation
// ====================================================

export interface UserUpdateMutation_userUpdate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserUpdateMutation_userUpdate_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserUpdateMutation_userUpdate {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserUpdateMutation_userUpdate_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserUpdateMutation_userUpdate_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserUpdateMutation {
  userUpdate: UserUpdateMutation_userUpdate | null;
}

export interface UserUpdateMutationVariables {
  input: UserUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserVerifyMutation
// ====================================================

export interface UserVerifyMutation_userVerify_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserVerifyMutation_userVerify_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserVerifyMutation_userVerify {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserVerifyMutation_userVerify_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserVerifyMutation_userVerify_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserVerifyMutation {
  userVerify: UserVerifyMutation_userVerify | null;
}

export interface UserVerifyMutationVariables {
  userId: string;
  verified?: boolean | null;
  blocked?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserCreateMutation
// ====================================================

export interface UserCreateMutation_userCreate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserCreateMutation_userCreate_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserCreateMutation_userCreate {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserCreateMutation_userCreate_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserCreateMutation_userCreate_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserCreateMutation {
  userCreate: UserCreateMutation_userCreate | null;
}

export interface UserCreateMutationVariables {
  input: UserCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserRemoveMutation
// ====================================================

export interface UserRemoveMutation {
  userRemove: boolean | null;
}

export interface UserRemoveMutationVariables {
  id: string;
  sendNotify?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserRestoreMutation
// ====================================================

export interface UserRestoreMutation_userRestore {
  __typename: "User";
  id: string;
}

export interface UserRestoreMutation {
  userRestore: UserRestoreMutation_userRestore;
}

export interface UserRestoreMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserPasswordResetMutation
// ====================================================

export interface UserPasswordResetMutation_userPasswordReset_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserPasswordResetMutation_userPasswordReset_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserPasswordResetMutation_userPasswordReset {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserPasswordResetMutation_userPasswordReset_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserPasswordResetMutation_userPasswordReset_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserPasswordResetMutation {
  userPasswordReset: UserPasswordResetMutation_userPasswordReset | null;
}

export interface UserPasswordResetMutationVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserPasswordChangeMutation
// ====================================================

export interface UserPasswordChangeMutation {
  userChangePassword: boolean | null;
}

export interface UserPasswordChangeMutationVariables {
  input: UserChangePasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserLandfillCreateMutation
// ====================================================

export interface UserLandfillCreateMutation_userLandfillCreate_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserLandfillCreateMutation_userLandfillCreate_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserLandfillCreateMutation_userLandfillCreate {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserLandfillCreateMutation_userLandfillCreate_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserLandfillCreateMutation_userLandfillCreate_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserLandfillCreateMutation {
  userLandfillCreate: UserLandfillCreateMutation_userLandfillCreate;
}

export interface UserLandfillCreateMutationVariables {
  userId: string;
  landfillId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserLandfillRemoveMutation
// ====================================================

export interface UserLandfillRemoveMutation_userLandfillRemove_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserLandfillRemoveMutation_userLandfillRemove_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserLandfillRemoveMutation_userLandfillRemove {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserLandfillRemoveMutation_userLandfillRemove_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserLandfillRemoveMutation_userLandfillRemove_vehicles[];
  deletedAt: GQL_Date | null;
}

export interface UserLandfillRemoveMutation {
  userLandfillRemove: UserLandfillRemoveMutation_userLandfillRemove;
}

export interface UserLandfillRemoveMutationVariables {
  userId: string;
  landfillId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserCommentCreateMutation
// ====================================================

export interface UserCommentCreateMutation_userAddComment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface UserCommentCreateMutation_userAddComment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: UserCommentCreateMutation_userAddComment_comments_author | null;
}

export interface UserCommentCreateMutation_userAddComment {
  __typename: "User";
  id: string;
  comments: UserCommentCreateMutation_userAddComment_comments[];
  lastCommentText: string | null;
}

export interface UserCommentCreateMutation {
  userAddComment: UserCommentCreateMutation_userAddComment;
}

export interface UserCommentCreateMutationVariables {
  userId: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VehicleTypes
// ====================================================

export interface VehicleTypes_vehicleTypes_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface VehicleTypes_vehicleTypes {
  __typename: "VehicleType";
  id: string;
  kind: VehicleKind;
  name: string;
  externalId: string;
  fuelConsumptions: VehicleTypes_vehicleTypes_fuelConsumptions[];
}

export interface VehicleTypes {
  vehicleTypes: VehicleTypes_vehicleTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VehicleTypeCreate
// ====================================================

export interface VehicleTypeCreate_vehicleTypeCreate_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface VehicleTypeCreate_vehicleTypeCreate {
  __typename: "VehicleType";
  id: string;
  kind: VehicleKind;
  name: string;
  externalId: string;
  fuelConsumptions: VehicleTypeCreate_vehicleTypeCreate_fuelConsumptions[];
}

export interface VehicleTypeCreate {
  vehicleTypeCreate: VehicleTypeCreate_vehicleTypeCreate;
}

export interface VehicleTypeCreateVariables {
  input: VehicleTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VehicleTypeUpdate
// ====================================================

export interface VehicleTypeUpdate_vehicleTypeUpdate_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface VehicleTypeUpdate_vehicleTypeUpdate {
  __typename: "VehicleType";
  id: string;
  kind: VehicleKind;
  name: string;
  externalId: string;
  fuelConsumptions: VehicleTypeUpdate_vehicleTypeUpdate_fuelConsumptions[];
}

export interface VehicleTypeUpdate {
  vehicleTypeUpdate: VehicleTypeUpdate_vehicleTypeUpdate;
}

export interface VehicleTypeUpdateVariables {
  id: string;
  input: VehicleTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VehicleTypeRemove
// ====================================================

export interface VehicleTypeRemove {
  vehicleTypeRemove: boolean;
}

export interface VehicleTypeRemoveVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GptConversationHistory
// ====================================================

export interface GptConversationHistory_chatGptConversation {
  __typename: "GptMessage";
  id: string;
  userInput: string;
  aiOutput: string;
}

export interface GptConversationHistory {
  chatGptConversation: GptConversationHistory_chatGptConversation[];
}

export interface GptConversationHistoryVariables {
  input: GptConversationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGptMessage
// ====================================================

export interface SendGptMessage_sendChatGptMessage {
  __typename: "GptMessage";
  id: string;
  userInput: string;
  aiOutput: string;
}

export interface SendGptMessage {
  sendChatGptMessage: SendGptMessage_sendChatGptMessage;
}

export interface SendGptMessageVariables {
  input: GptConversationInput;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeviationsByProjectQuery
// ====================================================

export interface DeviationsByProjectQuery_deviationsByProject_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface DeviationsByProjectQuery_deviationsByProject_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DeviationsByProjectQuery_deviationsByProject_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface DeviationsByProjectQuery_deviationsByProject_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: DeviationsByProjectQuery_deviationsByProject_comments_author | null;
}

export interface DeviationsByProjectQuery_deviationsByProject {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: DeviationsByProjectQuery_deviationsByProject_author;
  photos: DeviationsByProjectQuery_deviationsByProject_photos[];
  comments: DeviationsByProjectQuery_deviationsByProject_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface DeviationsByProjectQuery {
  deviationsByProject: DeviationsByProjectQuery_deviationsByProject[];
}

export interface DeviationsByProjectQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectDeviationCreateMutation
// ====================================================

export interface ProjectDeviationCreateMutation_deviationProjectCreate_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface ProjectDeviationCreateMutation_deviationProjectCreate_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDeviationCreateMutation_deviationProjectCreate_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDeviationCreateMutation_deviationProjectCreate_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDeviationCreateMutation_deviationProjectCreate_comments_author | null;
}

export interface ProjectDeviationCreateMutation_deviationProjectCreate {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: ProjectDeviationCreateMutation_deviationProjectCreate_author;
  photos: ProjectDeviationCreateMutation_deviationProjectCreate_photos[];
  comments: ProjectDeviationCreateMutation_deviationProjectCreate_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface ProjectDeviationCreateMutation {
  deviationProjectCreate: ProjectDeviationCreateMutation_deviationProjectCreate;
}

export interface ProjectDeviationCreateMutationVariables {
  input: DeviationProjectCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectDeviationUpdateMutation
// ====================================================

export interface ProjectDeviationUpdateMutation_deviationProjectUpdate_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface ProjectDeviationUpdateMutation_deviationProjectUpdate_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDeviationUpdateMutation_deviationProjectUpdate_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDeviationUpdateMutation_deviationProjectUpdate_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDeviationUpdateMutation_deviationProjectUpdate_comments_author | null;
}

export interface ProjectDeviationUpdateMutation_deviationProjectUpdate {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: ProjectDeviationUpdateMutation_deviationProjectUpdate_author;
  photos: ProjectDeviationUpdateMutation_deviationProjectUpdate_photos[];
  comments: ProjectDeviationUpdateMutation_deviationProjectUpdate_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface ProjectDeviationUpdateMutation {
  deviationProjectUpdate: ProjectDeviationUpdateMutation_deviationProjectUpdate;
}

export interface ProjectDeviationUpdateMutationVariables {
  id: string;
  input: DeviationProjectUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectDeviationCommentMutation
// ====================================================

export interface ProjectDeviationCommentMutation_deviationProjectComment_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface ProjectDeviationCommentMutation_deviationProjectComment_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDeviationCommentMutation_deviationProjectComment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDeviationCommentMutation_deviationProjectComment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDeviationCommentMutation_deviationProjectComment_comments_author | null;
}

export interface ProjectDeviationCommentMutation_deviationProjectComment {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: ProjectDeviationCommentMutation_deviationProjectComment_author;
  photos: ProjectDeviationCommentMutation_deviationProjectComment_photos[];
  comments: ProjectDeviationCommentMutation_deviationProjectComment_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface ProjectDeviationCommentMutation {
  deviationProjectComment: ProjectDeviationCommentMutation_deviationProjectComment;
}

export interface ProjectDeviationCommentMutationVariables {
  id: string;
  input: DeviationProjectCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectDeviationCloseMutation
// ====================================================

export interface ProjectDeviationCloseMutation_deviationProjectClose_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface ProjectDeviationCloseMutation_deviationProjectClose_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDeviationCloseMutation_deviationProjectClose_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDeviationCloseMutation_deviationProjectClose_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDeviationCloseMutation_deviationProjectClose_comments_author | null;
}

export interface ProjectDeviationCloseMutation_deviationProjectClose {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: ProjectDeviationCloseMutation_deviationProjectClose_author;
  photos: ProjectDeviationCloseMutation_deviationProjectClose_photos[];
  comments: ProjectDeviationCloseMutation_deviationProjectClose_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface ProjectDeviationCloseMutation {
  deviationProjectClose: ProjectDeviationCloseMutation_deviationProjectClose;
}

export interface ProjectDeviationCloseMutationVariables {
  id: string;
  files: GQL_Upload[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectDeviationRemoveFileMutation
// ====================================================

export interface ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_comments_author | null;
}

export interface ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_author;
  photos: ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_photos[];
  comments: ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface ProjectDeviationRemoveFileMutation {
  deviationProjectRemoveFile: ProjectDeviationRemoveFileMutation_deviationProjectRemoveFile;
}

export interface ProjectDeviationRemoveFileMutationVariables {
  id: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeviationsByLandfillQuery
// ====================================================

export interface DeviationsByLandfillQuery_deviationsByLandfill_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface DeviationsByLandfillQuery_deviationsByLandfill_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DeviationsByLandfillQuery_deviationsByLandfill_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface DeviationsByLandfillQuery_deviationsByLandfill_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: DeviationsByLandfillQuery_deviationsByLandfill_comments_author | null;
}

export interface DeviationsByLandfillQuery_deviationsByLandfill {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: DeviationsByLandfillQuery_deviationsByLandfill_author;
  photos: DeviationsByLandfillQuery_deviationsByLandfill_photos[];
  comments: DeviationsByLandfillQuery_deviationsByLandfill_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface DeviationsByLandfillQuery {
  deviationsByLandfill: DeviationsByLandfillQuery_deviationsByLandfill[];
}

export interface DeviationsByLandfillQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeviationCreateMutation
// ====================================================

export interface LandfillDeviationCreateMutation_deviationLandfillCreate_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface LandfillDeviationCreateMutation_deviationLandfillCreate_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDeviationCreateMutation_deviationLandfillCreate_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface LandfillDeviationCreateMutation_deviationLandfillCreate_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: LandfillDeviationCreateMutation_deviationLandfillCreate_comments_author | null;
}

export interface LandfillDeviationCreateMutation_deviationLandfillCreate {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: LandfillDeviationCreateMutation_deviationLandfillCreate_author;
  photos: LandfillDeviationCreateMutation_deviationLandfillCreate_photos[];
  comments: LandfillDeviationCreateMutation_deviationLandfillCreate_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface LandfillDeviationCreateMutation {
  deviationLandfillCreate: LandfillDeviationCreateMutation_deviationLandfillCreate;
}

export interface LandfillDeviationCreateMutationVariables {
  input: DeviationLandfillCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeviationUpdateMutation
// ====================================================

export interface LandfillDeviationUpdateMutation_deviationLandfillUpdate_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface LandfillDeviationUpdateMutation_deviationLandfillUpdate_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDeviationUpdateMutation_deviationLandfillUpdate_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface LandfillDeviationUpdateMutation_deviationLandfillUpdate_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: LandfillDeviationUpdateMutation_deviationLandfillUpdate_comments_author | null;
}

export interface LandfillDeviationUpdateMutation_deviationLandfillUpdate {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: LandfillDeviationUpdateMutation_deviationLandfillUpdate_author;
  photos: LandfillDeviationUpdateMutation_deviationLandfillUpdate_photos[];
  comments: LandfillDeviationUpdateMutation_deviationLandfillUpdate_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface LandfillDeviationUpdateMutation {
  deviationLandfillUpdate: LandfillDeviationUpdateMutation_deviationLandfillUpdate;
}

export interface LandfillDeviationUpdateMutationVariables {
  id: string;
  input: DeviationLandfillUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeviationCommentMutation
// ====================================================

export interface LandfillDeviationCommentMutation_deviationLandfillComment_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface LandfillDeviationCommentMutation_deviationLandfillComment_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDeviationCommentMutation_deviationLandfillComment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface LandfillDeviationCommentMutation_deviationLandfillComment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: LandfillDeviationCommentMutation_deviationLandfillComment_comments_author | null;
}

export interface LandfillDeviationCommentMutation_deviationLandfillComment {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: LandfillDeviationCommentMutation_deviationLandfillComment_author;
  photos: LandfillDeviationCommentMutation_deviationLandfillComment_photos[];
  comments: LandfillDeviationCommentMutation_deviationLandfillComment_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface LandfillDeviationCommentMutation {
  deviationLandfillComment: LandfillDeviationCommentMutation_deviationLandfillComment;
}

export interface LandfillDeviationCommentMutationVariables {
  id: string;
  input: DeviationLandfillCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeviationCloseMutation
// ====================================================

export interface LandfillDeviationCloseMutation_deviationLandfillClose_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface LandfillDeviationCloseMutation_deviationLandfillClose_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDeviationCloseMutation_deviationLandfillClose_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface LandfillDeviationCloseMutation_deviationLandfillClose_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: LandfillDeviationCloseMutation_deviationLandfillClose_comments_author | null;
}

export interface LandfillDeviationCloseMutation_deviationLandfillClose {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: LandfillDeviationCloseMutation_deviationLandfillClose_author;
  photos: LandfillDeviationCloseMutation_deviationLandfillClose_photos[];
  comments: LandfillDeviationCloseMutation_deviationLandfillClose_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface LandfillDeviationCloseMutation {
  deviationLandfillClose: LandfillDeviationCloseMutation_deviationLandfillClose;
}

export interface LandfillDeviationCloseMutationVariables {
  id: string;
  files: GQL_Upload[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeviationRemoveFileMutation
// ====================================================

export interface LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_comments_author | null;
}

export interface LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_author;
  photos: LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_photos[];
  comments: LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

export interface LandfillDeviationRemoveFileMutation {
  deviationLandfillRemoveFile: LandfillDeviationRemoveFileMutation_deviationLandfillRemoveFile;
}

export interface LandfillDeviationRemoveFileMutationVariables {
  id: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsAndLandfillsQuery
// ====================================================

export interface ProjectsAndLandfillsQuery_projects_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectsAndLandfillsQuery_projects_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectsAndLandfillsQuery_projects_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  role: UserRole;
  customerId: string | null;
}

export interface ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  id: string;
  name: string;
  location: ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill_location;
  workTime: ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill_workTime[];
}

export interface ProjectsAndLandfillsQuery_projects_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  destinationLandfill: ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill | null;
  serialNumber: string;
  status: LoadStatus | null;
}

export interface ProjectsAndLandfillsQuery_projects {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  location: ProjectsAndLandfillsQuery_projects_location;
  comment: string | null;
  customerId: string;
  customer: ProjectsAndLandfillsQuery_projects_customer | null;
  owner: ProjectsAndLandfillsQuery_projects_owner | null;
  dumpLoads: ProjectsAndLandfillsQuery_projects_dumpLoads[];
}

export interface ProjectsAndLandfillsQuery_landfills_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectsAndLandfillsQuery_landfills_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectsAndLandfillsQuery_landfills_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectsAndLandfillsQuery_landfills_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface ProjectsAndLandfillsQuery_landfills_projects_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectsAndLandfillsQuery_landfills_projects_dumpLoads {
  __typename: "DumpLoadRecord";
  id: string;
  serialNumber: string;
}

export interface ProjectsAndLandfillsQuery_landfills_projects {
  __typename: "ProjectRecord";
  id: string;
  name: string;
  location: ProjectsAndLandfillsQuery_landfills_projects_location;
  dumpLoads: ProjectsAndLandfillsQuery_landfills_projects_dumpLoads[];
}

export interface ProjectsAndLandfillsQuery_landfills {
  __typename: "Landfill";
  id: string;
  name: string;
  location: ProjectsAndLandfillsQuery_landfills_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: ProjectsAndLandfillsQuery_landfills_receivers[] | null;
  workTime: ProjectsAndLandfillsQuery_landfills_workTime[];
  comment: string | null;
  status: ProjectsAndLandfillsQuery_landfills_status | null;
  isDeleted: boolean | null;
  capacity: number;
  projects: ProjectsAndLandfillsQuery_landfills_projects[];
}

export interface ProjectsAndLandfillsQuery {
  projects: ProjectsAndLandfillsQuery_projects[];
  landfills: ProjectsAndLandfillsQuery_landfills[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminProjectsAndLandfillsQuery
// ====================================================

export interface AdminProjectsAndLandfillsQuery_projects_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminProjectsAndLandfillsQuery_projects_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface AdminProjectsAndLandfillsQuery_projects_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  role: UserRole;
  customerId: string | null;
}

export interface AdminProjectsAndLandfillsQuery_projects {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  location: AdminProjectsAndLandfillsQuery_projects_location;
  comment: string | null;
  customerId: string;
  customer: AdminProjectsAndLandfillsQuery_projects_customer | null;
  owner: AdminProjectsAndLandfillsQuery_projects_owner | null;
}

export interface AdminProjectsAndLandfillsQuery_landfills_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface AdminProjectsAndLandfillsQuery_landfills_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface AdminProjectsAndLandfillsQuery_landfills_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface AdminProjectsAndLandfillsQuery_landfills_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface AdminProjectsAndLandfillsQuery_landfills {
  __typename: "Landfill";
  id: string;
  name: string;
  location: AdminProjectsAndLandfillsQuery_landfills_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: AdminProjectsAndLandfillsQuery_landfills_receivers[] | null;
  workTime: AdminProjectsAndLandfillsQuery_landfills_workTime[];
  comment: string | null;
  status: AdminProjectsAndLandfillsQuery_landfills_status | null;
  isDeleted: boolean | null;
  capacity: number;
}

export interface AdminProjectsAndLandfillsQuery {
  projects: AdminProjectsAndLandfillsQuery_projects[];
  landfills: AdminProjectsAndLandfillsQuery_landfills[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BatchLogQuery
// ====================================================

export interface BatchLogQuery_batchTransfers_fromBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchLogQuery_batchTransfers_toBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchLogQuery_batchTransfers_externalProject {
  __typename: "ProjectInfo";
  name: string;
  address: string;
}

export interface BatchLogQuery_batchTransfers {
  __typename: "BatchTransfer";
  id: string;
  date: GQL_Date;
  type: TransferType;
  fromBatch: BatchLogQuery_batchTransfers_fromBatch | null;
  toBatch: BatchLogQuery_batchTransfers_toBatch | null;
  weight: number;
  externalProject: BatchLogQuery_batchTransfers_externalProject | null;
  licensePlate: string | null;
  comment: string | null;
}

export interface BatchLogQuery_batchAnalyses {
  __typename: "BatchAnalysis";
  id: string;
  date: GQL_Date;
  analysisResult: ContaminationLevel;
  analysisFileUrl: string;
}

export interface BatchLogQuery {
  batchTransfers: BatchLogQuery_batchTransfers[];
  batchAnalyses: BatchLogQuery_batchAnalyses[];
}

export interface BatchLogQueryVariables {
  landfillId: string;
  batchId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBatchTransferMutation
// ====================================================

export interface CreateBatchTransferMutation_createBatchTransfer_fromBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface CreateBatchTransferMutation_createBatchTransfer_toBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface CreateBatchTransferMutation_createBatchTransfer_externalProject {
  __typename: "ProjectInfo";
  name: string;
  address: string;
}

export interface CreateBatchTransferMutation_createBatchTransfer {
  __typename: "BatchTransfer";
  id: string;
  date: GQL_Date;
  type: TransferType;
  fromBatch: CreateBatchTransferMutation_createBatchTransfer_fromBatch | null;
  toBatch: CreateBatchTransferMutation_createBatchTransfer_toBatch | null;
  weight: number;
  externalProject: CreateBatchTransferMutation_createBatchTransfer_externalProject | null;
  licensePlate: string | null;
  comment: string | null;
}

export interface CreateBatchTransferMutation {
  createBatchTransfer: CreateBatchTransferMutation_createBatchTransfer;
}

export interface CreateBatchTransferMutationVariables {
  landfillId: string;
  input: BatchTransferInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBatchTransferMutation
// ====================================================

export interface UpdateBatchTransferMutation_updateBatchTransfer_fromBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface UpdateBatchTransferMutation_updateBatchTransfer_toBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface UpdateBatchTransferMutation_updateBatchTransfer_externalProject {
  __typename: "ProjectInfo";
  name: string;
  address: string;
}

export interface UpdateBatchTransferMutation_updateBatchTransfer {
  __typename: "BatchTransfer";
  id: string;
  date: GQL_Date;
  type: TransferType;
  fromBatch: UpdateBatchTransferMutation_updateBatchTransfer_fromBatch | null;
  toBatch: UpdateBatchTransferMutation_updateBatchTransfer_toBatch | null;
  weight: number;
  externalProject: UpdateBatchTransferMutation_updateBatchTransfer_externalProject | null;
  licensePlate: string | null;
  comment: string | null;
}

export interface UpdateBatchTransferMutation {
  updateBatchTransfer: UpdateBatchTransferMutation_updateBatchTransfer;
}

export interface UpdateBatchTransferMutationVariables {
  landfillId: string;
  id: string;
  input: BatchTransferInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveBatchTransferMutation
// ====================================================

export interface RemoveBatchTransferMutation {
  deleteBatchTransfer: boolean;
}

export interface RemoveBatchTransferMutationVariables {
  landfillId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BatchTransfers
// ====================================================

export interface BatchTransfers_batchTransfers_fromBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchTransfers_batchTransfers_toBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchTransfers_batchTransfers_externalProject {
  __typename: "ProjectInfo";
  name: string;
  address: string;
}

export interface BatchTransfers_batchTransfers {
  __typename: "BatchTransfer";
  id: string;
  date: GQL_Date;
  type: TransferType;
  fromBatch: BatchTransfers_batchTransfers_fromBatch | null;
  toBatch: BatchTransfers_batchTransfers_toBatch | null;
  weight: number;
  externalProject: BatchTransfers_batchTransfers_externalProject | null;
  licensePlate: string | null;
  comment: string | null;
}

export interface BatchTransfers {
  batchTransfers: BatchTransfers_batchTransfers[];
}

export interface BatchTransfersVariables {
  landfillId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBatchAnalysisMutation
// ====================================================

export interface CreateBatchAnalysisMutation_createBatchAnalysis {
  __typename: "BatchAnalysis";
  id: string;
  date: GQL_Date;
  analysisResult: ContaminationLevel;
  analysisFileUrl: string;
}

export interface CreateBatchAnalysisMutation {
  createBatchAnalysis: CreateBatchAnalysisMutation_createBatchAnalysis;
}

export interface CreateBatchAnalysisMutationVariables {
  landfillId: string;
  input: BatchAnalysisInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBatchAnalysisMutation
// ====================================================

export interface UpdateBatchAnalysisMutation_updateBatchAnalysis {
  __typename: "BatchAnalysis";
  id: string;
  date: GQL_Date;
  analysisResult: ContaminationLevel;
  analysisFileUrl: string;
}

export interface UpdateBatchAnalysisMutation {
  updateBatchAnalysis: UpdateBatchAnalysisMutation_updateBatchAnalysis;
}

export interface UpdateBatchAnalysisMutationVariables {
  landfillId: string;
  id: string;
  input: BatchAnalysisInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveBatchAnalysisMutation
// ====================================================

export interface RemoveBatchAnalysisMutation {
  deleteBatchAnalysis: boolean;
}

export interface RemoveBatchAnalysisMutationVariables {
  landfillId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LandfillDeliveryEvents
// ====================================================

export interface LandfillDeliveryEvents_landfillEvents_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
}

export interface LandfillDeliveryEvents_landfillEvents {
  __typename: "LandfillDeliveryEvent";
  id: string;
  date: GQL_Date;
  licensePlate: string;
  photoUrl: string;
  deliveryLine: LandfillDeliveryEvents_landfillEvents_deliveryLine | null;
}

export interface LandfillDeliveryEvents {
  landfillEvents: LandfillDeliveryEvents_landfillEvents[];
}

export interface LandfillDeliveryEventsVariables {
  landfillId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveryLinesMatchedForEvent
// ====================================================

export interface DeliveryLinesMatchedForEvent_deliveryLinesMatchedForEvent {
  __typename: "DeliveryLineInfo";
  id: string;
  plannedStartDate: string;
  endDeliveryDate: GQL_Date | null;
  orderNumber: string;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  weight: number;
  dumpTypeName: string;
  solidTestResult: ContaminationLevel | null;
}

export interface DeliveryLinesMatchedForEvent {
  deliveryLinesMatchedForEvent: DeliveryLinesMatchedForEvent_deliveryLinesMatchedForEvent[];
}

export interface DeliveryLinesMatchedForEventVariables {
  eventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLandfillDeliveryEvent
// ====================================================

export interface DeleteLandfillDeliveryEvent {
  landfillDeliveryEventDelete: boolean;
}

export interface DeleteLandfillDeliveryEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeliveryEventAttachToLine
// ====================================================

export interface LandfillDeliveryEventAttachToLine_landfillDeliveryEventAttachToLine_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
}

export interface LandfillDeliveryEventAttachToLine_landfillDeliveryEventAttachToLine {
  __typename: "LandfillDeliveryEvent";
  id: string;
  deliveryLine: LandfillDeliveryEventAttachToLine_landfillDeliveryEventAttachToLine_deliveryLine | null;
}

export interface LandfillDeliveryEventAttachToLine {
  landfillDeliveryEventAttachToLine: LandfillDeliveryEventAttachToLine_landfillDeliveryEventAttachToLine;
}

export interface LandfillDeliveryEventAttachToLineVariables {
  id: string;
  deliveryLineId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LandfillDeliveryEventDetachFromLine
// ====================================================

export interface LandfillDeliveryEventDetachFromLine_landfillDeliveryEventDetachFromLine_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
}

export interface LandfillDeliveryEventDetachFromLine_landfillDeliveryEventDetachFromLine {
  __typename: "LandfillDeliveryEvent";
  id: string;
  deliveryLine: LandfillDeliveryEventDetachFromLine_landfillDeliveryEventDetachFromLine_deliveryLine | null;
}

export interface LandfillDeliveryEventDetachFromLine {
  landfillDeliveryEventDetachFromLine: LandfillDeliveryEventDetachFromLine_landfillDeliveryEventDetachFromLine;
}

export interface LandfillDeliveryEventDetachFromLineVariables {
  deliveryLineId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDeliveryLineSenderCommentMutation
// ====================================================

export interface UpdateDeliveryLineSenderCommentMutation_deliveryLineUpdateSenderComment {
  __typename: "PplDeliveryLine";
  id: string;
  senderComment: string | null;
}

export interface UpdateDeliveryLineSenderCommentMutation {
  deliveryLineUpdateSenderComment: UpdateDeliveryLineSenderCommentMutation_deliveryLineUpdateSenderComment;
}

export interface UpdateDeliveryLineSenderCommentMutationVariables {
  projectId: string;
  lineId: string;
  senderComment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDeliveryLineReceiverFieldsMutation
// ====================================================

export interface UpdateDeliveryLineReceiverFieldsMutation_deliveryLineUpdateReceiverFields {
  __typename: "DeliveryLineInfo";
  id: string;
  receiverComment: string;
  receiverCheck: boolean;
}

export interface UpdateDeliveryLineReceiverFieldsMutation {
  deliveryLineUpdateReceiverFields: UpdateDeliveryLineReceiverFieldsMutation_deliveryLineUpdateReceiverFields;
}

export interface UpdateDeliveryLineReceiverFieldsMutationVariables {
  input: DeliveryLineReceiverUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDeliveryLineBatch
// ====================================================

export interface UpdateDeliveryLineBatch_deliveryLineUpdateBatch_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface UpdateDeliveryLineBatch_deliveryLineUpdateBatch {
  __typename: "DeliveryLineInfo";
  id: string;
  batchId: string | null;
  batch: UpdateDeliveryLineBatch_deliveryLineUpdateBatch_batch | null;
}

export interface UpdateDeliveryLineBatch {
  deliveryLineUpdateBatch: UpdateDeliveryLineBatch_deliveryLineUpdateBatch;
}

export interface UpdateDeliveryLineBatchVariables {
  lineId: string;
  batchId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProfileFragment
// ====================================================

export interface ProfileFragment_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProfileFragment {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  startPage: string | null;
  customer: ProfileFragment_customer | null;
  customerId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DespatchAdviceFragment
// ====================================================

export interface DespatchAdviceFragment {
  __typename: "DespatchAdvice";
  id: string;
  category: DespatchAdviceCategory;
  error: string | null;
  canSend: boolean;
  adviceType: DespatchAdviceType;
  createdAt: GQL_Date | null;
  sentAt: GQL_Date | null;
  transactionId: string | null;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DespatchAdviceResponseFragment
// ====================================================

export interface DespatchAdviceResponseFragment_deliveryLine_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DespatchAdviceResponseFragment_deliveryLine {
  __typename: "PplDeliveryLine";
  id: string;
  deliveryLineIdx: number;
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  status: DeliveryLineStatus;
  company: DespatchAdviceResponseFragment_deliveryLine_company | null;
}

export interface DespatchAdviceResponseFragment {
  __typename: "DespatchAdviceResponse";
  id: string;
  date: GQL_Date;
  body: string;
  despatchAdviceId: string | null;
  responseType: DespatchAdviceResponseType;
  transactionId: string;
  declineReason: string | null;
  deliveryLine: DespatchAdviceResponseFragment_deliveryLine | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BeastMaterialFragment
// ====================================================

export interface BeastMaterialFragment {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BlacklistItemFragment
// ====================================================

export interface BlacklistItemFragment {
  __typename: "EmailsBlacklisted";
  id: string;
  email: GQL_Email;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CommentFragment
// ====================================================

export interface CommentFragment_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface CommentFragment {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: CommentFragment_author | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoFragment
// ====================================================

export interface PageInfoFragment {
  __typename: "PageInfo";
  startCursor: string;
  hasNextPage: boolean;
  endCursor: string;
  hasPreviousPage: boolean;
  currentPage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyFragment
// ====================================================

export interface CompanyFragment {
  __typename: "PplCompany";
  id: string;
  organizationNumber: string | null;
  customerNumber: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: GQL_Email | null;
  web: string | null;
  contactPerson: string | null;
  contactPerson2: string | null;
  address: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  comment: string | null;
  active: boolean | null;
  APIKey: string | null;
  lastSyncAt: GQL_Date | null;
  isTransportCompany: boolean | null;
  GLN: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyShortFragment
// ====================================================

export interface CompanyShortFragment {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeliveryLineShortFragment
// ====================================================

export interface DeliveryLineShortFragment_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLineShortFragment {
  __typename: "PplDeliveryLine";
  id: string;
  deliveryLineIdx: number;
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  status: DeliveryLineStatus;
  company: DeliveryLineShortFragment_company | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeliveryLineFragment
// ====================================================

export interface DeliveryLineFragment_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DeliveryLineFragment_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineFragment_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DeliveryLineFragment_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DeliveryLineFragment_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DeliveryLineFragment_vehicleType_fuelConsumptions[];
}

export interface DeliveryLineFragment {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DeliveryLineFragment_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DeliveryLineFragment_driver | null;
  excavatorOperator: DeliveryLineFragment_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DeliveryLineFragment_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DestinationFragment
// ====================================================

export interface DestinationFragment_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface DestinationFragment_landfill_location {
  __typename: "Location";
  text: string;
}

export interface DestinationFragment_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface DestinationFragment_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: DestinationFragment_landfill_workTime[];
  location: DestinationFragment_landfill_location;
  gateFee: number | null;
  status: DestinationFragment_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface DestinationFragment_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface DestinationFragment {
  __typename: "IDestination";
  landfill: DestinationFragment_landfill;
  subarea: DestinationFragment_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpLoadDeclarationFragment
// ====================================================

export interface DumpLoadDeclarationFragment_consistence {
  __typename: "DeclarationConsistence";
  isLiquid: boolean | null;
  isViscous: boolean | null;
  isFixed: boolean | null;
  isPasty: boolean | null;
  isPowder: boolean | null;
  isSmall: boolean | null;
  isOther: boolean | null;
  other: string | null;
}

export interface DumpLoadDeclarationFragment_risks {
  __typename: "DeclarationRisks";
  isToxic: boolean | null;
  isHazard: boolean | null;
  isHarmful: boolean | null;
  isCorrosive: boolean | null;
  isOxidising: boolean | null;
  isFlammable: boolean | null;
  isExplosive: boolean | null;
  isFarlig: boolean | null;
  isHygiene: boolean | null;
  isAcid: boolean | null;
  acid: string | null;
  isAlk: boolean | null;
  alk: string | null;
}

export interface DumpLoadDeclarationFragment {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
  producer: string | null;
  invoice: string | null;
  customerNr: string | null;
  adressFrom: string | null;
  adressTo: string | null;
  placeFrom: string | null;
  placeTo: string | null;
  postNrFrom: string | null;
  postNrTo: string | null;
  referenceFrom: string | null;
  referenceTo: string | null;
  telFrom: string | null;
  telTo: string | null;
  organizationNrFrom: string | null;
  organizationNrTo: string | null;
  ewcCode: string | null;
  process: string | null;
  reason: string | null;
  wasteType: string | null;
  pollutionType: string | null;
  subject1: string | null;
  subject2: string | null;
  subject3: string | null;
  subject4: string | null;
  subject5: string | null;
  subject6: string | null;
  value1: string | null;
  value2: string | null;
  value3: string | null;
  value4: string | null;
  value5: string | null;
  value6: string | null;
  unit1: string | null;
  unit2: string | null;
  unit3: string | null;
  unit4: string | null;
  unit5: string | null;
  unit6: string | null;
  isTunk: boolean | null;
  isContainer: boolean | null;
  isRails: boolean | null;
  totalAmountCa: string | null;
  deliveryAmountCa: string | null;
  totalTon: string | null;
  deliveryTon: string | null;
  packaging1: string | null;
  packaging2: string | null;
  packaging3: string | null;
  packaging4: string | null;
  quantity1: string | null;
  quantity2: string | null;
  quantity3: string | null;
  quantity4: string | null;
  transport: string | null;
  unNr: string | null;
  class: string | null;
  packaging: string | null;
  conveyor: string | null;
  organizationNr: string | null;
  otherInfo: string | null;
  infoAttached: boolean | null;
  otherCar: string | null;
  date: string | null;
  stability: string | null;
  consistence: DumpLoadDeclarationFragment_consistence | null;
  risks: DumpLoadDeclarationFragment_risks | null;
  hasMassEffluentRecord: boolean | null;
  hasContaminatedSoilGroundWater: boolean | null;
  hasDifferentSmellAndColor: boolean | null;
  hasOccuredByExcavationOfPath: boolean | null;
  hasPetroleumProductBeenOnSite: boolean | null;
  hasAnyAccidentsOccuredOnProperty: boolean | null;
  hasEnvironmentalTechnicalAssessment: boolean | null;
  hasOtherAssessment: boolean | null;
  wasteOriginate: string | null;
  wasteOriginateMunicipality: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpLoadProjectFragment
// ====================================================

export interface DumpLoadProjectFragment_project_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface DumpLoadProjectFragment_project_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface DumpLoadProjectFragment_project_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DumpLoadProjectFragment_project_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
}

export interface DumpLoadProjectFragment_project {
  __typename: "Project";
  id: string;
  name: string;
  comment: string | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  userLinks: DumpLoadProjectFragment_project_userLinks[];
  location: DumpLoadProjectFragment_project_location;
  customer: DumpLoadProjectFragment_project_customer | null;
  customerId: string;
  owner: DumpLoadProjectFragment_project_owner | null;
  status: ProjectStatus;
  ver: number;
  joinCode: string | null;
}

export interface DumpLoadProjectFragment {
  __typename: "ProjectLoad";
  project: DumpLoadProjectFragment_project | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpLoadShortFragment
// ====================================================

export interface DumpLoadShortFragment_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadShortFragment_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface DumpLoadShortFragment_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpLoadShortFragment_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface DumpLoadShortFragment_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface DumpLoadShortFragment {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: DumpLoadShortFragment_analysisFiles[];
  priceData: DumpLoadShortFragment_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: DumpLoadShortFragment_dumpType | null;
  landfill: DumpLoadShortFragment_landfill | null;
  transportSchedule: DumpLoadShortFragment_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  inbound: boolean;
  skipMatching: boolean;
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpLoadDetailedFragment
// ====================================================

export interface DumpLoadDetailedFragment_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DumpLoadDetailedFragment_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface DumpLoadDetailedFragment_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface DumpLoadDetailedFragment_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface DumpLoadDetailedFragment_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface DumpLoadDetailedFragment_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface DumpLoadDetailedFragment_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface DumpLoadDetailedFragment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface DumpLoadDetailedFragment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: DumpLoadDetailedFragment_comments_author | null;
}

export interface DumpLoadDetailedFragment_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface DumpLoadDetailedFragment_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface DumpLoadDetailedFragment_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface DumpLoadDetailedFragment_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: DumpLoadDetailedFragment_destinationInfo_landfill_workTime[];
  location: DumpLoadDetailedFragment_destinationInfo_landfill_location;
  gateFee: number | null;
  status: DumpLoadDetailedFragment_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface DumpLoadDetailedFragment_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface DumpLoadDetailedFragment_destinationInfo {
  __typename: "IDestination";
  landfill: DumpLoadDetailedFragment_destinationInfo_landfill;
  subarea: DumpLoadDetailedFragment_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface DumpLoadDetailedFragment_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface DumpLoadDetailedFragment_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface DumpLoadDetailedFragment_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface DumpLoadDetailedFragment_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DumpLoadDetailedFragment_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface DumpLoadDetailedFragment_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DumpLoadDetailedFragment_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: DumpLoadDetailedFragment_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface DumpLoadDetailedFragment_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: DumpLoadDetailedFragment_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: DumpLoadDetailedFragment_deliveryLines_driver | null;
  excavatorOperator: DumpLoadDetailedFragment_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: DumpLoadDetailedFragment_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface DumpLoadDetailedFragment_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface DumpLoadDetailedFragment_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: DumpLoadDetailedFragment_timeSpent_vehicleType_fuelConsumptions[];
}

export interface DumpLoadDetailedFragment_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface DumpLoadDetailedFragment_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: DumpLoadDetailedFragment_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: DumpLoadDetailedFragment_timeSpent_user | null;
}

export interface DumpLoadDetailedFragment_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: DumpLoadDetailedFragment_volumesPerPeriods_weeks[];
  months: DumpLoadDetailedFragment_volumesPerPeriods_months[];
  years: DumpLoadDetailedFragment_volumesPerPeriods_years[];
}

export interface DumpLoadDetailedFragment_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface DumpLoadDetailedFragment_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: DumpLoadDetailedFragment_volumesSplitPerPeriods_weeks[];
  months: DumpLoadDetailedFragment_volumesSplitPerPeriods_months[];
  years: DumpLoadDetailedFragment_volumesSplitPerPeriods_years[];
}

export interface DumpLoadDetailedFragment_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface DumpLoadDetailedFragment {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: DumpLoadDetailedFragment_analysisFiles[];
  priceData: DumpLoadDetailedFragment_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: DumpLoadDetailedFragment_dumpType | null;
  destinationLandfill: DumpLoadDetailedFragment_destinationLandfill | null;
  landfill: DumpLoadDetailedFragment_landfill | null;
  transportSchedule: DumpLoadDetailedFragment_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: DumpLoadDetailedFragment_declaration | null;
  comments: DumpLoadDetailedFragment_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: DumpLoadDetailedFragment_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: DumpLoadDetailedFragment_actionsToShow[];
  yourOrderNumber: string | null;
  substances: DumpLoadDetailedFragment_substances[];
  deliveryLines: DumpLoadDetailedFragment_deliveryLines[];
  timeSpent: DumpLoadDetailedFragment_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: DumpLoadDetailedFragment_volumesPerPeriods;
  volumesSplitPerPeriods: DumpLoadDetailedFragment_volumesSplitPerPeriods;
  beastMaterial: DumpLoadDetailedFragment_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpTypeInputFragment
// ====================================================

export interface DumpTypeInputFragment {
  __typename: "DumpType";
  id: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpTypeUpdateFragment
// ====================================================

export interface DumpTypeUpdateFragment {
  __typename: "DumpType";
  id: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpTypeFragment
// ====================================================

export interface DumpTypeFragment {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DumpTypeUpdatePositionFragment
// ====================================================

export interface DumpTypeUpdatePositionFragment {
  __typename: "DumpType";
  id: string;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillShortFragment
// ====================================================

export interface LandfillShortFragment_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillShortFragment_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillShortFragment_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillShortFragment_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillShortFragment {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillShortFragment_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: LandfillShortFragment_receivers[] | null;
  workTime: LandfillShortFragment_workTime[];
  comment: string | null;
  status: LandfillShortFragment_status | null;
  isDeleted: boolean | null;
  capacity: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillBatchFragment
// ====================================================

export interface LandfillBatchFragment_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillBatchFragment {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillBatchFragment_dumpType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillFragment
// ====================================================

export interface LandfillFragment_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillFragment_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillFragment_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface LandfillFragment_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillFragment_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillFragment_certificates {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface LandfillFragment_subareas_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillFragment_subareas_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillFragment_subareas {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillFragment_subareas_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillFragment_subareas_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

export interface LandfillFragment_ordersInfo_volumes_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillFragment_ordersInfo_volumes_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillFragment_ordersInfo_volumes_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface LandfillFragment_ordersInfo_volumes {
  __typename: "VolumesPerPeriods";
  weeks: LandfillFragment_ordersInfo_volumes_weeks[];
  months: LandfillFragment_ordersInfo_volumes_months[];
  years: LandfillFragment_ordersInfo_volumes_years[];
}

export interface LandfillFragment_ordersInfo_dumpLoad_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillFragment_ordersInfo_dumpLoad {
  __typename: "DumpLoadInfo";
  id: string;
  status: LoadStatus;
  amountInTons: number;
  dumpType: LandfillFragment_ordersInfo_dumpLoad_dumpType;
  solidTestResult: ContaminationLevel | null;
  comment: string;
  serialNumber: string;
  inbound: boolean;
}

export interface LandfillFragment_ordersInfo {
  __typename: "OrderInfo";
  id: string;
  subareaId: string | null;
  companyName: string | null;
  projectName: string | null;
  date: string | null;
  orderNumber: string;
  deliveredVolume: number;
  pricePerTonn: number | null;
  estimatedCO2: number | null;
  volumes: LandfillFragment_ordersInfo_volumes;
  dumpLoad: LandfillFragment_ordersInfo_dumpLoad;
}

export interface LandfillFragment_deliveryLinesInfo_deliveryEvent {
  __typename: "LandfillDeliveryEvent";
  id: string;
  photoUrl: string;
}

export interface LandfillFragment_deliveryLinesInfo_batch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface LandfillFragment_deliveryLinesInfo {
  __typename: "DeliveryLineInfo";
  id: string;
  orderNumber: string;
  weight: number;
  receiptUrl: string | null;
  truckRegistrationNumber: string;
  plannedStartDate: string;
  truckCapacity: number | null;
  transportCompanyName: string;
  receiverComment: string;
  receiverCheck: boolean;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  status: DeliveryLineStatus;
  landfillInvoiceNumber: string;
  inbound: boolean;
  deliveryEvent: LandfillFragment_deliveryLinesInfo_deliveryEvent | null;
  batch: LandfillFragment_deliveryLinesInfo_batch | null;
}

export interface LandfillFragment_batches_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillFragment_batches {
  __typename: "LandfillBatch";
  id: string;
  name: string;
  comment: string | null;
  closedAt: GQL_Date | null;
  createdAt: GQL_Date | null;
  dumpType: LandfillFragment_batches_dumpType;
}

export interface LandfillFragment {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillFragment_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receiverIds: string[];
  receivers: LandfillFragment_receivers[] | null;
  customerId: string;
  customer: LandfillFragment_customer | null;
  workTime: LandfillFragment_workTime[];
  comment: string | null;
  description: string | null;
  status: LandfillFragment_status | null;
  certificates: LandfillFragment_certificates[];
  isDeleted: boolean | null;
  capacity: number;
  subareas: LandfillFragment_subareas[];
  ordersInfo: LandfillFragment_ordersInfo[];
  deliveryLinesInfo: LandfillFragment_deliveryLinesInfo[] | null;
  batches: LandfillFragment_batches[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillLightFragment
// ====================================================

export interface LandfillLightFragment_location {
  __typename: "Location";
  text: string;
}

export interface LandfillLightFragment_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillLightFragment {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillLightFragment_location;
  status: LandfillLightFragment_status | null;
  isDeleted: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillStatusFragment
// ====================================================

export interface LandfillStatusFragment {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LocalizationStringFragment
// ====================================================

export interface LocalizationStringFragment {
  __typename: "LocalizationString";
  code: string;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LocationFragment
// ====================================================

export interface LocationFragment {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectShortFragment
// ====================================================

export interface ProjectShortFragment_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectShortFragment_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectShortFragment_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  role: UserRole;
  customerId: string | null;
}

export interface ProjectShortFragment {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  location: ProjectShortFragment_location;
  comment: string | null;
  customerId: string;
  customer: ProjectShortFragment_customer | null;
  owner: ProjectShortFragment_owner | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectDetailedFragment
// ====================================================

export interface ProjectDetailedFragment_owner {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface ProjectDetailedFragment_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface ProjectDetailedFragment_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectDetailedFragment_dumpLoads_priceData {
  __typename: "PriceData";
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProjectDetailedFragment_dumpLoads_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
  tonnsPerCubicM: number | null;
  contaminationLevelAvailable: boolean;
}

export interface ProjectDetailedFragment_dumpLoads_destinationLandfill {
  __typename: "LandfillRecord";
  name: string;
}

export interface ProjectDetailedFragment_dumpLoads_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
}

export interface ProjectDetailedFragment_dumpLoads_transportSchedule {
  __typename: "WeekAmount";
  week: string;
  amount: number | null;
}

export interface ProjectDetailedFragment_dumpLoads_declaration {
  __typename: "ProjectLoadDeclaration";
  serialNumber: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: ProjectDetailedFragment_dumpLoads_comments_author | null;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_workTime {
  __typename: "WorkTimeDay";
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_location {
  __typename: "Location";
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  icon: string;
  name: string;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  workTime: ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_workTime[];
  location: ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_location;
  gateFee: number | null;
  status: ProjectDetailedFragment_dumpLoads_destinationInfo_landfill_status | null;
  comment: string | null;
  description: string | null;
  isDeleted: boolean | null;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo_subarea {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  allowTOC: boolean;
  TOCPercent: number | null;
  allowInvasiveSpecies: boolean;
  comment: string | null;
  over50cm: boolean;
  reservedAmount: number;
}

export interface ProjectDetailedFragment_dumpLoads_destinationInfo {
  __typename: "IDestination";
  landfill: ProjectDetailedFragment_dumpLoads_destinationInfo_landfill;
  subarea: ProjectDetailedFragment_dumpLoads_destinationInfo_subarea | null;
  internalLoadFee: number;
  loadFee: number;
  distance: number;
  distanceKm: number;
  costPerTrip: number;
}

export interface ProjectDetailedFragment_dumpLoads_actionsToShow {
  __typename: "DumpLoadAction";
  toStatus: LoadStatus;
  type: DumpLoadActionType;
  actionName: DumpLoadActionName;
}

export interface ProjectDetailedFragment_dumpLoads_substances {
  __typename: "ContaminationLimit";
  substanceId: string;
  contaminationType: ContaminationType;
  amount: number | null;
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines_company {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines_driver {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines_excavatorOperator {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  kind: VehicleKind;
  externalId: string;
  fuelConsumptions: ProjectDetailedFragment_dumpLoads_deliveryLines_vehicleType_fuelConsumptions[];
}

export interface ProjectDetailedFragment_dumpLoads_deliveryLines {
  __typename: "PplDeliveryLine";
  id: string;
  orderNumber: string;
  deliveryLineIdx: number;
  weight: number;
  plannedStartDate: GQL_Date;
  excavationDate: GQL_Date | null;
  startDeliveryDate: GQL_Date | null;
  endDeliveryDate: GQL_Date | null;
  company: ProjectDetailedFragment_dumpLoads_deliveryLines_company | null;
  truckRegistrationNumber: string;
  truckCapacity: number | null;
  customerInvoiceNumber: string | null;
  transportationInvoiceNumber: string | null;
  landfillInvoiceNumber: string | null;
  receiptUrl: string | null;
  deletedAt: GQL_Date | null;
  comment: string | null;
  receiverComment: string | null;
  senderComment: string | null;
  status: DeliveryLineStatus;
  driver: ProjectDetailedFragment_dumpLoads_deliveryLines_driver | null;
  excavatorOperator: ProjectDetailedFragment_dumpLoads_deliveryLines_excavatorOperator | null;
  /**
   * TODO 5444 Make mandatory
   */
  beastStatus: BeastStatus | null;
  vehicleType: ProjectDetailedFragment_dumpLoads_deliveryLines_vehicleType | null;
  fuel: Fuel;
  engine: Engine;
}

export interface ProjectDetailedFragment_dumpLoads_timeSpent_vehicleType_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface ProjectDetailedFragment_dumpLoads_timeSpent_vehicleType {
  __typename: "VehicleType";
  id: string;
  name: string;
  fuelConsumptions: ProjectDetailedFragment_dumpLoads_timeSpent_vehicleType_fuelConsumptions[];
}

export interface ProjectDetailedFragment_dumpLoads_timeSpent_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface ProjectDetailedFragment_dumpLoads_timeSpent {
  __typename: "TimeSpent";
  id: string;
  vehicleType: ProjectDetailedFragment_dumpLoads_timeSpent_vehicleType | null;
  date: GQL_Date;
  hoursSpent: number;
  dumpLoadNumber: string;
  licensePlate: string;
  fuel: Fuel;
  engine: Engine;
  user: ProjectDetailedFragment_dumpLoads_timeSpent_user | null;
}

export interface ProjectDetailedFragment_dumpLoads_volumesPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectDetailedFragment_dumpLoads_volumesPerPeriods_weeks[];
  months: ProjectDetailedFragment_dumpLoads_volumesPerPeriods_months[];
  years: ProjectDetailedFragment_dumpLoads_volumesPerPeriods_years[];
}

export interface ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_weeks {
  __typename: "VolumesPerWeek";
  year: number;
  week: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_months {
  __typename: "VolumesPerMonth";
  year: number;
  month: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_years {
  __typename: "VolumesPerYear";
  year: number;
  delivered: number;
  planned: number;
  text: string;
}

export interface ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods {
  __typename: "VolumesPerPeriods";
  weeks: ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_weeks[];
  months: ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_months[];
  years: ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods_years[];
}

export interface ProjectDetailedFragment_dumpLoads_beastMaterial {
  __typename: "BeastMaterial";
  id: string;
  beastId: string;
  name: string;
}

export interface ProjectDetailedFragment_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  serialNumber: string;
  status: LoadStatus | null;
  date: GQL_Date | null;
  endDate: GQL_Date | null;
  comment: string | null;
  analysisFiles: ProjectDetailedFragment_dumpLoads_analysisFiles[];
  priceData: ProjectDetailedFragment_dumpLoads_priceData;
  amount: number | null;
  amountInTons: number | null;
  unitOfMeasure: UnitOfMeasure | null;
  stackability: boolean | null;
  over50cm: boolean | null;
  createdAt: GQL_Date;
  updatedAt: GQL_Date;
  statusUpdatedAt: GQL_Date | null;
  contractSigned: boolean | null;
  lastCommentText: string | null;
  dumpType: ProjectDetailedFragment_dumpLoads_dumpType | null;
  destinationLandfill: ProjectDetailedFragment_dumpLoads_destinationLandfill | null;
  landfill: ProjectDetailedFragment_dumpLoads_landfill | null;
  transportSchedule: ProjectDetailedFragment_dumpLoads_transportSchedule[];
  FANumber: string | null;
  toxicAnalysisApprovedExternally: boolean | null;
  siteConfirmed: boolean | null;
  creditCheck: boolean | null;
  landfillOrdered: boolean | null;
  solidTestResult: ContaminationLevel | null;
  withTOC: boolean;
  TOCPercent: number | null;
  withInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  declaration: ProjectDetailedFragment_dumpLoads_declaration | null;
  comments: ProjectDetailedFragment_dumpLoads_comments[] | null;
  solidState: boolean | null;
  leachingState: boolean | null;
  destinationInfo: ProjectDetailedFragment_dumpLoads_destinationInfo | null;
  distance: number | null;
  totalTrips: number | null;
  actionsToShow: ProjectDetailedFragment_dumpLoads_actionsToShow[];
  yourOrderNumber: string | null;
  substances: ProjectDetailedFragment_dumpLoads_substances[];
  deliveryLines: ProjectDetailedFragment_dumpLoads_deliveryLines[];
  timeSpent: ProjectDetailedFragment_dumpLoads_timeSpent[];
  deliveredVolume: number | null;
  deliveryLineCount: number;
  notVerifiedDeliveryLineCount: number;
  isManualScheduled: boolean | null;
  volumesPerPeriods: ProjectDetailedFragment_dumpLoads_volumesPerPeriods;
  volumesSplitPerPeriods: ProjectDetailedFragment_dumpLoads_volumesSplitPerPeriods;
  beastMaterial: ProjectDetailedFragment_dumpLoads_beastMaterial | null;
  inbound: boolean;
  skipMatching: boolean;
  suggestedLandfillName: string | null;
  suggestedLandfillAddress: string | null;
  hasPeppolSettings: boolean;
}

export interface ProjectDetailedFragment_users_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
}

export interface ProjectDetailedFragment_users {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  customer: ProjectDetailedFragment_users_customer | null;
  email: GQL_Email;
  phoneNumber: string | null;
  role: UserRole;
}

export interface ProjectDetailedFragment_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface ProjectDetailedFragment {
  __typename: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  owner: ProjectDetailedFragment_owner | null;
  startDate: GQL_Date | null;
  endDate: GQL_Date | null;
  location: ProjectDetailedFragment_location;
  customer: ProjectDetailedFragment_customer | null;
  customerId: string;
  dumpLoads: ProjectDetailedFragment_dumpLoads[];
  comment: string | null;
  ver: number;
  users: ProjectDetailedFragment_users[] | null;
  userLinks: ProjectDetailedFragment_userLinks[];
  invoiceReference: string | null;
  certificates: string[];
  totalAmountInTons: number | null;
  transportPerWeek: number | null;
  totalEstimatedPrice: number | null;
  joinCode: string | null;
  externalId: string | null;
  whatsappGroupLink: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectIdDumpLoadIdAnalysisFilesFragment
// ====================================================

export interface ProjectIdDumpLoadIdAnalysisFilesFragment_dumpLoads_analysisFiles {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface ProjectIdDumpLoadIdAnalysisFilesFragment_dumpLoads {
  __typename: "ProjectLoad";
  id: string;
  analysisFiles: ProjectIdDumpLoadIdAnalysisFilesFragment_dumpLoads_analysisFiles[];
}

export interface ProjectIdDumpLoadIdAnalysisFilesFragment {
  __typename: "Project";
  id: string;
  ver: number;
  dumpLoads: ProjectIdDumpLoadIdAnalysisFilesFragment_dumpLoads[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReleaseNoteFragment
// ====================================================

export interface ReleaseNoteFragment {
  __typename: "ReleaseNote";
  id: string;
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TransportConfigurationFragment
// ====================================================

export interface TransportConfigurationFragment {
  __typename: "TransportConfiguration";
  tonnsPerTruck: number;
  pricePerHour: number;
  pricePerKm: number;
  tax: number;
  fixedPrice: number;
  co2perKm: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeaturesFlagsFragment
// ====================================================

export interface FeaturesFlagsFragment {
  __typename: "FeaturesFlags";
  captchaEnabled: boolean;
  chatGptEnabled: boolean;
  peppolEnabled: boolean;
  whatsappEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyConfigurationFragment
// ====================================================

export interface CompanyConfigurationFragment {
  __typename: "CompanyConfiguration";
  orgName: string;
  orgNumber: string;
  place: string;
  postNumber: string;
  address: string;
  tel: string;
  email: GQL_Email;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SettingsKeysFragment
// ====================================================

export interface SettingsKeysFragment {
  __typename: "SettingsKeys";
  captchaSiteKey: string;
  captchaSecretKey: string;
  chatGptApiKey: string;
  chatGptAssistantId: string;
  tickstarClientId: string;
  tickstarClientSecret: string;
  tickstarToken: string;
  pinpointerGLN: string;
  testDespatchPartyOrgNumber: string;
  testConsigneeOrgNumber: string;
  mytapiProductId: string;
  mytapiToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SettingsFragment
// ====================================================

export interface SettingsFragment_featuresFlags {
  __typename: "FeaturesFlags";
  captchaEnabled: boolean;
  chatGptEnabled: boolean;
  peppolEnabled: boolean;
  whatsappEnabled: boolean;
}

export interface SettingsFragment_transportConfiguration {
  __typename: "TransportConfiguration";
  tonnsPerTruck: number;
  pricePerHour: number;
  pricePerKm: number;
  tax: number;
  fixedPrice: number;
  co2perKm: number;
}

export interface SettingsFragment_companyConfiguration {
  __typename: "CompanyConfiguration";
  orgName: string;
  orgNumber: string;
  place: string;
  postNumber: string;
  address: string;
  tel: string;
  email: GQL_Email;
}

export interface SettingsFragment_keys {
  __typename: "SettingsKeys";
  captchaSiteKey: string;
  captchaSecretKey: string;
  chatGptApiKey: string;
  chatGptAssistantId: string;
  tickstarClientId: string;
  tickstarClientSecret: string;
  tickstarToken: string;
  pinpointerGLN: string;
  testDespatchPartyOrgNumber: string;
  testConsigneeOrgNumber: string;
  mytapiProductId: string;
  mytapiToken: string;
}

export interface SettingsFragment {
  __typename: "Settings";
  featuresFlags: SettingsFragment_featuresFlags;
  transportConfiguration: SettingsFragment_transportConfiguration;
  companyConfiguration: SettingsFragment_companyConfiguration;
  keys: SettingsFragment_keys;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomScriptFragment
// ====================================================

export interface CustomScriptFragment {
  __typename: "CustomScript";
  id: string;
  text: string;
  src: string;
  async: boolean;
  order: number;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillSubareaFragment
// ====================================================

export interface LandfillSubareaFragment_dumpType {
  __typename: "DumpType";
  id: string;
  externalId: string;
  name: string;
  description: string | null;
  tonnsPerCubicM: number | null;
  position: number | null;
  usedInDeclaration: boolean | null;
  contaminationLevelAvailable: boolean;
}

export interface LandfillSubareaFragment_substances {
  __typename: "ContaminationLimit";
  contaminationType: ContaminationType;
  substanceId: string;
  amount: number | null;
}

export interface LandfillSubareaFragment {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  comment: string | null;
  dumpType: LandfillSubareaFragment_dumpType | null;
  currentAmount: number;
  reservedAmount: number;
  deliveredAmount: number;
  maxAmount: number;
  unitOfMeasure: UnitOfMeasure | null;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  grossPriceOver50cm: number | null;
  grossPriceNotStackable: number | null;
  solidState: boolean | null;
  allowSolidFA: boolean | null;
  solidTestResult: ContaminationLevel | null;
  leachingState: boolean | null;
  onHold: boolean;
  substances: LandfillSubareaFragment_substances[];
  allowInvasiveSpecies: boolean;
  invasiveSpecies: string | null;
  allowTOC: boolean;
  TOCPercent: number | null;
  stackable: boolean;
  over50cm: boolean;
  priceUpdatedAt: GQL_Date;
  isProvider: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandfillSubareaShortFragment
// ====================================================

export interface LandfillSubareaShortFragment_landfill_location {
  __typename: "Location";
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LandfillSubareaShortFragment_landfill_receivers {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
  email: GQL_Email;
  role: UserRole;
}

export interface LandfillSubareaShortFragment_landfill_workTime {
  __typename: "WorkTimeDay";
  dayNumber: number;
  openFrom: string;
  openTo: string;
  isOpen: boolean;
}

export interface LandfillSubareaShortFragment_landfill_status {
  __typename: "LandfillStatus";
  id: string;
  name: string;
  icon: string;
}

export interface LandfillSubareaShortFragment_landfill {
  __typename: "Landfill";
  id: string;
  name: string;
  location: LandfillSubareaShortFragment_landfill_location;
  gateFee: number | null;
  minMargin: number | null;
  marginPercent: number | null;
  receivers: LandfillSubareaShortFragment_landfill_receivers[] | null;
  workTime: LandfillSubareaShortFragment_landfill_workTime[];
  comment: string | null;
  status: LandfillSubareaShortFragment_landfill_status | null;
  isDeleted: boolean | null;
  capacity: number;
}

export interface LandfillSubareaShortFragment_dumpType {
  __typename: "DumpType";
  id: string;
  name: string;
}

export interface LandfillSubareaShortFragment {
  __typename: "LandfillSubarea";
  id: string;
  externalId: string;
  landfill: LandfillSubareaShortFragment_landfill;
  dumpType: LandfillSubareaShortFragment_dumpType | null;
  unitOfMeasure: UnitOfMeasure | null;
  comment: string | null;
  maxAmount: number;
  currentAmount: number;
  reservedAmount: number;
  priceBase: number;
  priceOver50cm: number;
  priceNotStackable: number;
  grossPriceBase: number | null;
  priceUpdatedAt: GQL_Date;
  stackable: boolean;
  over50cm: boolean;
  solidTestResult: ContaminationLevel | null;
  isProvider: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ISubstanceFragment
// ====================================================

export interface ISubstanceFragment {
  __typename: "SubstanceChild" | "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubstanceChildFragment
// ====================================================

export interface SubstanceChildFragment {
  __typename: "SubstanceChild";
  id: string;
  name: string;
  position: number;
  parentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubstanceFragment
// ====================================================

export interface SubstanceFragment_children {
  __typename: "SubstanceChild";
  id: string;
  name: string;
  position: number;
  parentId: string;
}

export interface SubstanceFragment {
  __typename: "Substance";
  id: string;
  name: string;
  position: number;
  parentId: string | null;
  contaminationTypes: ContaminationType[];
  groupBy: SubstanceGroupBy | null;
  children: SubstanceFragment_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimeSpentFragment
// ====================================================

export interface TimeSpentFragment_user {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
}

export interface TimeSpentFragment {
  __typename: "TimeSpent";
  id: string;
  userId: string;
  user: TimeSpentFragment_user | null;
  dumpLoadNumber: string;
  date: GQL_Date;
  hoursSpent: number;
  licensePlate: string;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
  comment: string | null;
  beastStatus: BeastStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ToxicLimitFragment
// ====================================================

export interface ToxicLimitFragment_limits {
  __typename: "ToxicLevelLimit";
  levelName: ContaminationLevel;
  value: number | null;
}

export interface ToxicLimitFragment {
  __typename: "ToxicLimit";
  substanceId: string;
  limits: ToxicLimitFragment_limits[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ToxicSumRuleFragment
// ====================================================

export interface ToxicSumRuleFragment_substances {
  __typename: "ToxicSumRuleSubstance";
  substanceId: string;
  threshold: number;
}

export interface ToxicSumRuleFragment {
  __typename: "ToxicSumRule";
  id: string;
  name: string;
  faSumLevel: number;
  substances: ToxicSumRuleFragment_substances[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_customer {
  __typename: "PplCompany";
  id: string;
  name: string;
  organizationNumber: string | null;
}

export interface UserFragment_vehicles {
  __typename: "Vehicle";
  licensePlate: string;
  capacity: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface UserFragment {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
  orgName: string | null;
  orgNr: string | null;
  customerId: string | null;
  confirmed: boolean | null;
  verified: boolean | null;
  blocked: boolean | null;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  createdAt: GQL_Date | null;
  customer: UserFragment_customer | null;
  lastActivityDate: GQL_Date | null;
  vehicles: UserFragment_vehicles[];
  deletedAt: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserRecordFragment
// ====================================================

export interface UserRecordFragment {
  __typename: "UserRecord";
  id: string;
  name: string;
  surname: string | null;
  phoneNumber: string | null;
  email: GQL_Email;
  role: UserRole;
  customerId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubLandfillFragment
// ====================================================

export interface SubLandfillFragment {
  __typename: "Landfill";
  id: string;
  name: string;
  receiverIds: string[];
  customerId: string;
  ownerUserId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubProjectFragment
// ====================================================

export interface SubProjectFragment_userLinks {
  __typename: "ProjectUserLink";
  userId: string;
  role: UserContextRole;
}

export interface SubProjectFragment {
  __typename: "Project";
  id: string;
  name: string;
  userLinks: SubProjectFragment_userLinks[];
  customerId: string;
  ownerUserId: string | null;
  status: ProjectStatus;
  ver: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleTypeFragment
// ====================================================

export interface VehicleTypeFragment_fuelConsumptions {
  __typename: "FuelConsumption";
  fuel: Fuel;
  value: number;
}

export interface VehicleTypeFragment {
  __typename: "VehicleType";
  id: string;
  kind: VehicleKind;
  name: string;
  externalId: string;
  fuelConsumptions: VehicleTypeFragment_fuelConsumptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeviationFragment
// ====================================================

export interface DeviationFragment_author {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

export interface DeviationFragment_photos {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

export interface DeviationFragment_comments_author {
  __typename: "User";
  id: string;
  name: string;
  surname: string | null;
}

export interface DeviationFragment_comments {
  __typename: "Comment";
  id: string;
  text: string;
  date: GQL_Date;
  author: DeviationFragment_comments_author | null;
}

export interface DeviationFragment {
  __typename: "Deviation";
  id: string;
  ticketNumber: number;
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  author: DeviationFragment_author;
  photos: DeviationFragment_photos[];
  comments: DeviationFragment_comments[];
  dateSent: GQL_Date | null;
  dateClosed: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BatchTransferFragment
// ====================================================

export interface BatchTransferFragment_fromBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchTransferFragment_toBatch {
  __typename: "LandfillBatch";
  id: string;
  name: string;
}

export interface BatchTransferFragment_externalProject {
  __typename: "ProjectInfo";
  name: string;
  address: string;
}

export interface BatchTransferFragment {
  __typename: "BatchTransfer";
  id: string;
  date: GQL_Date;
  type: TransferType;
  fromBatch: BatchTransferFragment_fromBatch | null;
  toBatch: BatchTransferFragment_toBatch | null;
  weight: number;
  externalProject: BatchTransferFragment_externalProject | null;
  licensePlate: string | null;
  comment: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BatchAnalysisFragment
// ====================================================

export interface BatchAnalysisFragment {
  __typename: "BatchAnalysis";
  id: string;
  date: GQL_Date;
  analysisResult: ContaminationLevel;
  analysisFileUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthorFragment
// ====================================================

export interface AuthorFragment {
  __typename: "User";
  id: string;
  name: string;
  role: UserRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileDocFragment
// ====================================================

export interface FileDocFragment {
  __typename: "FileDoc";
  id: string;
  name: string;
  uploadDate: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BeastStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DISABLED = "DISABLED",
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
}

export enum ContaminationLevel {
  fa = "fa",
  ifa = "ifa",
  inert = "inert",
  km = "km",
  limit = "limit",
  mkm = "mkm",
  mrr = "mrr",
  na = "na",
}

export enum ContaminationType {
  LEACHING = "LEACHING",
  SOLID = "SOLID",
}

export enum DeliveryLineStatus {
  DELIVERED = "DELIVERED",
  DELIVERING = "DELIVERING",
  EXCAVATED = "EXCAVATED",
  VERIFIED = "VERIFIED",
}

export enum DespatchAdviceCategory {
  DUMPING = "DUMPING",
  EXCAVATING = "EXCAVATING",
  TRANSPORTING = "TRANSPORTING",
}

export enum DespatchAdviceResponseType {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export enum DespatchAdviceSource {
  DELIVERY_LINE = "DELIVERY_LINE",
  TIME_SPENT = "TIME_SPENT",
}

export enum DespatchAdviceType {
  CANCELLATION = "CANCELLATION",
  ORIGINAL = "ORIGINAL",
  REPLACE = "REPLACE",
}

export enum DeviationStatus {
  ANSWERED = "ANSWERED",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  REQUESTED = "REQUESTED",
}

export enum DumpLoadActionName {
  BUY = "BUY",
  CONFIRM = "CONFIRM",
  DISCARD = "DISCARD",
  DONE = "DONE",
  REJECT = "REJECT",
  REOPEN = "REOPEN",
  REQUEST = "REQUEST",
  START_DELIVERY = "START_DELIVERY",
}

export enum DumpLoadActionType {
  BUTTON = "BUTTON",
  LINK = "LINK",
}

export enum Engine {
  Euro4 = "Euro4",
  Euro5 = "Euro5",
  Euro6 = "Euro6",
  Euro7 = "Euro7",
}

export enum Fuel {
  Diesel = "Diesel",
  Electric = "Electric",
  HVO100 = "HVO100",
}

export enum InfoCode {
  AUTOMATIC_PRICES_UNAVAILABLE = "AUTOMATIC_PRICES_UNAVAILABLE",
  DUMP_TYPE_EMPTY = "DUMP_TYPE_EMPTY",
  GOOGLE_SERVICE_UNAVAILABLE = "GOOGLE_SERVICE_UNAVAILABLE",
  LANDFILL_ROUTE_UNAVAILABLE = "LANDFILL_ROUTE_UNAVAILABLE",
  LOCATION_EMPTY = "LOCATION_EMPTY",
  MATCHED_LANDFILLS_NOT_FOUND = "MATCHED_LANDFILLS_NOT_FOUND",
  NOT_ALLOCATED_TRIPS = "NOT_ALLOCATED_TRIPS",
  OVER_ALLOCATED_TRIPS = "OVER_ALLOCATED_TRIPS",
  SKIP_MATCHING_MODE = "SKIP_MATCHING_MODE",
  TRANSPORT_CONF_EMPTY = "TRANSPORT_CONF_EMPTY",
}

export enum Language {
  en = "en",
  sv = "sv",
}

export enum LoadStatus {
  CONFIRMED = "CONFIRMED",
  DISCARDED = "DISCARDED",
  DONE = "DONE",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_ORDERED = "NOT_ORDERED",
  ORDERED = "ORDERED",
  REQUESTED = "REQUESTED",
}

export enum LocationType {
  ON_WATER = "ON_WATER",
  UNKNOWN = "UNKNOWN",
  UNREACHABLE = "UNREACHABLE",
  VALID = "VALID",
}

export enum LockEntityType {
  ORDER = "ORDER",
}

export enum ProjectStatus {
  DELETED = "DELETED",
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
}

export enum ReleaseNoteType {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  DRIVER = "DRIVER",
}

export enum SubstanceGroupBy {
  MAX = "MAX",
  SUM = "SUM",
}

export enum TransferType {
  INCOMING = "INCOMING",
  INTERNAL = "INTERNAL",
  OUTGOING = "OUTGOING",
}

export enum UnitOfMeasure {
  CUBIC_METERS = "CUBIC_METERS",
  TONNS = "TONNS",
}

export enum UserContextRole {
  AUTHOR = "AUTHOR",
  GUEST = "GUEST",
  OWNER = "OWNER",
  PROJECT_DRIVER = "PROJECT_DRIVER",
  PROJECT_EXCAVATOR_OPERATOR = "PROJECT_EXCAVATOR_OPERATOR",
  PROJECT_USER = "PROJECT_USER",
  RECEIVER = "RECEIVER",
  UNKNOWN = "UNKNOWN",
}

export enum UserRole {
  ADMIN = "ADMIN",
  DRIVER = "DRIVER",
  EMPLOYEE = "EMPLOYEE",
  EXCAVATOR_OPERATOR = "EXCAVATOR_OPERATOR",
  EXTERNAL_RECEIVER = "EXTERNAL_RECEIVER",
}

export enum VehicleKind {
  ON_SITE = "ON_SITE",
  TRANSPORT = "TRANSPORT",
}

export interface AddBlackedlist {
  email: GQL_Email;
}

export interface BatchAnalysisInput {
  batchId: string;
  date: GQL_Date;
  analysisResult: ContaminationLevel;
  upload?: GQL_Upload | null;
}

export interface BatchTransferInput {
  type?: TransferType | null;
  date?: GQL_Date | null;
  weight?: number | null;
  licensePlate?: string | null;
  fromBatchId?: string | null;
  toBatchId?: string | null;
  externalProject?: ProjectInfoInput | null;
  comment?: string | null;
}

export interface BeastMaterialCreateInput {
  beastId: string;
  name: string;
}

export interface BeastMaterialUpdateInput {
  id: string;
  beastId?: string | null;
  name?: string | null;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export interface CompanyConfigurationInput {
  orgName: string;
  orgNumber: string;
  place: string;
  postNumber: string;
  address: string;
  tel: string;
  email: GQL_Email;
}

export interface CompanyCreate {
  organizationNumber: string;
  name: string;
  phone?: string | null;
  phone2?: string | null;
  email?: GQL_Email | null;
  web?: string | null;
  contactPerson?: string | null;
  contactPerson2?: string | null;
  address?: string | null;
  address2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  comment?: string | null;
  active?: boolean | null;
  isTransportCompany?: boolean | null;
  GLN?: string | null;
}

export interface CompanyUpdate {
  id: string;
  organizationNumber?: string | null;
  name?: string | null;
  phone?: string | null;
  phone2?: string | null;
  email?: GQL_Email | null;
  web?: string | null;
  contactPerson?: string | null;
  contactPerson2?: string | null;
  address?: string | null;
  address2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  comment?: string | null;
  active?: boolean | null;
  APIKey?: string | null;
  isTransportCompany?: boolean | null;
  GLN?: string | null;
}

export interface ConnectionInput {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  page?: number | null;
}

export interface CustomScriptInput {
  text: string;
  src: string;
  async: boolean;
  order: number;
  active: boolean;
}

export interface DeclarationConsistenceInput {
  isLiquid?: boolean | null;
  isViscous?: boolean | null;
  isFixed?: boolean | null;
  isPasty?: boolean | null;
  isPowder?: boolean | null;
  isSmall?: boolean | null;
  isOther?: boolean | null;
  other?: string | null;
}

export interface DeclarationRisksInput {
  isToxic?: boolean | null;
  isHazard?: boolean | null;
  isHarmful?: boolean | null;
  isCorrosive?: boolean | null;
  isOxidising?: boolean | null;
  isFlammable?: boolean | null;
  isExplosive?: boolean | null;
  isFarlig?: boolean | null;
  isHygiene?: boolean | null;
  isAcid?: boolean | null;
  acid?: string | null;
  isAlk?: boolean | null;
  alk?: string | null;
}

export interface DeliveryLineCreateInput {
  orderNumber: string;
  weight: number;
  plannedStartDate: GQL_Date;
  truckRegistrationNumber: string;
  truckCapacity: number;
  customerInvoiceNumber?: string | null;
  transportationInvoiceNumber?: string | null;
  landfillInvoiceNumber?: string | null;
  receipt: GQL_Upload;
  companyId?: string | null;
  comment?: string | null;
  status: DeliveryLineStatus;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface DeliveryLineFilterInput {
  orderNumber?: string | null;
  orderNumbers?: string[] | null;
  weight?: (number | null)[] | null;
  truckCapacity?: (number | null)[] | null;
  date?: (GQL_Date | null)[] | null;
  transportCompany?: string | null;
  truckRegistrationNumber?: string | null;
  customerInvoiceNumber?: StringSearchParams | null;
  transportationInvoiceNumber?: StringSearchParams | null;
  landfillInvoiceNumber?: StringSearchParams | null;
  comment?: string | null;
  isDeleted?: boolean | null;
  statuses?: DeliveryLineStatus[] | null;
  batchId?: string | null;
}

export interface DeliveryLineReceiverUpdateInput {
  id: string;
  receiverCheck?: boolean | null;
  receiverComment?: string | null;
}

export interface DeliveryLineUpdateInput {
  id: string;
  deliveryLineIdx?: number | null;
  weight?: number | null;
  plannedStartDate?: GQL_Date | null;
  truckRegistrationNumber?: string | null;
  truckCapacity?: number | null;
  customerInvoiceNumber?: string | null;
  transportationInvoiceNumber?: string | null;
  landfillInvoiceNumber?: string | null;
  receipt?: GQL_Upload | null;
  companyId?: string | null;
  comment?: string | null;
  receiverComment?: string | null;
  senderComment?: string | null;
  status?: DeliveryLineStatus | null;
  vehicleTypeId?: string | null;
  fuel?: Fuel | null;
  engine?: Engine | null;
  batchId?: string | null;
}

export interface DespatchAdviceResponseFilterInput {
  dateFrom?: GQL_Date | null;
  dateTo?: GQL_Date | null;
  responseType?: DespatchAdviceResponseType | null;
  declineReason?: string | null;
}

export interface DespatchAdviceSettingsInput {
  category: DespatchAdviceCategory;
  consigneeId: string;
  despatchPartyId?: string | null;
  buyerId: string;
  sellerId: string;
  pricePerTon?: number | null;
}

export interface DestinationInput {
  projectId: string;
  dumpLoadId: string;
  ver: number;
  landfillId: string;
  subareaId?: string | null;
  priceData: PriceDataInput;
}

export interface DeviationLandfillCommentInput {
  suggestedSolution?: string | null;
  text: string;
  files: GQL_Upload[];
}

export interface DeviationLandfillCreate {
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  landfillId: string;
  files: GQL_Upload[];
}

export interface DeviationLandfillUpdate {
  issue?: string | null;
  description?: string | null;
  suggestedSolution?: string | null;
  status: DeviationStatus;
  files: GQL_Upload[];
}

export interface DeviationProjectCommentInput {
  suggestedSolution?: string | null;
  text: string;
  files: GQL_Upload[];
}

export interface DeviationProjectCreate {
  issue: string;
  description: string;
  suggestedSolution: string;
  status: DeviationStatus;
  projectId: string;
  files: GQL_Upload[];
}

export interface DeviationProjectUpdate {
  issue?: string | null;
  description?: string | null;
  suggestedSolution?: string | null;
  status: DeviationStatus;
  files: GQL_Upload[];
}

export interface DumpLoadDeliveryInfoInput {
  id: string;
  amount: number;
  unitOfMeasure: UnitOfMeasure;
  dumpTypeId: string;
  over50cm: boolean;
  stackability: boolean;
  inbound: boolean;
  withTOC: boolean;
  withInvasiveSpecies: boolean;
}

export interface DumpLoadStatusInput {
  id: string;
  status: LoadStatus;
}

export interface DumpLoadsFilter {
  statuses?: LoadStatus[] | null;
  projectName?: string | null;
  customerName?: string | null;
  serialNumber?: string | null;
  dumpType?: string | null;
  toxicAnalysisApprovedExternally?: boolean | null;
  isCustomerSigned?: boolean | null;
  contractSigned?: boolean | null;
  createdAt?: (GQL_Date | null)[] | null;
  updatedAt?: (GQL_Date | null)[] | null;
  endDate?: (GQL_Date | null)[] | null;
  landfillPrice?: (number | null)[] | null;
  hasToxicAnalysisFiles?: boolean | null;
  searchString?: string | null;
  FANumber?: string | null;
  comments?: string | null;
  landfillName?: string | null;
  startDate?: (GQL_Date | null)[] | null;
  ownerName?: string | null;
  siteConfirmed?: boolean | null;
  creditCheck?: boolean | null;
  landfillOrdered?: boolean | null;
  solidTestResult?: ContaminationLevel | null;
  joinCode?: string | null;
  inbound?: boolean | null;
  skipMatching?: boolean | null;
}

export interface DumpLoadsStatusUpdateInput {
  projectId: string;
  ver: number;
  dumpLoads: DumpLoadStatusInput[];
}

export interface DumpTypeCreateInput {
  id?: string | null;
  name: string;
  description?: string | null;
  tonnsPerCubicM: number;
  usedInDeclaration: boolean;
  contaminationLevelAvailable: boolean;
}

export interface DumpTypeUpdateInput {
  id: string;
  name?: string | null;
  description?: string | null;
  tonnsPerCubicM?: number | null;
  position?: number | null;
  usedInDeclaration?: boolean | null;
  contaminationLevelAvailable?: boolean | null;
}

export interface DumpTypeUpdatePosition {
  id?: string | null;
  position?: number | null;
}

export interface DumploadTransportScheduleUpdate {
  id: string;
  transportSchedule: WeekAmountInput[];
  date?: string | null;
  endDate?: string | null;
}

export interface FeaturesFlagsInput {
  captchaEnabled: boolean;
  chatGptEnabled: boolean;
  peppolEnabled: boolean;
  whatsappEnabled: boolean;
}

export interface FuelConsumptionInput {
  fuel: Fuel;
  value: number;
}

export interface GptConversationInput {
  projectId?: string | null;
  landfillId?: string | null;
}

export interface LandfillBatchInput {
  name: string;
  dumpTypeId: string;
  comment?: string | null;
}

export interface LandfillFilter {
  name?: string | null;
  location?: string | null;
  comment?: string | null;
  withDeleted?: boolean | null;
  userId?: string | null;
  excludeUserId?: string | null;
  customerId?: string | null;
}

export interface LandfillInput {
  id?: string | null;
  name?: string | null;
  comment?: string | null;
  description?: string | null;
  status?: string | null;
  workTime?: WorkTimeDayInput[] | null;
  gateFee?: number | null;
  minMargin?: number | null;
  marginPercent?: number | null;
  location?: LocationInput | null;
  owner?: UserUpdate | null;
  receiverIds?: string[] | null;
  certificates?: GQL_Upload[] | null;
  customerId?: string | null;
  capacity?: number | null;
}

export interface LandfillStatusInput {
  id?: string | null;
  icon: string;
  name: string;
}

export interface LandfillSubareaInput {
  id?: string | null;
  comment?: string | null;
  dumpTypeId?: string | null;
  maxAmount?: number | null;
  currentAmount?: number | null;
  reservedAmount?: number | null;
  unitOfMeasure?: UnitOfMeasure | null;
  priceBase?: number | null;
  priceOver50cm?: number | null;
  priceNotStackable?: number | null;
  solidState?: boolean | null;
  leachingState?: boolean | null;
  allowSolidFA?: boolean | null;
  onHold?: boolean | null;
  substances?: SubstanceItemInput[] | null;
  allowInvasiveSpecies?: boolean | null;
  invasiveSpecies?: string | null;
  allowTOC?: boolean | null;
  TOCPercent?: number | null;
  isProvider?: boolean | null;
}

export interface LocalizationStringInput {
  language: Language;
  code: string;
  value: string;
}

export interface LocationInput {
  lat: number;
  lng: number;
  text: string;
  type: LocationType;
}

export interface LocationReverseInput {
  lat: number;
  lng: number;
}

export interface LockInput {
  entityType: LockEntityType;
  entityId: string;
}

export interface PplCompanyFilter {
  search?: string | null;
  name?: string | null;
  organizationNumber?: string | null;
  phone?: string | null;
  email?: string | null;
  isTransportCompany?: boolean | null;
  GLN?: string | null;
}

export interface PriceDataInput {
  landfillPrice: number;
  internalLandfillPrice: number;
  forceZeroPrices: boolean;
}

export interface ProfileInput {
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  language?: Language | null;
  vehicles?: VehicleInput[] | null;
}

export interface ProjectCreate {
  name: string;
  location: LocationInput;
  comment?: string | null;
  invoiceReference?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  customerId?: string | null;
  externalId?: string | null;
}

export interface ProjectFilter {
  name?: string | null;
  statuses?: ProjectStatus[] | null;
  locationText?: string | null;
  customerName?: string | null;
  search?: string | null;
  userId?: string | null;
  excludeUserId?: string | null;
  customerId?: string | null;
  comment?: string | null;
  ownerName?: string | null;
  joinCode?: string | null;
}

export interface ProjectInfoInput {
  name: string;
  address: string;
}

export interface ProjectLoadDeclarationInput {
  serialNumber?: string | null;
  producer?: string | null;
  invoice?: string | null;
  customerNr?: string | null;
  adressFrom?: string | null;
  adressTo?: string | null;
  placeFrom?: string | null;
  placeTo?: string | null;
  postNrFrom?: string | null;
  postNrTo?: string | null;
  referenceFrom?: string | null;
  referenceTo?: string | null;
  telFrom?: string | null;
  telTo?: string | null;
  organizationNrFrom?: string | null;
  organizationNrTo?: string | null;
  ewcCode?: string | null;
  process?: string | null;
  reason?: string | null;
  wasteType?: string | null;
  pollutionType?: string | null;
  subject1?: string | null;
  subject2?: string | null;
  subject3?: string | null;
  subject4?: string | null;
  subject5?: string | null;
  subject6?: string | null;
  value1?: string | null;
  value2?: string | null;
  value3?: string | null;
  value4?: string | null;
  value5?: string | null;
  value6?: string | null;
  unit1?: string | null;
  unit2?: string | null;
  unit3?: string | null;
  unit4?: string | null;
  unit5?: string | null;
  unit6?: string | null;
  isTunk?: boolean | null;
  isContainer?: boolean | null;
  isRails?: boolean | null;
  totalAmountCa?: string | null;
  deliveryAmountCa?: string | null;
  totalTon?: string | null;
  deliveryTon?: string | null;
  packaging1?: string | null;
  packaging2?: string | null;
  packaging3?: string | null;
  packaging4?: string | null;
  quantity1?: string | null;
  quantity2?: string | null;
  quantity3?: string | null;
  quantity4?: string | null;
  transport?: string | null;
  unNr?: string | null;
  class?: string | null;
  packaging?: string | null;
  conveyor?: string | null;
  organizationNr?: string | null;
  otherInfo?: string | null;
  infoAttached?: boolean | null;
  otherCar?: string | null;
  date?: string | null;
  stability?: string | null;
  consistence?: DeclarationConsistenceInput | null;
  risks?: DeclarationRisksInput | null;
  hasMassEffluentRecord?: boolean | null;
  hasContaminatedSoilGroundWater?: boolean | null;
  hasDifferentSmellAndColor?: boolean | null;
  hasOccuredByExcavationOfPath?: boolean | null;
  hasPetroleumProductBeenOnSite?: boolean | null;
  hasAnyAccidentsOccuredOnProperty?: boolean | null;
  hasEnvironmentalTechnicalAssessment?: boolean | null;
  hasOtherAssessment?: boolean | null;
  wasteOriginate?: string | null;
  wasteOriginateMunicipality?: string | null;
}

export interface ProjectLoadInput {
  id?: string | null;
  dumpTypeId?: string | null;
  unitOfMeasure?: UnitOfMeasure | null;
  amount?: number | null;
  solidState?: boolean | null;
  leachingState?: boolean | null;
  stackability?: boolean | null;
  over50cm?: boolean | null;
  date?: string | null;
  endDate?: string | null;
  priceData?: PriceDataInput | null;
  comment?: string | null;
  substances?: (SubstanceItemInput | null)[] | null;
  ver?: number | null;
  toxicAnalysisApprovedExternally?: boolean | null;
  siteConfirmed?: boolean | null;
  creditCheck?: boolean | null;
  yourOrderNumber?: string | null;
  contractSigned?: boolean | null;
  landfillOrdered?: boolean | null;
  FANumber?: string | null;
  withInvasiveSpecies?: boolean | null;
  invasiveSpecies?: string | null;
  withTOC?: boolean | null;
  TOCPercent?: number | null;
  beastMaterialId?: string | null;
  skipMatching?: boolean | null;
  inbound?: boolean | null;
  suggestedLandfillName?: string | null;
  suggestedLandfillAddress?: string | null;
}

export interface ProjectTransportScheduleUpdate {
  id: string;
  ver: number;
  dumpLoads: DumploadTransportScheduleUpdate[];
}

export interface ProjectUpdate {
  id: string;
  name?: string | null;
  location?: LocationInput | null;
  comment?: string | null;
  invoiceReference?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  owner?: UserUpdate | null;
  customerId?: string | null;
  ver: number;
  externalId?: string | null;
}

export interface ReleaseNoteInput {
  text: string;
  releaseDate: GQL_Date;
  type: ReleaseNoteType;
}

export interface SettingsInput {
  transportConfiguration: TransportConfigurationInput;
  featuresFlags: FeaturesFlagsInput;
  companyConfiguration: CompanyConfigurationInput;
  keys: SettingsKeysInput;
}

export interface SettingsKeysInput {
  captchaSiteKey: string;
  captchaSecretKey: string;
  chatGptApiKey: string;
  chatGptAssistantId: string;
  tickstarClientId: string;
  tickstarClientSecret: string;
  tickstarToken: string;
  pinpointerGLN: string;
  testDespatchPartyOrgNumber: string;
  testConsigneeOrgNumber: string;
  mytapiProductId: string;
  mytapiToken: string;
}

export interface SortInput {
  field: string;
  direction: number;
}

export interface StringSearchParams {
  value?: string | null;
  includeEmpty?: boolean | null;
}

export interface SubareaFilter {
  landfillName?: string | null;
  landfillLocation?: string | null;
  landfillComment?: string | null;
  landfillStatusId?: string | null;
  dumpTypeId?: string | null;
  materialId?: string | null;
  comment?: string | null;
  nonStackable?: boolean | null;
  over50cm?: boolean | null;
  maxAmount?: (number | null)[] | null;
  currentAmount?: (number | null)[] | null;
  reservedAmount?: (number | null)[] | null;
  landfillGateFee?: (number | null)[] | null;
  landfillMinMargin?: (number | null)[] | null;
  landfillMarginPercent?: (number | null)[] | null;
  priceOver50cm?: (number | null)[] | null;
  priceNotStackable?: (number | null)[] | null;
  priceBase?: (number | null)[] | null;
  priceUpdatedAt?: (GQL_Date | null)[] | null;
  solidTestResult?: ContaminationLevel | null;
  isProvider?: boolean | null;
}

export interface SubstanceItemInput {
  contaminationType: ContaminationType;
  substanceId: string;
  amount?: number | null;
}

export interface SubstanceUpdatePosition {
  id?: string | null;
  parentId?: string | null;
  position?: number | null;
}

export interface TimeSpentFilterInput {
  userId?: string | null;
  date?: (GQL_Date | null)[] | null;
  dumpLoadNumber?: string | null;
}

export interface ToxicSumRuleInput {
  name: string;
  faSumLevel: number;
  substances: ToxicSumRuleSubstanceInput[];
}

export interface ToxicSumRuleSubstanceInput {
  substanceId: string;
  threshold: number;
}

export interface TransportConfigurationInput {
  tonnsPerTruck: number;
  pricePerKm: number;
  pricePerHour: number;
  tax: number;
  fixedPrice: number;
  co2perKm: number;
}

export interface UserChangePasswordInput {
  userId: string;
  newPassword: string;
  repeatPassword: string;
}

export interface UserCreate {
  email: GQL_Email;
  password?: string | null;
  role: UserRole;
  customerId?: string | null;
  name: string;
  surname: string;
  phoneNumber?: string | null;
}

export interface UserFilter {
  search?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  roles?: string[] | null;
  customerId?: string | null;
  verified?: boolean | null;
  confirmed?: boolean | null;
  blocked?: boolean | null;
  createdAt?: (GQL_Date | null)[] | null;
  lastActivityDate?: (GQL_Date | null)[] | null;
  customerName?: string | null;
  isDeleted?: boolean | null;
}

export interface UserUpdate {
  id: string;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  role?: UserRole | null;
  orgName?: string | null;
  orgNr?: string | null;
  customerId?: string | null;
  confirmed?: boolean | null;
  verified?: boolean | null;
  blocked?: boolean | null;
  vehicles?: VehicleInput[] | null;
}

export interface VehicleInput {
  licensePlate: string;
  capacity?: number | null;
  vehicleTypeId: string;
  fuel: Fuel;
  engine: Engine;
}

export interface VehicleTypeInput {
  kind: VehicleKind;
  name: string;
  externalId: string;
  fuelConsumptions: FuelConsumptionInput[];
}

export interface WeekAmountInput {
  week: string;
  amount?: number | null;
}

export interface WorkTimeDayInput {
  dayNumber: number;
  isOpen: boolean;
  openFrom: string;
  openTo: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
