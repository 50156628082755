import { validateBatchAmount } from '@common/functions/common.landfillBatches';
import { BatchCheckResult, BatchLogItem } from '@common/types';

import { IBatchLog } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage';
import { TransferType } from '~/graphql';

export const getBatchCheckResult = (batchId: string, batchLog: IBatchLog): BatchCheckResult => {
    const list: BatchLogItem[] = [
        ...batchLog.transfers.map(t => ({
            id: t.id,
            weight: t.weight,
            date: new Date(t.date),
            incoming:
                t.type === TransferType.INCOMING || (t.type === TransferType.INTERNAL && t.toBatch?.id === batchId),
        })),
        ...batchLog.analyses.map(t => ({
            id: t.id,
            weight: 0,
            date: new Date(t.date),
            incoming: true,
        })),
    ];

    return validateBatchAmount(list);
};
