import { makeStyles } from '@material-ui/core/styles';
import { omit } from 'lodash';
import React, { FC, useMemo } from 'react';

import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { BatchAnalysisCard } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/BatchAnalysisCard';
import { BatchDeliveryLineCard } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/BatchDeliveryLineCard';
import { BatchTransferCard } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/BatchTransferCard';
import {
    IBatchDeliveryIncomingLine,
    IBatchDeliveryOutgoingLine,
    IBatchLogRecord,
    ITransfer,
    Kind,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { LandfillQuery_landfill } from '~/graphql';
import { getBatchCheckResult } from '~/utils/landfillBatch';

import { IBatchLog } from './LandfillBatchLogPage';

type Props = {
    landfill: LandfillQuery_landfill;
    batch: IBatch;
    data: IBatchLog;
    disabled?: boolean;
};

export const LandfillBatchLog: FC<Props> = ({ landfill, data, batch, disabled = false }) => {
    const { root } = useStyles();

    const items = useMemo(() => {
        const dumpLoadInfo = landfill.ordersInfo.reduce((acc, orderInfo) => {
            acc.set(orderInfo.orderNumber, {
                inbound: orderInfo.dumpLoad.inbound,
                projectName: orderInfo.projectName || '',
            });

            return acc;
        }, new Map<string, { inbound: boolean; projectName: string }>());

        const list: IBatchLogRecord[] = [
            ...(landfill.deliveryLinesInfo || [])
                .filter(l => !l.inbound && l.batch?.id === batch.id)
                .map(l => ({
                    id: l.id,
                    truckRegistrationNumber: l.truckRegistrationNumber,
                    weight: l.weight,
                    date: new Date(l.plannedStartDate),
                    batch,
                    projectName: dumpLoadInfo.get(l.orderNumber)?.projectName || '',
                    kind: Kind.LINE_INCOMING as const,
                })),
            ...(landfill.deliveryLinesInfo || [])
                .filter(l => l.inbound && l.batch?.id === batch.id)
                .map(l => ({
                    id: l.id,
                    truckRegistrationNumber: l.truckRegistrationNumber,
                    weight: l.weight,
                    date: new Date(l.plannedStartDate),
                    batch,
                    projectName: dumpLoadInfo.get(l.orderNumber)?.projectName || '',
                    kind: Kind.LINE_OUTGOING as const,
                })),
            ...data.transfers.map(t => ({
                ...omit(t, ['__typename', 'date']),
                kind: Kind.TRANSFER as const,
                date: new Date(t.date),
            })),
            ...data.analyses.map(a => ({
                ...omit(a, ['__typename', 'date']),
                kind: Kind.ANALYSIS as const,
                date: new Date(a.date),
            })),
        ];

        const batchCheckResult = getBatchCheckResult(batch.id, data);

        const result: IBatchLogRecord[] = Array.from(batchCheckResult.amounts.keys()).reduce(
            (
                acc: {
                    data: IBatchLogRecord[];
                    prevItem: ITransfer | IBatchDeliveryIncomingLine | IBatchDeliveryOutgoingLine | null;
                },
                k,
                i
            ) => {
                const item = list.find(i => i.id === k)!;

                if (item.kind === Kind.ANALYSIS) {
                    acc.data.push(item);

                    return acc;
                }

                const value = batchCheckResult.amounts.get(k)!;
                item.amount = value.amount;
                item.signSymbol = value.sign === 1 ? '+' : '-';

                if (acc.prevItem && acc.prevItem.date.toLocaleDateString() !== item.date.toLocaleDateString())
                    acc.prevItem.displayRemain = true;

                if (i === batchCheckResult.amounts.size - 1) item.displayRemain = true;

                acc.prevItem = item;
                acc.data.push(item);

                return acc;
            },
            { data: [], prevItem: null }
        ).data;

        return result.reverse();
    }, [data, batch, landfill]);

    return (
        <div className={root}>
            {items.map(item =>
                item.kind === Kind.TRANSFER ? (
                    <BatchTransferCard
                        landfill={landfill}
                        batch={batch}
                        item={item}
                        disabled={disabled}
                        key={item.id}
                    />
                ) : item.kind === Kind.ANALYSIS ? (
                    <BatchAnalysisCard
                        landfill={landfill}
                        batch={batch}
                        item={item}
                        disabled={disabled}
                        key={item.id}
                    />
                ) : (
                    <BatchDeliveryLineCard landfill={landfill} item={item} disabled={disabled} key={item.id} />
                )
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '60%',
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100vh - 310px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 'calc(100vh - 130px)',
        },
        overflowY: 'auto',
    },
}));
